import React, { Component } from 'react';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import { www_url } from '../../config/config';
import { queueUpdate } from '../../actions/actions';
import { fetchAfterSave } from '../../actions/pageControl';

const CityPdfView = ({
  filename_new,
  url,
  remove_clicked,
  remove,
  handleChange,
}) => (
  <div>
    {url && (
      <div className="pd-h">
        <a
          className="the_link"
          target="_blank"
          rel="noopener noreferrer"
          href={url}>
          {url}
        </a>
      </div>
    )}
    <div className="pd-h">
      {url && !remove_clicked && <FlatButton label="remove" onClick={remove} />}
      {url && remove_clicked && (
        <span>The file will be remove once you click SAVE button</span>
      )}
      {!url && (
        <RaisedButton label="select file" containerElement="label">
          <input
            style={{ display: 'none' }}
            multiple={false}
            type="file"
            accept=".pdf"
            name="pdf"
            onChange={handleChange}
          />
        </RaisedButton>
      )}
    </div>
    {filename_new && <div className="pd-h">{filename_new}</div>}
  </div>
);

class CityPdfState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filename_new: '',
      remove_clicked: false,
    };
  }

  remove = () => {
    const { city_id, dispatch } = this.props;

    const confirm = window.confirm('Removing cover. Are you sure?');
    if (!confirm) return;
    this.setState({
      filename_new: '',
      remove_clicked: true,
    });

    dispatch(queueUpdate('city', city_id, 'has_pdf', null));
    dispatch(fetchAfterSave(true));
  };

  handleChange = event => {
    event.preventDefault();
    event.persist();

    const files = event.target.files;
    if (files.length === 0) {
      this.setState({ filename_new: '' });
    } else {
      this.setState({ filename_new: files[0].name });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.data_key === nextProps.data_key) return;
    this.setState({
      filename_new: '',
      remove_clicked: false,
    });
  }

  render() {
    const { filename_new, remove_clicked } = this.state;
    const { url } = this.props;
    return (
      <CityPdfView
        filename_new={filename_new}
        remove_clicked={remove_clicked}
        url={url}
        handleChange={this.handleChange}
        remove={this.remove}
      />
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { has_pdf, symbol, city_id } = data.city;

  return {
    data_key: data.data_key,
    city_id,
    url: has_pdf
      ? www_url + 'data/download/' + symbol.toLowerCase() + '.pdf'
      : null,
  };
};

export default connect(mapStateToProps)(CityPdfState);
