// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import getUserData from '../actions/getUserData';

const mapStateToProps = ({ user_data }, ownProps) => {
  return {
    user_data,
    ...ownProps,
  };
};

export const withAccessSuperadmin = (WrappedComponent: any) => {
  const withAccessLimitet = props => {
    const { user_data, dispatch, ...the_props } = props;
    useEffect(() => {
      props.dispatch(getUserData());
    }, []);
    return props.user_data.group_id === 4 ? (
      <WrappedComponent {...the_props} />
    ) : (
      'No access'
    );
  };
  return connect(mapStateToProps)(withAccessLimitet);
};

export const withAccessLogged = (WrappedComponent: any) => {
  const withAccessLimitet = props => {
    const { user_data, dispatch, ...the_props } = props;
    useEffect(() => {
      props.dispatch(getUserData());
    }, []);
    return props.user_data.group_id ? (
      <WrappedComponent {...the_props} />
    ) : (
      'No access'
    );
  };
  return connect(mapStateToProps)(withAccessLimitet);
};
