import React, { Component } from 'react';
import PcCard from '../shared/PcCard.js';
import Toggle from 'material-ui/Toggle';
import { queueUpdateAuto } from '../../actions/actions.js';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';

const RedirectView = ({ toggled, handleToggle, redirect_to, handleChange }) => {
  let textInput = null;

  const handleToggleInternal = ev => {
    if (ev.target.value === 'on') {
      // can't focus on element that is hidden
      setTimeout(
        function (textInput) {
          textInput.focus();
        },
        100,
        textInput,
      );
    }
    handleToggle(ev);
  };

  return (
    <PcCard cardTitle="Redirect" id="card_redirect">
      <Toggle
        label="display other page"
        toggled={toggled}
        onToggle={handleToggleInternal}
        labelPosition="right"
      />

      <div style={!toggled ? { display: 'none' } : null}>
        <span>https://www.inyourpocket.com/</span>
        <TextField
          name="redirect_to"
          value={redirect_to || ''}
          onChange={handleChange}
          ref={input => {
            textInput = input;
          }}
        />
      </div>
    </PcCard>
  );
};

export class CardRedirect extends Component {
  init({ redirect_to }) {
    this.setState({
      redirect_to,
      toggled: redirect_to ? true : false,
    });
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data_key === nextProps.data_key) return;
    this.init(nextProps);
  }

  handleChange = event => {
    const value = event.target.value;
    this.setState({ redirect_to: value }, this.props.handleLinkChange(value));
  };

  handleToggle = () => {
    this.setState({ toggled: !this.state.toggled }, () => {
      if (!this.state.toggled) this.props.handleLinkChange(null);
    });
  };

  render() {
    const { toggled, redirect_to } = this.state;
    return (
      <RedirectView
        toggled={toggled}
        redirect_to={redirect_to}
        handleToggle={this.handleToggle}
        handleChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const redirect_to = state.data[ownProps.data_type].redirect_to;
  const data_key = state.data;

  return {
    data_key,
    redirect_to,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleLinkChange: value => {
      dispatch(queueUpdateAuto('redirect_to', value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardRedirect);
