// @flow

// $FlowFixMe - after flow update https://github.com/facebook/flow/pull/7149/
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

import PcAppBar from '../shared/TopBar/PcAppBar';
import BreadCrumbs from '../shared/BreadCrumbs';

import withUserDataFetched from '../../core/withUserDataFetched';
import withDataFromUrl from '../../core/withDataFromUrl';
import { withAccessSuperadmin } from '../../core/withAccess';

/*
const affiliate_widget_list = [
  {
    name: "The widget name",
    active: 1,
    archived: null,
    affiliate_id: 1,
    zone: 1
  },
  {
    name: "The widget2 name",
    active: 1,
    archived: null,
    affiliate_id: 2,
    zone: 1
  },
  {
    name: "The inactive widhet",
    active: null,
    archived: null,
    affiliate_id: 3,
    zone: 2
  }
];
*/

const AffiliateList = ({ country, country_list, affiliate_widget_list }) => {
  const [showing_archived, setShowArchived] = useState(false);

  if (!affiliate_widget_list) return null;

  const toggleShowArchived = new_value => {
    setShowArchived(new_value);
    window.scrollTo(0, 0);
  };

  const breadcrumb_self = country
    ? {
        url: '/affiliate/by_country/' + country.country_id + '/all',
        name: country.name,
      }
    : {
        url: '/affiliate/global/all',
        name: 'global',
      };

  const breadcrumbs = [
    {
      url: '/',
      name: 'home',
    },
    {
      url: '/affiliate',
      name: 'Affliliate home',
    },
    breadcrumb_self,
  ];

  const list = affiliate_widget_list.filter(
    af =>
      (!showing_archived && !af.archived) || (showing_archived && af.archived),
  );

  const count_archived = affiliate_widget_list.filter(af => af.archived).length;

  const add_new_url = country
    ? '/affiliate/by_country/' + country.country_id + '/new'
    : '/affiliate/global/new';

  const add_new_label =
    'Add new ' + (country ? country.name : 'global') + ' widget';

  return (
    <>
      <PcAppBar
        title={(country ? country.name : 'Global') + ' - Affiliate Widgets '}
        no_drawer={true}
      />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
          <h1>
            {country ? country.name : 'Global'}{' '}
            {showing_archived && ' - Archived'}
          </h1>
        </div>

        <div style={{ maxWidth: '700px', margin: 'auto' }}>
          {country && !showing_archived && (
            <>
              <Link
                to={
                  '/affiliate/by_country/' +
                  country.country_id +
                  '/venue_exclusions'
                }
                className="the_link pull-right">
                Venue exclusions page
              </Link>
              <br />
            </>
          )}

          {!showing_archived && (
            <Link to={add_new_url}>
              <RaisedButton primary={true} label={add_new_label} />
            </Link>
          )}

          <Table>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn style={{ width: '80px' }}>
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn>Name</TableHeaderColumn>
                <TableHeaderColumn style={{ width: '120px' }}>
                  Zone
                </TableHeaderColumn>
                <TableHeaderColumn style={{ width: '120px' }}>
                  State
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {list.map(affiliate => {
                const item_link = country
                  ? '/affiliate/by_country/' +
                    country.country_id +
                    '/' +
                    affiliate.affiliate_widget_id
                  : '/affiliate/global/' + affiliate.affiliate_widget_id;
                return (
                  <TableRow key={affiliate.affiliate_widget_id}>
                    <TableRowColumn style={{ width: '80px' }}>
                      {affiliate.affiliate_widget_id}
                    </TableRowColumn>
                    <TableRowColumn>
                      <Link
                        className="the_link"
                        style={affiliate.active ? null : { color: 'grey' }}
                        to={item_link}>
                        {affiliate.name}
                      </Link>
                    </TableRowColumn>
                    <TableRowColumn style={{ width: '120px' }}>
                      {affiliate.zone
                        ? affiliate.zone === 1
                          ? 'left'
                          : 'below text'
                        : '-not set-'}
                    </TableRowColumn>
                    <TableRowColumn style={{ width: '120px' }}>
                      {affiliate.active ? (
                        'On'
                      ) : (
                        <span style={{ color: 'grey' }}>Off</span>
                      )}
                    </TableRowColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <br />
          <br />
          {affiliate_widget_list.length === 0 && (
            <div className="pd" style={{ textAlign: 'center' }}>
              <br />
              Nothing to show yet
            </div>
          )}
          {!showing_archived && count_archived > 0 && (
            <FlatButton
              label={'Show Archived (' + count_archived + ')'}
              onClick={() => toggleShowArchived(true)}
            />
          )}
          {showing_archived && (
            <FlatButton
              label={'Show Unarchived'}
              onClick={() => toggleShowArchived(false)}
            />
          )}
          {count_archived === 0 && <small>no archived</small>}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ constants, data }, ownProps) => {
  const country_list = constants.country_list || null;
  const country_id_route = parseInt(ownProps.match.params.country_id);
  const country = country_list
    ? country_list.find(c => c.country_id === country_id_route)
    : null;
  const affiliate_widget_list = data.affiliate_widget_list;

  return {
    country,
    country_list,
    affiliate_widget_list,
  };
};

//const WithAccess = withAccessSuperadmin(AffiliateList);
//const Test = props => <>{JSON.stringify(props)}</>;

const AffiliateListByCountry = withUserDataFetched(
  withDataFromUrl(match => {
    return {
      page_type: 'affiliate-list-by-country',
      page_id: match.country_id,
      api_call: 'page/affiliate/by_country/' + match.country_id + '/all',
    };
  }, withAccessSuperadmin(connect(mapStateToProps)(AffiliateList))),
);

const AffiliateListGlobal = withUserDataFetched(
  withDataFromUrl(match => {
    return {
      page_type: 'affiliate-list-global',
      page_id: 0,
      api_call: 'page/affiliate/global/all',
    };
  }, withAccessSuperadmin(connect(mapStateToProps)(AffiliateList))),
);

export { AffiliateListByCountry, AffiliateListGlobal };

/*
return {
  page_type: "affiliate",
  page_id: match.country_id,
  api_call: "affiliate/" + match.country_id
}
*/
