import React from 'react';
import { connect } from 'react-redux';

import CitySelect from '../shared/Drawer/CitySelect';
import ChapterSelect from '../shared/Drawer/ChapterSelect';
import PcCard from '../shared/PcCard';
import BreadCrumbs from '../shared/BreadCrumbs';
import PcAppBar from '../shared/TopBar/PcAppBar';
import PcDrawer from '../shared/Drawer/PcDrawer';
import CardMetaTags from '../shared/CardMetaTags';

import EventTypeSelect from '../shared/Drawer/EventTypeSelect';
import EventList from '../shared/Drawer/EventTypeFilter/EventList';

import PcCheckbox from '../shared/PcCheckbox';
import PcHtmlEditor from '../shared/PcHtmlEditor';
import { modifyWrapper } from '../../core/ModifyWrapper';
import CardImages from '../shared/Images/CardImages';
import PcTextField from '../shared/PcTextField';

import EventsCardPicks from './EventsCardPicks';
import BackgroundMobile from '../shared/Images/BackgroundMobile';
import CardImagesBackground from '../shared/Images/CardImagesBackground';

const CheckboxEvents = modifyWrapper(PcCheckbox, 'events_chapter', {
  custom_key: 'city_id',
});
const HtmlEditorEvents = modifyWrapper(PcHtmlEditor, 'events_chapter', {
  custom_key: 'city_id',
});
const TextFieldEventsChapter = modifyWrapper(PcTextField, 'events_chapter', {
  custom_key: 'city_id',
});

const EventsChapterView = ({ city_id, title, data_key }) => {
  return (
    <div>
      <PcAppBar title={title + ' events'} />
      <PcDrawer>
        <CitySelect city_id={city_id} />
        <ChapterSelect />

        <EventTypeSelect />
        <EventList />
      </PcDrawer>
      <div className="pd pc-content">
        <BreadCrumbs />

        <PcCard cardTitle="Intro text">
          <CheckboxEvents name="active" label="Display&nbsp;events" />
          <h2>Intro text</h2>
          <HtmlEditorEvents
            name="body"
            dom_key={'events_chapter_body_' + { data_key }}
          />
        </PcCard>

        <EventsCardPicks />

        <CardImages
          data_type="events_chapter"
          data_id={city_id}
          custom_key="city_id"
        />

        <CardImagesBackground
          data_type="events_chapter"
          data_id={city_id}
          custom_key="city_id"
        />

        <PcCard cardTitle="Background Mobile" id="mb-mobile">
          <BackgroundMobile />
        </PcCard>

        <CardMetaTags TextFieldTyped={TextFieldEventsChapter} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  return {
    city_id: data.events_chapter.city_id,
    title: data.city.name,
    data_key: data.data_key,
  };
};

export default connect(mapStateToProps)(EventsChapterView);
