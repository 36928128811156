import React from 'react';
import PcAppBar from '../shared/TopBar/PcAppBar';
import { Link } from 'react-router-dom';
import PcDrawer from '../shared/Drawer/PcDrawer';
import FeatureList from '../shared/Drawer/FeatureList';
import CitySelect from '../shared/Drawer/CitySelect';
import { connect } from 'react-redux';
import BreadCrumbs from '../shared/BreadCrumbs';
import RemoveArchive from '../shared/RemoveArchive';

const Country = ({ city_list }) => {
  return (
    <div>
      <PcAppBar />

      <PcDrawer>
        <CitySelect city_id={null} />
        <FeatureList />
      </PcDrawer>

      <div className="pd pc-content">
        <BreadCrumbs />

        <div className="pd" />

        <div className="top-line">
          {city_list.map(city => (
            <div className="pd" key={city.city_id}>
              <Link
                className="the_link"
                to={'/city/' + city.symbol + '/' + city.city_id}>
                {city.name}
              </Link>
            </div>
          ))}
        </div>

        <RemoveArchive />
      </div>
    </div>
  );
};

const mapStateToProps = ({ data, constants }) => {
  const country_id = data.country.country_id;
  const city_list = constants.city_list.filter(
    city => city.country_id === country_id,
  );

  return {
    city_list,
  };
};

export default connect(mapStateToProps)(Country);
