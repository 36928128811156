const customPostData = (state = {}, action) => {
  switch (action.type) {
    case 'SET_POST_DATA':
      let new_item = {};

      new_item[action.key] = action.value;

      return { ...state, ...new_item };

    case 'CLEAR_POST_DATA':
      return {};

    default:
      return state;
  }
};

export default customPostData;
