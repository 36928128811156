import { api_url, env_local } from './config/config';

export const formatDateDb = date => {
  const year = date.getFullYear();
  let month = '' + (date.getMonth() + 1);
  if (month.length === 1) month = '0' + month;
  let day = '' + date.getDate();
  if (day.length === 1) day = '0' + day;
  return year + '-' + month + '-' + day;
};

export const formatHourDb = date => {
  let hours = '' + date.getHours();
  if (hours.length === 1) hours = '0' + hours;
  let minutes = '' + date.getMinutes();
  if (minutes.length === 1) minutes = '0' + minutes;
  return hours + ':' + minutes;
};

export const buildObj = (key, value) => {
  let item_state = {};
  item_state[key] = value;
  return item_state;
};

export const cssActive = value => {
  return value ? {} : { color: 'grey' };
};

export const leloFetch = (path, params_in = {}) => {
  const url = api_url + path;

  let timeout = null;

  // cors
  let params = env_local ? { mode: 'cors' } : {};

  // header
  params.headers = {
    Accept: 'application/json',
  };

  params.credentials = 'include';

  // post data form
  if (params_in.form_data) {
    params.body = params_in.form_data;
  }

  // post data json
  if (params_in.json_data) {
    params.headers['Content-Type'] = 'application/json';
    params.body = JSON.stringify(params_in.json_data);
  }

  // method
  params.method = params_in.method ? params_in.method : 'GET';

  //	console.log('LeloFetch', params)

  return new Promise(function (resolve, reject) {
    // timeout
    if (params_in.timeout) {
      timeout = setTimeout(function () {
        reject('Request timed out');
      }, params_in.timeout);
    }

    fetch(url, params)
      .then(response => {
        if (!response.ok) {
          reject({ error: response.statusText });
        }
        if (timeout !== null) {
          clearTimeout(timeout);
        }
        return response;
      })
      .then(response => {
        const response2 = response.clone();
        console.log('call:', url);
        response.json().then(
          data => {
            //					console.log('leloFetch data:', data)
            resolve(data);
          },
          error => {
            response2.text().then(response_txt => {
              console.log(response_txt);
              reject('Server respone is not a valid JSON');
            });
          },
        );
      })
      .catch(error => {
        console.error('error', error);
        clearTimeout(timeout);
        const error_msg =
          error === 'TypeError: Failed to fetch'
            ? 'Could not connect. Check your internet connection'
            : error;
        reject(error_msg);
      });
  });
};

export const linkGenerator = (type, d, custom_url_type) => {
  const { symbol } = d;
  const key = type + '_id';
  const id = d[key];
  const the_type = custom_url_type || type;
  return the_type + '/' + symbol + '/' + id;
};
