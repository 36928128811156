import React, { Component } from 'react';
import PcCard from '../shared/PcCard.js';
import PcSearch from '../shared/PcSearch';
import EditableList from '../shared/EditableList';
import { connect } from 'react-redux';
import { queueUpdate } from '../../actions/actions.js';
import { Row, CellHalf } from '../shared/Grid.js';

class EventConnectedState extends Component {
  constructor(props) {
    super();
    this.state = {
      items: props.connected_events || [],
      value: '',
    };
    this.addItem = this.addItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // added new item
  addItem(data) {
    const items = [...this.state.items, data];
    this.setState({ items });
    this.props.modifyIds(this.props.event_id, items);
  }

  handleChange(items) {
    this.setState({ items });
    this.props.modifyIds(this.props.event_id, items);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.event_id === this.props.event_id) return;

    this.setState({
      items: nextProps.connected_events || [],
    });
  }

  render() {
    const { country_id, country_name, event_id } = this.props;

    return (
      <PcCard cardTitle="Connected Events" id="card_connected_events">
        <Row>
          <CellHalf>
            {/*<PcSearch narrow_field_key='country_id' narrow_field_id={country_id} table='event' onResultClick={this.addItem} tip={"events in "+country_name} search_id={'event'+event_id}/>*/}
            <PcSearch
              narrow_field_key="country_id"
              narrow_field_id={country_id}
              table="event"
              onResultClick={this.addItem}
              tip={'events in ' + country_name}
              search_id={'event' + event_id}
              exclude={[event_id]}
            />
          </CellHalf>
          <CellHalf>
            {this.state.items.length > 0 && (
              <EditableList
                items={this.state.items}
                is_sortable={true}
                handleChange={this.handleChange}
              />
            )}
          </CellHalf>
        </Row>
      </PcCard>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    modifyIds: (event_id, items) => {
      const event_ids = items.map(item => {
        return item.event_id;
      });
      dispatch(
        queueUpdate('event', event_id, 'associated_events_ids', [...event_ids]),
      ); /*...event_ids will make them uniqe */
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const event_id = ownProps.event_id || state.data.page_id;

  const { event, country } = state.data;

  return {
    connected_events: event.connected_events || [],
    country_name: country.name,
    country_id: country.country_id,
    event_id,
  };
};

const EventCardConnectedEvents = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventConnectedState);

export default EventCardConnectedEvents;
