// @flow
import React, { useState } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import PcCard from '../../../page/shared/PcCard.js';

type TLogicalConnective = 'AND' | 'OR' | null;

type TLogicalConnectiveProps = {
  logical_connective: TLogicalConnective,
  disabled: boolean,
  handleChange: TLogicalConnective => void,
};

const LogicalConnective = ({
  logical_connective,
  disabled,
  handleChange,
}: TLogicalConnectiveProps) => {
  const [selectedLocalState, setSelected] = useState(logical_connective);

  const handleSelectChange = (event, index, values) => {
    handleChange(values);
    setSelected(values);
  };

  const defs = [
    {
      value: 'AND',
      description: 'AND',
    },
    {
      value: 'OR',
      description: 'OR',
    },
  ];

  return (
    <div style={{ opacity: disabled ? 0.3 : 1 }}>
      <PcCard cardTitle="Logical connective">
        Choose one of options: <br />
        1. All Conditions must be met simultaneously [AND] <br />
        2. Any of conditions must be met [OR] <br />
        <SelectField
          hintText="Select Logical Connective"
          value={selectedLocalState}
          onChange={handleSelectChange}
          disabled={disabled}>
          {defs.map(item => (
            <MenuItem
              key={item.value}
              insetChildren={true}
              checked={selectedLocalState === item.value}
              value={item.value}
              primaryText={item.description}
            />
          ))}
        </SelectField>
      </PcCard>
    </div>
  );
};

const LogicalConnectiveWithState = ({
  logical_connective,
  handleChange,
}: TLogicalConnectiveProps) => {
  const [logicalConnectiveState, setLogicalConnective] =
    useState(logical_connective);
  const _handleChange = logical_connective => {
    handleChange(logical_connective);
    setLogicalConnective(logical_connective);
  };
  return (
    <LogicalConnective
      logical_connective={logicalConnectiveState}
      handleChange={_handleChange}
    />
  );
};

export { LogicalConnectiveWithState };

export default LogicalConnective;
