import { createSelector } from 'reselect';

const getCityData = ({ data_city }) => data_city;
const getCityId = ({ city_id }) => city_id;

const getCitySructureData = createSelector(
  [getCityData, getCityId],
  (data_city, city_id) => {
    const data_city_arr = data_city.find(val => val.city_id === city_id);
    return data_city_arr
      ? data_city_arr.data
      : {
          chapter_list: [],
          category_list: [],
          district_list: [],
          event_type_list: [],
        };
  },
);

export default getCitySructureData;
