import React from 'react';

const wrapperStyle = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  zIndex: 1000,
  color: 'grey',
  fontSize: '4em',
  fontWeight: '100',
  textAlign: 'center',
};

const messageStyle = {
  fontSize: '20px',
};

const NotFound = ({ message }) => {
  let print = '';

  if (message === 'no_access') print = 'You have no access to see this page';

  if (message === 'removed') print = 'This item was removed';

  if (print.length === 0) print = message;

  return (
    <div style={wrapperStyle}>
      <div>
        <span>ERROR</span>
        {print && <div style={messageStyle}>{print}</div>}
      </div>
    </div>
  );
};

export default NotFound;
