// @flow
import React from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Chip from 'material-ui/Chip';

import PcCard from '../../../page/shared/PcCard.js';

import type { TAffiliateWidgetGroup } from '../types';

type TProps = {
  value: Array<number>,
  groupList: Array<TAffiliateWidgetGroup>,
  handleChange: (Array<number>) => void,
};

const AffiliateAssociatedGroups = ({
  value,
  groupList,
  handleChange,
}: TProps) => {
  const selected = value || [];

  const handleSelectChange = (event, index, values) => {
    handleChange(values);
  };

  const onChipRemoveClick = group_id_to_be_removed => {
    const filtered = selected.filter(id => id !== group_id_to_be_removed);
    handleChange(filtered);
  };

  const chip_data: Array<TAffiliateWidgetGroup> = groupList.filter(
    (g: TAffiliateWidgetGroup) =>
      selected.includes(g.affiliate_target_group_id),
  );

  return (
    <PcCard
      cardTitle="Associated groups"
      description="If group choosen widget will be displayed only if group conditions are met">
      <SelectField
        multiple={true}
        hintText="Select group"
        value={selected}
        onChange={handleSelectChange}>
        {groupList.map(({ affiliate_target_group_id, name }) => (
          <MenuItem
            key={affiliate_target_group_id}
            insetChildren={true}
            checked={selected.includes(affiliate_target_group_id)}
            value={affiliate_target_group_id}
            primaryText={name}
          />
        ))}
      </SelectField>

      <div style={{ display: 'flex' }}>
        {chip_data.map(({ affiliate_target_group_id, name }) => {
          return (
            <Chip
              key={affiliate_target_group_id}
              style={{ margin: '2px' }}
              onRequestDelete={() =>
                onChipRemoveClick(affiliate_target_group_id)
              }>
              {name}
            </Chip>
          );
        })}
      </div>
    </PcCard>
  );
};

export default AffiliateAssociatedGroups;
