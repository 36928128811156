import React from 'react';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';

import { save } from '../../../actions/save';
import { snackError } from '../../../actions/snacks';

export const SaveButtonPure = ({ onClick }) => {
  const button_style = { color: 'white' };
  const wrapper_style = {
    verticalAlign: '30%',
    display: 'inline-block',
  };
  return (
    <div style={wrapper_style}>
      <FlatButton style={button_style} onClick={onClick} label="save" />
    </div>
  );
};

/* formError - should be moved to action compleatly */

const SaveButtonHtml = ({
  data_modify,
  data_type,
  page_type,
  page_id,
  rights,
  saveData,
  errorText,
}) => {
  // this pages must be accessible for superadmin only
  const show_always = [
    'homepage',
    'pc_user',
    'affiliate',
    'affiliate-exclusions',
  ];

  const map_pageType_to_userRight = {
    city: 'city',
    chapter: 'chapter',
    category: 'category',
    venue: 'venue',
    feature: 'feature',
    // non direct
    city_home: 'city',
    event: 'venue',
    venue_comments: 'venue',
    feature_comments: 'feature',
    events_chapter: 'venue',
    event_type: 'venue',
    district: 'category',
  };

  const right_name = map_pageType_to_userRight[page_type] || {};
  const right_level = rights && rights[right_name] ? rights[right_name] : 0;

  const show_btn = show_always.includes(page_type)
    ? true
    : right_level >= 2
    ? true
    : false;

  return show_btn ? (
    <SaveButtonPure onClick={() => saveData(errorText)} />
  ) : null;
};

const mapStateToProps = ({ modiffy_update, form_errors, data, user_data }) => {
  const { page_id, data_type, page_type } = data;
  const { rights } = user_data;

  return {
    modiffy_update,
    data_type,
    page_type,
    page_id,
    rights,
    errorText: getFormError(form_errors),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveData: errorText => {
      if (errorText) dispatch(snackError(errorText));
      else dispatch(save());
    },
  };
};

const getFormError = errors => {
  if (!Object.keys(errors).length) return false;
  // returns first property of object
  return errors[Object.keys(errors)[0]];
};

const SaveButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveButtonHtml);

export default SaveButton;
