/**
 * @flow
 */

import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { connect } from 'react-redux';
import Chip from 'material-ui/Chip';

import { buildObj } from '../../helpers';
import { queueUpdate } from '../../actions/actions.js';

type feature_type = {
  name: string,
  feature_type_id: number,
};

type Props = {
  feature_type_list: Array<feature_type>,
  feature_type_id: number,
  feature_id: number,
  key: string,
  type_ids: Array<number>,
  dispatch: any,
};

type State = {
  feature_type_id: number,
  type_ids: Array<number>,
};

const ParentMain = ({
  feature_type_list,
  feature_type_id,
  handleParentChange,
}) => (
  <SelectField
    floatingLabelText="Feature type"
    value={feature_type_id}
    onChange={handleParentChange}
    autoWidth={true}>
    {feature_type_list.map((item, index) => (
      <MenuItem
        value={item.feature_type_id}
        primaryText={item.name}
        key={index}
      />
    ))}
  </SelectField>
);

// use feature type id to exclide it from list (don't allow additional category the same as main category)
const ParentsExtra = ({
  feature_type_list,
  feature_type_id,
  type_ids,
  handleExtraChange,
  handleExtraRemove,
}) => (
  <React.Fragment>
    <SelectField
      floatingLabelText="Additional categories"
      value={type_ids}
      onChange={handleExtraChange}
      multiple={true}
      selectionRenderer={(choosen: Array<number>) => (
        <span>{choosen.length ? choosen.length + ' items' : null}</span>
      )}
      className="flex-item"
      autoWidth={true}>
      {feature_type_list.map((item, index) => {
        if (feature_type_id === item.feature_type_id) return null;
        return (
          <MenuItem
            value={item.feature_type_id}
            primaryText={item.name}
            key={index}
          />
        );
      })}
    </SelectField>

    {type_ids &&
      type_ids.map(id => {
        const feature_type = feature_type_list.find(
          feature_type => feature_type.feature_type_id === id,
        );
        if (!feature_type) return null;
        if (feature_type_id === id) return null;
        return (
          <Chip
            style={{ margin: '2px' }}
            onRequestDelete={() => handleExtraRemove(id)}
            key={id}>
            {feature_type.name}
          </Chip>
        );
      })}
  </React.Fragment>
);

class FeatureParentsContainer extends Component<Props, State> {
  state = {
    feature_type_id: this.props.feature_type_id,
    type_ids: this.props.type_ids,
  };

  /*
	constructor(props) {
		super(props);
		console.log('rendered');
	}
*/

  handleParentChange = (ev, i, feature_type_id: number) => {
    this.handleChange('feature_type_id', feature_type_id);
  };

  handleExtraChange = (ev, i, type_ids: Array<number>) => {
    this.handleChange('type_ids', type_ids);
  };

  handleExtraRemove = (feature_type_id: number) => {
    const type_ids = this.state.type_ids.filter(id => id !== feature_type_id);
    this.handleChange('type_ids', type_ids);
  };

  handleChange(key, val) {
    const { feature_id, dispatch } = this.props;
    const newItem = buildObj(key, val);
    this.setState(newItem);
    dispatch(queueUpdate('feature', feature_id, key, val));
    //console.log(JSON.stringify(newItem));
  }

  render() {
    const { feature_type_list } = this.props;
    const { type_ids, feature_type_id } = this.state;

    return (
      <React.Fragment>
        <div className="row-flex">
          <ParentMain
            feature_type_list={feature_type_list}
            feature_type_id={feature_type_id}
            handleParentChange={this.handleParentChange}
          />
        </div>
        <div className="row-flex">
          <ParentsExtra
            feature_type_list={feature_type_list}
            feature_type_id={feature_type_id}
            type_ids={type_ids}
            handleExtraChange={this.handleExtraChange}
            handleExtraRemove={this.handleExtraRemove}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { feature_id, feature_type_id, type_ids } = data.feature;
  const { feature_type_list, data_key } = data;

  return {
    feature_type_list,
    feature_type_id,
    type_ids,
    feature_id,
    key: data_key,
  };
};

const FeatureTypesParent = connect(mapStateToProps)(FeatureParentsContainer);

export default FeatureTypesParent;
