import React from 'react';
import IconButton from 'material-ui/IconButton';
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import ArrowForward from 'material-ui/svg-icons/navigation/arrow-forward';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const getNeighbor = (venue_list, current_id, type) => {
  const type_flag = type === 'next' ? true : false; /* to not compare string */
  const l = venue_list.length;
  for (let i = 0; i < l; i++) {
    const venue = venue_list[i];
    if (venue.venue_id !== current_id) continue;

    //prev
    if (!type_flag && i !== 0) return venue_list[i - 1];

    // next
    if (type_flag && i < l - 1) return venue_list[i + 1];
  }
};

const BtnView = ({ venue_list, current_id, type }) => {
  const venue = getNeighbor(venue_list, current_id, type);

  const link = venue ? '/venue/' + venue.symbol + '/' + venue.venue_id : null;

  return (
    <div className="md-up">
      <IconButton
        disabled={link ? false : true}
        tooltip={venue ? venue.name : false}>
        {link && (
          <Link to={link}>
            {type === 'next' && <ArrowForward color="white" />}
            {type !== 'next' && <ArrowBack color="white" />}
          </Link>
        )}
        {!link && type === 'next' && <ArrowForward color="white" />}

        {!link && type !== 'next' && <ArrowBack color="white" />}
      </IconButton>
    </div>
  );
};

const mapStatePrev = (state, ownProps) => {
  return {
    venue_list: state.data.venue_list || [] /* archived return null*/,
    current_id: state.data.venue.venue_id,
    type: ownProps.type,
  };
};

export default connect(mapStatePrev)(BtnView);
