// @flow

import { is_debug } from '../config/config';
import {
  indicatorOn,
  indicatorOff,
  cleanModiffy,
  clearPostData,
} from './actions';
import { DispatchProp } from 'react-redux';
import { snackSuccess, snackError } from './snacks';
import { leloFetch } from '../helpers';
import history from '../history.js';

type Tparams = {
  item_type: string,
  item_key: string,
  item_id: number,
  parent_url?: string,
};

export const remove = ({
  item_type,
  item_key,
  item_id,
  parent_url,
}: Tparams) => {
  return (dispatch: DispatchProp) =>
    dispatch(
      makeAction({
        action: 'remove',
        item_type,
        item_key,
        item_id,
        parent_url,
      }),
    );
};

export const archive = ({
  item_type,
  item_key,
  item_id,
  parent_url,
}: Tparams) => {
  return (dispatch: DispatchProp) =>
    dispatch(
      makeAction({
        action: 'archive',
        item_type,
        item_key,
        item_id,
        parent_url,
      }),
    );
};

const makeAction = ({
  action,
  item_type,
  item_key,
  item_id,
  parent_url,
}: Tparams & { action: 'remove' | 'archive' }) => {
  return dispatch => {
    dispatch(indicatorOn());
    dispatch(cleanModiffy());
    dispatch(clearPostData());
    return leloFetch('remove', {
      method: 'POST',
      json_data: { action, item_type, item_key, item_id },
    }).then(
      data => {
        dispatch(indicatorOff());

        if (data.success) {
          const message =
            action === 'archive' ? 'Item archived' : 'Item removed';
          dispatch(snackSuccess(message));
          if (parent_url) {
            history.push('/' + parent_url);
          }
        }
        if (data.error) {
          if (is_debug) dispatch(snackError('ERROR: ' + data.error));
          else dispatch(snackError('ERROR: something went wrong'));
        }
      },
      error => {
        dispatch(indicatorOff());
        dispatch(snackError('ERROR: ' + error));
      },
    );
  };
};
