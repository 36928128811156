export default [
  ['Top', 'breadcrumbs'],
  ['Description', 'card_description'],
  ['Researcher comments', 'card_researcher_comments'],
  ['Images text', 'card_images_text'],
  ['Images background', 'card_images_bg'],
  ['Connected venues', 'card_venues'],
  ['Connected features', 'card_features'],
  ['Connected categories', 'card_categories'],
  ['Meta tags', 'card_meta_tags'],
  ['Parents', 'card_parents'],
  ['Offers', 'card_offer'],
  ['Language', 'card_language'],
  ['Redirect', 'card_redirect'],
];
