import React, { Component } from 'react';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import update from 'immutability-helper';
import Close from 'material-ui/svg-icons/navigation/close';
import Add from 'material-ui/svg-icons/content/add';
import IconButton from 'material-ui/IconButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { RowFlexTop } from '../../shared/Grid';
import { formatDateDb, formatHourDb } from '../../../helpers';
import VenueSearch from './VenueSearch';

export class DateRange extends Component {
  handleAddHour = () => {
    this.props.stateUp({
      hours: update(this.props.hours, { $push: [[null, null]] }),
    });
  };

  handleRemoveHour = hour_index => {
    let new_hours = update(this.props.hours, { $splice: [[hour_index, 1]] });
    if (!new_hours.length) new_hours = [[null, null]];
    this.props.stateUp({
      hours: update(this.props.hours, { $set: new_hours }),
    });
  };

  handleChangeHourStart = (hour_index, val) => {
    const hour = formatHourDb(val);
    // eslint-disable-next-line
    let hours = update(this.props.hours, {
      // eslint-disable-next-line
      [hour_index]: { [0]: { $set: hour } },
    });

    // remove empty
    const filtered = hours.filter(item => item[0] !== null); // item[0] = start

    const hours_sorted = filtered.sort((a, b) => {
      const start_a = a[0]; // [0] - start, [1] = stop
      const start_b = b[0];
      const start_a_h = start_a.split(':')[0];
      const start_b_h = start_b.split(':')[0];
      return start_a_h > start_b_h;
    });
    this.props.stateUp({
      hours: update(this.props.hours, { $set: hours_sorted }),
    });
  };

  handleChangeHourStop = (hour_index, val) => {
    const hour = formatHourDb(val);
    this.props.stateUp({
      // eslint-disable-next-line
      hours: update(this.props.hours, {
        [hour_index]: { [1]: { $set: hour } },
      }),
    });
  };

  handleChangeDateStart = val => {
    this.props.stateUp({
      date_start: formatDateDb(val),
    });
  };

  handleChangeDateStop = val => {
    this.props.stateUp({
      date_stop: formatDateDb(val),
    });
  };

  handleClean = () => {
    this.props.stateUp({
      date_start: null,
      date_stop: null,
      venue_id: null,
      hours: [[null, null]],
      datesofweek: null,
    });
  };

  handelAddVenue = venue_id => {
    this.props.stateUp({
      venue_id,
    });
  };

  handelRemoveVenue = () => {
    this.props.stateUp({
      venue_id: null,
    });
  };

  handleUseVenueHour = val => {
    this.props.stateUp({
      hour_from_venue: val ? 1 : null,
      hours: [[null, null]],
    });
  };

  handleDowChange = (event, index, values) => {
    const new_values = values.sort((a, b) => a > b);

    this.props.stateUp({
      datesofweek: new_values.join(''),
    });
  };

  selectionRender = values => {
    const days = [
      ['1', 'Mo'],
      ['2', 'Tu'],
      ['3', 'We'],
      ['4', 'Th'],
      ['5', 'Fr'],
      ['6', 'Sa'],
      ['7', 'Su'],
    ];
    const days_filteres = days.filter(el => values.includes(el[0]));
    return days_filteres.map(el => el[1]).join(', ');
  };

  render() {
    const {
      date_start,
      date_stop,
      datesofweek,
      hours,
      hour_from_venue,
      venue_id,
    } = this.props;

    const styles = {
      input_date: {
        width: '100px',
        overflow: 'hidden',
      },
      input_hour: {
        width: '80px',
        overflow: 'hidden',
      },
      inline: {
        display: 'inline-block',
      },
      icon_button: {
        padding: 0,
        width: 'auto',
        height: 'auto',
      },
      dow: {
        width: '220px',
      },
    };

    return (
      <RowFlexTop>
        <div>
          <DatePicker
            floatingLabelText="date start"
            autoOk={true}
            style={{
              ...styles.input_date,
              ...styles.inline,
              marginRight: '15px',
            }}
            value={date_start ? new Date(date_start) : null}
            onChange={(something, val) => this.handleChangeDateStart(val)}
          />
          <DatePicker
            floatingLabelText="date end"
            autoOk={true}
            style={{ ...styles.input_date, ...styles.inline }}
            value={date_stop ? new Date(date_stop) : null}
            onChange={(something, val) => this.handleChangeDateStop(val)}
          />
          <div>
            {date_start &&
              date_stop &&
              Date.parse(date_stop) - Date.parse(date_start) > 604800000 && ( // seven days
                <SelectField
                  multiple={true}
                  floatingLabelText="Days of week"
                  onChange={this.handleDowChange}
                  value={datesofweek ? datesofweek.split('') : null}
                  selectionRenderer={this.selectionRender}
                  style={styles.dow}
                  autoWidth={true}
                  disabled={
                    !date_start ||
                    !date_stop ||
                    Date.parse(date_stop) - Date.parse(date_start) <
                      7 * 24 * 3600 * 1000
                      ? true
                      : false
                  }>
                  {[
                    'Monday',
                    'Tuesday',
                    'Wednsday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday',
                  ].map((name, index) => (
                    <MenuItem
                      key={index}
                      insetChildren={true}
                      checked={
                        !datesofweek ||
                        datesofweek.includes((index + 1).toString())
                      }
                      primaryText={name}
                      value={(index + 1).toString()}
                    />
                  ))}
                </SelectField>
              )}
          </div>
        </div>
        <div>
          {hours.map((hour, hour_index) => {
            const start = hour[0];
            const stop = hour[1];
            const start_hour = start ? start.split(':')[0] : null;
            const start_min = start ? start.split(':')[1] : null;
            const stop_hour = stop ? stop.split(':')[0] : null;
            const stop_min = stop ? stop.split(':')[1] : null;
            const start_obj = start
              ? new Date(2000, 1, 1, start_hour, start_min)
              : null;
            const stop_obj = stop
              ? new Date(2000, 1, 1, stop_hour, stop_min)
              : null;

            return (
              <div key={hour_index}>
                <TimePicker
                  floatingLabelText="hour start"
                  minutesStep={5}
                  format="24hr"
                  style={{
                    ...styles.input_hour,
                    ...styles.inline,
                    marginRight: '15px',
                  }}
                  value={start_obj}
                  onChange={(lalala, val) =>
                    this.handleChangeHourStart(hour_index, val)
                  }
                  disabled={hour_from_venue ? true : false}
                />

                <TimePicker
                  floatingLabelText="hour end"
                  minutesStep={5}
                  format="24hr"
                  style={{ ...styles.input_hour, ...styles.inline }}
                  value={stop_obj}
                  onChange={(lalala, val) =>
                    this.handleChangeHourStop(hour_index, val)
                  }
                  disabled={hour_from_venue ? true : false}
                />

                <IconButton
                  onClick={() => this.handleAddHour()}
                  tooltip="add hour"
                  style={{
                    ...styles.icon_button,
                    ...styles.inline,
                    verticalAlign: 'top',
                    marginTop: '32px',
                    marginRight: '15px',
                    visibility:
                      hour_index === hours.length - 1 &&
                      start_obj &&
                      !hour_from_venue
                        ? 'visible'
                        : 'hidden',
                  }}>
                  <Add />
                </IconButton>

                {hours.length > 1 && (
                  <IconButton
                    onClick={() => this.handleRemoveHour(hour_index)}
                    tooltip="remove hour"
                    style={{
                      ...styles.icon_button,
                      ...styles.inline,
                      verticalAlign: 'top',
                      marginTop: '32px',
                      marginRight: '15px',
                    }}>
                    <Close />
                  </IconButton>
                )}
              </div>
            );
          })}
        </div>

        <div style={{ width: '250px' }}>
          <VenueSearch
            venue_id={venue_id ? parseInt(venue_id, 10) : null}
            hour_from_venue={hour_from_venue ? true : false}
            handleAddVenue={venue_id => this.handelAddVenue(venue_id)}
            handleRemoveVenue={() => this.handelRemoveVenue()}
            handleUseVenueHour={val => this.handleUseVenueHour(val)}
          />
        </div>
      </RowFlexTop>
    );
  }
}

export default DateRange;
