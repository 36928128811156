import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const propTypes = {
  // as in 'material-ui/TextField' +
  handleAddError: PropTypes.func,
  handleRemoveError: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func, // check it
  focusInput: PropTypes.func,
};

class PcTextField extends Component {
  constructor(props) {
    super(props);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  focusInput = () => {
    this.refs.element.focus();
  };

  init({ value, type, required }) {
    const value_claned = value || '';

    this.setState(
      prevState => {
        return {
          value: value_claned || '',
          type: type || 'text',
          required: required || false,
          errorText: prevState ? prevState.errorText : '',
          error_required: prevState ? prevState.error_required : false,
          validate_on_change: prevState ? prevState.validate_on_change : false,
          typeDef: this.filterInputType(type),
        };
      },
      //,
      //() => this.validateInput()
    );
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  upHandleRemoveError() {
    if (this.props.handleRemoveError) this.props.handleRemoveError();
  }

  upHandleAddError(error_text) {
    if (this.props.handleAddError) this.props.handleAddError(error_text);
  }

  filterInputType(val) {
    const types = {
      email: {
        term: /^.+@.+\..+$/,
        errorMessage: 'This is not valid email address.',
      },
      url: {
        // eslint-disable-next-line
        term: /((http|https)\:\/\/)?[a-zA-Z0-9\.\/\?\:@\-_=#]+\.([a-zA-Z0-9\&\.\/\?\:@\-_=#])*/,
        errorMessage: 'This is not valid url address.',
      },
    };

    if (!val || val === 'text') return;
    if (!types.hasOwnProperty(val)) return;

    return types[val];
  }

  validateInput() {
    const { typeDef, value, errorText, validate_on_change } = this.state;

    // empty - nothing to validate. Remove error if any
    if (!value) {
      if (errorText) {
        this.upHandleRemoveError();
      }

      this.setState({
        errorText: '',
        validate_on_change: false,
      });
      return;
    }

    if (!typeDef) return;

    const errorMessage = typeDef.errorMessage;

    // input not valid
    if (!typeDef.term.test(value)) {
      if (this.state.errorText) return;

      this.setState(
        {
          errorText: errorMessage,
          validate_on_change: true,
        },

        () => {
          this.upHandleAddError(errorMessage);
        },
      );
    } else {
      /*valid*/

      if (errorText || validate_on_change) {
        this.setState({
          errorText: '',
          validate_on_change: false,
        });
        this.upHandleRemoveError();
      }
    }
  }

  checkRequired() {
    const { required, error_required, value } = this.state;

    const error_text = 'This field is required';

    if (error_required && !value) {
      return false;
    } else if (required && !error_required && !value) {
      this.upHandleAddError(error_text);

      this.setState({
        errorText: error_text,
        error_required: true,
      });
      return false;
    } else if (required && error_required && value) {
      this.upHandleRemoveError();

      this.setState({
        errorText: '',
        error_required: false,
      });
      return true;
    }
    return true;
  }

  propChangeDebounced = debounce(value => {
    this.props.handleChange(value);
  }, 500);

  handleChange(event) {
    const value = event.target.value;

    this.setState({ value }, () => {
      if (!this.checkRequired()) return;
      if (this.state.validate_on_change) {
        this.validateInput();
      }
      if (typeof this.props.handleChange === 'undefined') return;
      this.propChangeDebounced(value);
    });
  }

  handleBlur(event, value) {
    if (!this.checkRequired()) return;

    this.validateInput();

    if (typeof this.props.handleBlur !== 'undefined') {
      this.props.handleBlur(event.target.value);
    }
  }

  handleFocus = event => {
    if (this.state.error_required) {
      /* clear error on focus when error comes from 'required' conditionion */
      this.setState({
        errorText: '',
        error_required: false,
      });
      this.upHandleRemoveError();
    }
  };

  componentDidMount() {
    if (this.checkRequired()) {
      /*catch if required is filled firsts */
      this.validateInput();
    }
  }

  render() {
    let {
      handleChange,
      handleBlur,
      data_id,
      data_field,
      handleAddError,
      handleRemoveError,
      custom_key,
      ...p
    } = this.props;

    // might be set as false
    p.fullWidth = typeof p.fullWidth !== 'undefined' ? p.fullWidth : true;

    p.name = p.name || 'empty-name';

    return (
      <TextField
        {...p}
        //className	=	{p.multiLine ? '' : 'shrink'}
        className=""
        value={this.state.value || ''}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        errorText={this.state.errorText}
        errorStyle={{ position: 'absolute', bottom: '-10px' }}
        ref="element"
      />
    );
  }
}

PcTextField.propTypes = propTypes;

export default PcTextField;
