/**
 * @flow
 */

import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { connect } from 'react-redux';
import Chip from 'material-ui/Chip';

import PcCard from '../shared/PcCard.js';
import { buildObj } from '../../helpers';
import { queueUpdate } from '../../actions/actions.js';

type event_type = {
  name: string,
  event_type_id: number,
};

type Props = {
  event_type_list: Array<event_type>,
  event_type_id: number,
  event_id: number,
  key: string,
  type_ids: Array<number>,
  dispatch: any,
};

type State = {
  event_type_id: number,
  type_ids: Array<number>,
};

const ParentMain = ({ event_type_list, event_type_id, handleParentChange }) => (
  <SelectField
    floatingLabelText="Event type"
    value={event_type_id}
    onChange={handleParentChange}
    autoWidth={true}>
    {event_type_list.map((item, index) => (
      <MenuItem
        value={item.event_type_id}
        primaryText={item.name}
        key={index}
      />
    ))}
  </SelectField>
);

// use event type id to exclide it from list (don't allow additional category the same as main category)
const ParentsExtra = ({
  event_type_list,
  event_type_id,
  type_ids,
  handleExtraChange,
  handleExtraRemove,
}) => (
  <React.Fragment>
    <SelectField
      floatingLabelText="Additional categories"
      value={type_ids}
      onChange={handleExtraChange}
      multiple={true}
      selectionRenderer={(choosen: Array<number>) => (
        <span>{choosen.length ? choosen.length + ' items' : null}</span>
      )}
      className="flex-item"
      autoWidth={true}>
      {event_type_list.map((item, index) => {
        if (event_type_id === item.event_type_id) return null;
        return (
          <MenuItem
            value={item.event_type_id}
            primaryText={item.name}
            key={index}
          />
        );
      })}
    </SelectField>

    {type_ids &&
      type_ids.map(id => {
        const event_type = event_type_list.find(
          event_type => event_type.event_type_id === id,
        );
        if (!event_type) return null;
        if (event_type_id === id) return null;
        return (
          <Chip
            style={{ margin: '2px' }}
            onRequestDelete={() => handleExtraRemove(id)}
            key={id}>
            {event_type.name}
          </Chip>
        );
      })}
  </React.Fragment>
);

class EventParentsContainer extends Component<Props, State> {
  state = {
    event_type_id: this.props.event_type_id,
    type_ids: this.props.type_ids,
  };

  /*
	constructor(props) {
		super(props);
		console.log('rendered');
	}
*/

  handleParentChange = (ev, i, event_type_id: number) => {
    this.handleChange('event_type_id', event_type_id);
  };

  handleExtraChange = (ev, i, type_ids: Array<number>) => {
    this.handleChange('type_ids', type_ids);
  };

  handleExtraRemove = (event_type_id: number) => {
    const type_ids = this.state.type_ids.filter(id => id !== event_type_id);
    this.handleChange('type_ids', type_ids);
  };

  handleChange(key, val) {
    const { event_id, dispatch } = this.props;
    const newItem = buildObj(key, val);
    this.setState(newItem);
    dispatch(queueUpdate('event', event_id, key, val));
    //console.log(JSON.stringify(newItem));
  }

  render() {
    const { event_type_list } = this.props;
    const { type_ids, event_type_id } = this.state;

    return (
      <PcCard cardTitle="Display in" id="card_parents">
        <div className="row-flex">
          <ParentMain
            event_type_list={event_type_list}
            event_type_id={event_type_id}
            handleParentChange={this.handleParentChange}
          />
        </div>
        <div className="row-flex">
          <ParentsExtra
            event_type_list={event_type_list}
            event_type_id={event_type_id}
            type_ids={type_ids}
            handleExtraChange={this.handleExtraChange}
            handleExtraRemove={this.handleExtraRemove}
          />
        </div>
      </PcCard>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { event_id, event_type_id, type_ids } = data.event;
  const { event_type_list, data_key } = data;

  return {
    event_type_list,
    event_type_id,
    type_ids,
    event_id,
    key: data_key,
  };
};

const EventParent = connect(mapStateToProps)(EventParentsContainer);

export default EventParent;
