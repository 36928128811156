// @flow

import React from 'react';
import { connect } from 'react-redux';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { Link } from 'react-router-dom';

import PcAppBar from '../shared/TopBar/PcAppBar';
import Center from '../shared/Center';
import BreadCrumbs from '../shared/BreadCrumbs';

import withUserDataFetched from '../../core/withUserDataFetched';
import { withAccessSuperadmin } from '../../core/withAccess';

const breadcrumbs = [
  {
    url: '/',
    name: 'home',
  },
  {
    url: '/affiliate',
    name: 'Affliliate home',
  },
];

const AffiliateHome = ({ country_list }) => (
  <>
    <PcAppBar title="Affiliate Widgets" no_drawer={true} />
    <div className="pd pc-content-wide">
      <div className="pd">
        <BreadCrumbs link_data={breadcrumbs} />
      </div>
      <Center>
        <Link className="the_link" to="/affiliate/global/all">
          Global widgets
        </Link>
        <br />
        <br />
        <br />
        Country widgets:
        <br />
        <SelectField hintText="Select country">
          {country_list &&
            country_list.map(country => (
              <MenuItem
                containerElement={
                  <Link
                    to={'/affiliate/by_country/' + country.country_id + '/all'}
                  />
                }
                value={1}
                primaryText={country.name}
                key={country.country_id}
                className="the_link"
                style={country.active ? {} : { color: 'grey' }}
              />
            ))}
        </SelectField>
        <br />
        <br />
        <Link className="the_link" to="/affiliate/target_group/all">
          Target groups
        </Link>
      </Center>
    </div>
  </>
);

const WithAccess = withAccessSuperadmin(AffiliateHome);

const mapStateToProps = ({ constants }) => {
  return {
    country_list: constants.country_list,
  };
};

export default withUserDataFetched(connect(mapStateToProps)(WithAccess));
