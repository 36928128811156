export default [
  ['Settings', 'breadcrumbs'],
  ['Description', 'card_description'],
  ['Picks', 'card_picks'],
  ['Images text', 'card_images_text'],
  ['Images background', 'card_images_bg'],
  ['Image App mobile', 'card_images_app'],
  ['Meta tags', 'card_meta_tags'],
  ['Categories ordering', 'card_categories'],
  ['Redirect', 'card_redirect'],
];
