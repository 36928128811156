// @flow

import React from 'react';
import FlatButton from 'material-ui/FlatButton';

import { RowFlexCenter } from '../shared/Grid';
import FileImage from '../shared/Images/FileImage';

type TVenueCardMapExportImage = {
  img_url: string | null,
  removedClicked: boolean,
  onRemove: () => void,
};

export const VenueCardMapExportImage = ({
  img_url,
  removedClicked,
  onRemove,
}: TVenueCardMapExportImage) => (
  <div>
    {img_url && !removedClicked && (
      <RowFlexCenter>
        <img
          src={img_url}
          style={{ maxWidth: '100px', height: 'auto' }}
          alt=""
        />
        <FlatButton label="Remove Photo" onClick={onRemove} />
      </RowFlexCenter>
    )}
    {img_url && removedClicked && (
      <div className="pd-h">Photo will be removed (click save) </div>
    )}

    {!img_url && (
      <div className="pd-h">
        <FileImage
          name="miniguide_photo"
          label="select file"
          accept="image/jpeg"
        />
      </div>
    )}
  </div>
);
