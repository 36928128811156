import React from 'react';
import { connect } from 'react-redux';
import MapLocation from '../shared/MapLocation';
import { queueUpdate } from '../../actions/actions';

const cityMap = ({ dispatch, city_id, ...rest }) => (
  <MapLocation
    handleChange={coords => {
      dispatch(queueUpdate('city', city_id, 'lat', coords.lat));
      dispatch(queueUpdate('city', city_id, 'lon', coords.lng));
    }}
    {...rest}
  />
);

const mapStateToProps = ({ data }) => {
  const { city_id, lat, lon, geo_name, name } = data.city;
  const coords = lat ? { lat: lat, lng: lon } : null;
  return {
    coords,
    city_id,
    address_text: geo_name || name,
  };
};

export default connect(mapStateToProps)(cityMap);
