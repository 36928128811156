import PcTextField from '../../shared/PcTextField';
import React, { Component } from 'react';

export default class InputCell extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value, data_id) {
    this.props.handleChange(value, this.props.name);
  }

  render() {
    const p = this.props;
    return (
      <PcTextField
        floatingLabelText={p.label}
        value={p.value || ''}
        handleChange={this.handleChange}
        name="any"
        type={p.type}
        min={p.min}
      />
    );
  }
}
