const loadData = (state = {}, action) => {
  switch (action.type) {
    case 'LOAD_CONSTANTS':
      return action.data;

    case 'LOGGED_OUT':
      return {};

    case 'CLEAR_CONSTANTS' /* added - riggered when login screen even if not logout link click */:
      return {};

    default:
      return state;
  }
};

export default loadData;
