import React from 'react';
import { connect } from 'react-redux';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { Link } from 'react-router-dom';

const TheItem = category => {
  const name = category.parent_id ? ' - ' + category.name : category.name;
  const style = category.active ? {} : { color: 'grey' };
  const link = '/category/' + category.symbol + '/' + category.category_id;
  return (
    <MenuItem
      value={category.category_id}
      primaryText={name}
      style={style}
      containerElement={<Link to={link} />}
      key={category.category_id}
    />
  );
};

const categoryList = category_list => {
  let items = [];
  category_list.map(category => {
    if (category.parent_id) return null;
    items.push(TheItem(category));
    category_list.map(sub_category => {
      if (sub_category.parent_id !== category.category_id) return null;
      return items.push(TheItem(sub_category));
    });
    return null;
  });
  return items;
};

const CategorySelectView = ({ category_id, category_list }) => {
  if (category_list.length === 0)
    return <div className="pd text-center">No categories yet</div>;

  return (
    <SelectField
      value={category_id}
      floatingLabelText="category"
      autoWidth={true}
      style={{ maxWidth: '100%' }}>
      {category_list.length > 0 && categoryList(category_list)}
    </SelectField>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    category_list: state.data.category_list || [],
    category_id: ownProps.category_id,
  };
};

export default connect(mapStateToProps)(CategorySelectView);
