const snackSuccess = (state = false, action) => {
  switch (action.type) {
    case 'SNACK_SUCCESS_ON':
      return action.message;

    case 'SNACK_SUCCESS_OFF':
      return false;

    default:
      return state;
  }
};

const snackError = (state = false, action) => {
  switch (action.type) {
    case 'SNACK_ERROR_ON':
      return action.message;

    case 'SNACK_ERROR_OFF':
      return false;

    default:
      return state;
  }
};

export { snackSuccess, snackError };
