import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import { connect } from 'react-redux';

import PcTextField from '../PcTextField';
import addNew from '../../../actions/addNew';

class AddNewEventType extends React.Component {
  state = {
    name: '',
  };

  componentDidMount() {
    window.setTimeout(
      function () {
        const ref = this.refs['name'];
        if (ref) ref.focusInput();
      }.bind(this),
      200,
    );
  }

  handleNameChange = name => this.setState({ name });

  handleAddNewClick = () => {
    const { name } = this.state;
    const { city_id } = this.props;
    this.props.handleCloseModal();
    this.props.dispatch(addNew('event_type', { name, city_id }));
  };

  render() {
    return (
      <Dialog
        title="Add new event category"
        actions={[
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={this.props.handleCloseModal}
          />,
          <FlatButton
            label="Add"
            primary={true}
            keyboardFocused={true}
            onClick={this.handleAddNewClick}
            disabled={!this.state.name}
          />,
        ]}
        modal={false}
        open={true}
        onRequestClose={this.props.handleCloseModal}>
        <PcTextField
          handleChange={this.handleNameChange}
          floatingLabelText="name"
          value={this.state.name}
          ref="name"
        />
      </Dialog>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    city_id: data.city.city_id,
  };
};

export default connect(mapStateToProps)(AddNewEventType);
