export const languages = [
  ['ab', 'Abkhaz'],
  ['aa', 'Afar'],
  ['af', 'Afrikaans'],
  ['ak', 'Akan'],
  ['sq', 'Albanian'],
  ['am', 'Amharic'],
  ['ar', 'Arabic'],
  ['an', 'Aragonese'],
  ['hy', 'Armenian'],
  ['as', 'Assamese'],
  ['av', 'Avaric'],
  ['ae', 'Avestan'],
  ['ay', 'Aymara'],
  ['az', 'Azerbaijani'],
  ['bm', 'Bambara'],
  ['ba', 'Bashkir'],
  ['eu', 'Basque'],
  ['be', 'Belarusian'],
  ['bn', 'Bengali, Bangla'],
  ['bh', 'Bihari'],
  ['bi', 'Bislama'],
  ['bs', 'Bosnian'],
  ['br', 'Breton'],
  ['bg', 'Bulgarian'],
  ['my', 'Burmese'],
  ['ca', 'Catalan'],
  ['ch', 'Chamorro'],
  ['ce', 'Chechen'],
  ['ny', 'Chichewa, Chewa, Nyanja'],
  ['zh', 'Chinese'],
  ['cv', 'Chuvash'],
  ['kw', 'Cornish'],
  ['co', 'Corsican'],
  ['cr', 'Cree'],
  ['hr', 'Croatian'],
  ['cs', 'Czech'],
  ['da', 'Danish'],
  ['dv', 'Divehi, Dhivehi, Maldivian'],
  ['nl', 'Dutch'],
  ['dz', 'Dzongkha'],
  ['eo', 'Esperanto'],
  ['et', 'Estonian'],
  ['ee', 'Ewe'],
  ['fo', 'Faroese'],
  ['fj', 'Fijian'],
  ['fi', 'Finnish'],
  ['fr', 'French'],
  ['ff', 'Fula, Fulah, Pulaar, Pular'],
  ['gl', 'Galician'],
  ['lg', 'Ganda'],
  ['ka', 'Georgian'],
  ['de', 'German'],
  ['el', 'Greek (modern)'],
  ['gn', 'Guaraní'],
  ['gu', 'Gujarati'],
  ['ht', 'Haitian, Haitian Creole'],
  ['ha', 'Hausa'],
  ['he', 'Hebrew (modern)'],
  ['hz', 'Herero'],
  ['hi', 'Hindi'],
  ['ho', 'Hiri Motu'],
  ['hu', 'Hungarian'],
  ['is', 'Icelandic'],
  ['io', 'Ido'],
  ['ig', 'Igbo'],
  ['id', 'Indonesian'],
  ['ia', 'Interlingua'],
  ['ie', 'Interlingue'],
  ['iu', 'Inuktitut'],
  ['ik', 'Inupiaq'],
  ['ga', 'Irish'],
  ['it', 'Italian'],
  ['ja', 'Japanese'],
  ['jv', 'Javanese'],
  ['kl', 'Kalaallisut, Greenlandic'],
  ['kn', 'Kannada'],
  ['kr', 'Kanuri'],
  ['ks', 'Kashmiri'],
  ['kk', 'Kazakh'],
  ['km', 'Khmer'],
  ['ki', 'Kikuyu, Gikuyu'],
  ['rw', 'Kinyarwanda'],
  ['rn', 'Kirundi'],
  ['kv', 'Komi'],
  ['kg', 'Kongo'],
  ['ko', 'Korean'],
  ['ku', 'Kurdish'],
  ['kj', 'Kwanyama, Kuanyama'],
  ['ky', 'Kyrgyz'],
  ['lo', 'Lao'],
  ['la', 'Latin'],
  ['lv', 'Latvian'],
  ['li', 'Limburgish, Limburgan, Limburger'],
  ['ln', 'Lingala'],
  ['lt', 'Lithuanian'],
  ['lu', 'Luba-Katanga'],
  ['lb', 'Luxembourgish, Letzeburgesch'],
  ['mk', 'Macedonian'],
  ['mg', 'Malagasy'],
  ['ms', 'Malay'],
  ['ml', 'Malayalam'],
  ['mt', 'Maltese'],
  ['gv', 'Manx'],
  ['mi', 'Māori'],
  ['mr', 'Marathi (Marāṭhī)'],
  ['mh', 'Marshallese'],
  ['mn', 'Mongolian'],
  ['na', 'Nauru'],
  ['nv', 'Navajo, Navaho'],
  ['ng', 'Ndonga'],
  ['ne', 'Nepali'],
  ['nd', 'Northern Ndebele'],
  ['se', 'Northern Sami'],
  ['no', 'Norwegian'],
  ['nb', 'Norwegian Bokmål'],
  ['nn', 'Norwegian Nynorsk'],
  ['ii', 'Nuosu'],
  ['oc', 'Occitan'],
  ['oj', 'Ojibwe, Ojibwa'],
  ['cu', 'Old Church Slavonic, Church Slavonic, Old Bulgarian'],
  ['or', 'Oriya'],
  ['om', 'Oromo'],
  ['os', 'Ossetian, Ossetic'],
  ['pi', 'Pāli'],
  ['pa', 'Panjabi, Punjabi'],
  ['ps', 'Pashto, Pushto'],
  ['fa', 'Persian (Farsi)'],
  ['pl', 'Polish'],
  ['pt', 'Portuguese'],
  ['qu', 'Quechua'],
  ['ro', 'Romanian'],
  ['rm', 'Romansh'],
  ['rh', 'Rothongua'],
  ['ru', 'Russian'],
  ['sm', 'Samoan'],
  ['sg', 'Sango'],
  ['sa', 'Sanskrit (Saṁskṛta)'],
  ['sc', 'Sardinian'],
  ['gd', 'Scottish Gaelic, Gaelic'],
  ['sr', 'Serbian'],
  ['sn', 'Shona'],
  ['sd', 'Sindhi'],
  ['si', 'Sinhala, Sinhalese'],
  ['sk', 'Slovak'],
  ['sl', 'Slovene'],
  ['so', 'Somali'],
  ['nr', 'Southern Ndebele'],
  ['st', 'Southern Sotho'],
  ['es', 'Spanish'],
  ['su', 'Sundanese'],
  ['sw', 'Swahili'],
  ['ss', 'Swati'],
  ['sv', 'Swedish'],
  ['tl', 'Tagalog'],
  ['ty', 'Tahitian'],
  ['tg', 'Tajik'],
  ['ta', 'Tamil'],
  ['tt', 'Tatar'],
  ['te', 'Telugu'],
  ['th', 'Thai'],
  ['bo', 'Tibetan Standard, Tibetan, Central'],
  ['ti', 'Tigrinya'],
  ['to', 'Tonga (Tonga Islands)'],
  ['ts', 'Tsonga'],
  ['tn', 'Tswana'],
  ['tr', 'Turkish'],
  ['tk', 'Turkmen'],
  ['tw', 'Twi'],
  ['uk', 'Ukrainian'],
  ['ur', 'Urdu'],
  ['ug', 'Uyghur'],
  ['uz', 'Uzbek'],
  ['ve', 'Venda'],
  ['vi', 'Vietnamese'],
  ['vo', 'Volapük'],
  ['wa', 'Walloon'],
  ['cy', 'Welsh'],
  ['fy', 'Western Frisian'],
  ['wo', 'Wolof'],
  ['xh', 'Xhosa'],
  ['yi', 'Yiddish'],
  ['yo', 'Yoruba'],
  ['za', 'Zhuang, Chuang'],
  ['zu', 'Zulu'],
];
