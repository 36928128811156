import React, { Component } from 'react';
import PcCard from '../shared/PcCard.js';
import { connect } from 'react-redux';
import Checkbox from 'material-ui/Checkbox';
import { queueUpdate } from '../../actions/actions.js';

const ItemView = ({ item, services_arr, onCheck }) => {
  const { name, letter } = item;

  const checked = services_arr.find(val => {
    return val === letter;
  })
    ? true
    : false;

  return (
    <Checkbox
      label={name}
      name={letter}
      checked={checked}
      onCheck={onCheck}
      className="inline-block"
    />
  );
};

const ServicesView = ({ service_list, services_arr, onCheck }) => {
  return (
    <PcCard cardTitle="Services" id="card_services">
      <div id="services-columns">
        {service_list &&
          service_list.map((item, key) => (
            <div key={key}>
              <ItemView
                item={item}
                services_arr={services_arr}
                onCheck={onCheck}
              />
            </div>
          ))}
      </div>
    </PcCard>
  );
};

class ServicesWithState extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = this.buildState(props);
  }

  buildState({ services, venue_id }) {
    return {
      services_arr: services ? services.split('') : [],
      venue_id,
    };
  }

  componentWillReceiveProps({ services, venue_id }) {
    if (venue_id === this.state.venue_id) return;
    this.setState(this.buildState({ services, venue_id }));
  }

  handleChange(event) {
    const letter = event.target.name;

    let new_services_arr = [];

    if (
      this.state.services_arr.find(val => {
        return val === letter;
      })
    ) {
      //remmove letter
      new_services_arr = [...this.state.services_arr].filter(
        val => val !== letter,
      );
    } else {
      //add letter
      new_services_arr = [...this.state.services_arr];
      new_services_arr.push(letter);
    }

    this.setState({ services_arr: new_services_arr }, () =>
      this.props.handleModify(
        'venue',
        this.props.venue_id,
        'services',
        this.state.services_arr.join(''),
      ),
    );
  }

  render() {
    return (
      <ServicesView
        service_list={this.props.service_list}
        services_arr={this.state.services_arr}
        onCheck={this.handleChange}
      />
    );
  }
}

const mapStateToProps = ({ constants, data }) => {
  const { services, venue_id } = data.venue;

  return {
    services,
    venue_id,
    service_list: constants.service_list,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleModify: (...params) => {
      dispatch(queueUpdate(...params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesWithState);
