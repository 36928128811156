import React from 'react';
import { Card, CardTitle, CardText } from 'material-ui/Card';

const NotModal = ({ title, open, actions, children, overlay }) => {
  const style = {
    overlay: {
      backgroundColor: overlay ? 'rgba(0,0,0,0.2)' : '',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      width: '400px',
      maxWith: '90vw',
      margin: '10px',
    },
    actions: {
      marginTop: '10px',
      marginBottom: '-8px',
      textAlign: 'right',
    },
  };

  if (!open) return null;

  return (
    <div style={style.overlay}>
      <Card style={style.card} zDepth={3}>
        <CardTitle
          title={title}
          className={title ? '' : 'hide'}
          style={{ paddingBottom: '0' }}
        />
        <CardText style={title ? { paddingTop: '0' } : {}}>
          {children}
          <div style={style.actions}>{actions}</div>
        </CardText>
      </Card>
    </div>
  );
};

export default NotModal;
