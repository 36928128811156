import React, { Component } from 'react';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import update from 'immutability-helper';
import Close from 'material-ui/svg-icons/navigation/close';
import Add from 'material-ui/svg-icons/content/add';
import IconButton from 'material-ui/IconButton';
import RaisedButton from 'material-ui/RaisedButton';
import { RowFlexTop } from '../../shared/Grid';
import { formatDateDb, formatHourDb } from '../../../helpers';
import VenueSearch from './VenueSearch';

class ExactDays extends Component {
  handleAddHour = day_index => {
    this.props.stateUp({
      days: update(this.props.days, {
        [day_index]: { hours: { $push: [[null, null]] } },
      }),
    });
  };

  handleRemoveHour = (day_index, hour_index) => {
    let new_hours = update(this.props.days[day_index].hours, {
      $splice: [[hour_index, 1]],
    });
    if (!new_hours.length) new_hours = [[null, null]];
    this.props.stateUp({
      days: update(this.props.days, {
        [day_index]: { hours: { $set: new_hours } },
      }),
    });
  };

  handleChangeHourStart = (day_index, hour_index, val) => {
    const hour = formatHourDb(val);
    // eslint-disable-next-line
    let hours = update(this.props.days[day_index].hours, {
      // eslint-disable-next-line
      [hour_index]: { [0]: { $set: hour } },
    });

    // remove empty
    const filtered = hours.filter(item => item[0] !== null); // item[0] = start

    const hours_sorted = filtered.sort((a, b) => {
      const start_a = a[0]; // [0] - start, [1] = stop
      const start_b = b[0];
      const start_a_h = start_a.split(':')[0];
      const start_b_h = start_b.split(':')[0];
      return start_a_h > start_b_h;
    });
    this.props.stateUp({
      days: update(this.props.days, {
        [day_index]: { hours: { $set: hours_sorted } },
      }),
    });
  };

  handleChangeHourStop = (day_index, hour_index, val) => {
    const hour = formatHourDb(val);
    this.props.stateUp({
      // eslint-disable-next-line
      days: update(this.props.days, {
        // eslint-disable-next-line
        [day_index]: { hours: { [hour_index]: { [1]: { $set: hour } } } },
      }),
    });
  };

  handleAddDate = () => {
    const new_date = {
      hours: [[null, null]],
      date: null,
      venue_id: null,
    };

    this.props.stateUp({
      days: update(this.props.days, { $push: [new_date] }),
    });
  };

  handleChangeDate = (day_index, val) => {
    const date = formatDateDb(val);
    const days = update(this.props.days, {
      [day_index]: { date: { $set: date } },
    });
    const days_filtered = days.filter(({ date }) => date);
    const days_sorted = days_filtered.sort((day1, day2) => {
      return new Date(day1.date) > new Date(day2.date);
    });
    this.props.stateUp({
      days: days_sorted,
    });
  };

  handleRemoveDay = day_index => {
    this.props.stateUp({
      days: update(this.props.days, { $splice: [[day_index, 1]] }),
    });
  };

  handelAddVenue = (day_index, venue_id) => {
    this.props.stateUp({
      days: update(this.props.days, {
        [day_index]: { venue_id: { $set: venue_id } },
      }),
    });
  };

  handelRemoveVenue = day_index => {
    this.props.stateUp({
      days: update(this.props.days, {
        [day_index]: { venue_id: { $set: null } },
      }),
    });
  };

  handleUseVenueHour = (day_index, val) => {
    const value = val ? 1 : null;
    this.props.stateUp({
      days: update(this.props.days, {
        [day_index]: {
          hour_from_venue: { $set: value },
          hours: { $set: [[null, null]] },
        },
      }),
    });
  };

  render() {
    const {
      handleAddHour,
      handleRemoveHour,
      handleChangeHourStart,
      handleChangeHourStop,
      handleChangeDate,
    } = this;
    const { days } = this.props;

    const styles = {
      input_date: {
        width: '100px',
        overflow: 'hidden',
      },
      input_hour: {
        width: '80px',
        overflow: 'hidden',
      },
      inline: {
        display: 'inline-block',
      },
      icon_button: {
        padding: 0,
        width: 'auto',
        height: 'auto',
      },
    };

    return (
      <div>
        {days.map((day, day_index) => (
          <RowFlexTop key={day_index}>
            <div
              style={{ marginTop: '49px', fontSize: '20px', color: '#d6d6d6' }}>
              {day_index + 1}
            </div>

            <DatePicker
              floatingLabelText="date"
              autoOk={true}
              style={styles.input_date}
              value={day.date ? new Date(day.date) : null}
              onChange={(something, val) => handleChangeDate(day_index, val)}
            />
            <div>
              {day.hours.map((hour, hour_index) => {
                const start = hour[0];
                const stop = hour[1];
                if (start) {
                }
                const start_hour = start ? start.split(':')[0] : null;
                const start_min = start ? start.split(':')[1] : null;
                const stop_hour = stop ? stop.split(':')[0] : null;
                const stop_min = stop ? stop.split(':')[1] : null;
                const start_obj = start
                  ? new Date(2000, 1, 1, start_hour, start_min)
                  : null;
                const stop_obj = stop
                  ? new Date(2000, 1, 1, stop_hour, stop_min)
                  : null;

                return (
                  <div key={hour_index}>
                    <TimePicker
                      floatingLabelText="hour start"
                      minutesStep={5}
                      format="24hr"
                      style={{
                        ...styles.input_hour,
                        ...styles.inline,
                        marginRight: '15px',
                      }}
                      value={start_obj}
                      onChange={(lalala, val) =>
                        handleChangeHourStart(day_index, hour_index, val)
                      }
                      disabled={day.hour_from_venue ? true : false}
                    />

                    <TimePicker
                      floatingLabelText="hour end"
                      minutesStep={5}
                      format="24hr"
                      style={{ ...styles.input_hour, ...styles.inline }}
                      value={stop_obj}
                      onChange={(lalala, val) =>
                        handleChangeHourStop(day_index, hour_index, val)
                      }
                      disabled={day.hour_from_venue ? true : false}
                    />

                    <IconButton
                      onClick={() => handleAddHour(day_index)}
                      tooltip="add hour"
                      style={{
                        ...styles.icon_button,
                        ...styles.inline,
                        verticalAlign: 'top',
                        marginTop: '32px',
                        marginRight: '15px',
                        visibility:
                          hour_index === day.hours.length - 1 &&
                          start_obj &&
                          !day.hour_from_venue
                            ? 'visible'
                            : 'hidden',
                      }}>
                      <Add />
                    </IconButton>

                    <IconButton
                      onClick={() => handleRemoveHour(day_index, hour_index)}
                      tooltip="remove hour"
                      style={{
                        ...styles.icon_button,
                        ...styles.inline,
                        verticalAlign: 'top',
                        marginTop: '32px',
                        marginRight: '15px',
                        visibility: !day.hour_from_venue ? 'visible' : 'hidden',
                      }}>
                      <Close />
                    </IconButton>
                  </div>
                );
              })}
            </div>

            <div style={{ width: '250px' }}>
              <VenueSearch
                hour_from_venue={day.hour_from_venue ? true : false}
                venue_id={day.venue_id ? parseInt(day.venue_id, 10) : null}
                handleAddVenue={venue_id =>
                  this.handelAddVenue(day_index, venue_id)
                }
                handleRemoveVenue={() => this.handelRemoveVenue(day_index)}
                handleUseVenueHour={val =>
                  this.handleUseVenueHour(day_index, val)
                }
              />
            </div>

            <div className="pd">
              <RaisedButton
                label="Remove"
                labelPosition="before"
                onClick={() => this.handleRemoveDay(day_index)}
                icon={<Close />}
                style={{ marginTop: '33px' }}
              />
            </div>
          </RowFlexTop>
        ))}

        {
          <RaisedButton
            label="Add Date"
            onClick={this.handleAddDate}
            disabled={
              !days.length ? false : days[days.length - 1].date ? false : true
            }
            style={{ marginLeft: '37px' }}
          />
        }
      </div>
    );
  }
}

export default ExactDays;
