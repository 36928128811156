import React, { Component } from 'react';
import { connect } from 'react-redux';
import PcCard from '../shared/PcCard';
import PcSearch from '../shared/PcSearch';
import { Row, CellHalf } from '../shared/Grid';

import { queueUpdate } from '../../actions/actions.js';
import EditableList from '../shared/EditableList';

import { pc_url } from '../../config/config';

class ChapterCardPicksWithState extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.addItem = this.addItem.bind(this);

    this.state = {
      editors_picks: props.editors_picks || [],
    };
  }

  componentWillReceiveProps({ chapter_id, editors_picks }) {
    if (chapter_id === this.props.chapter_id) return;
    this.setState({
      editors_picks: editors_picks || [],
    });
  }

  addItem(data) {
    this.setState(
      {
        editors_picks: this.state.editors_picks.concat([data]),
      },
      () =>
        this.props.modifyItem(this.props.chapter_id, this.state.editors_picks),
    );
  }

  handleChange(items) {
    this.setState(
      {
        editors_picks: items,
      },
      () => this.props.modifyItem(this.props.chapter_id, items),
    );
  }

  render() {
    const { handleChange, addItem } = this;
    const { venue_list, chapter_id, chapter_name } = this.props;
    const { editors_picks } = this.state;

    const props = {
      venue_list,
      editors_picks,
      chapter_id,
      chapter_name,
      handleChange,
      addItem,
    };

    return <ChapterCardPicksView {...props} />;
  }
}

const ChapterCardPicksView = ({
  venue_list,
  editors_picks,
  chapter_id,
  chapter_name,
  handleChange,
  addItem,
}) => {
  const picks = venue_list.filter(item => item.chapter_pick) || [];

  return (
    <PcCard cardTitle="Picks" id="card_picks">
      <Row>
        <CellHalf>
          <h3>editor's picks</h3>
          <PcSearch
            narrow_field_key="chapter_id"
            narrow_field_id={chapter_id}
            table="venue"
            search_id={'chapter' + chapter_id}
            onResultClick={addItem}
            tip={'venues in ' + chapter_name}
          />
          <EditableList
            items={editors_picks}
            is_sortable={true}
            handleChange={handleChange}
          />
        </CellHalf>

        <CellHalf>
          <h3>featured</h3>
          {picks.length > 0 &&
            picks.map((item, i) => (
              <div className="the_link" key={i}>
                <a
                  target="_blank"
                  href={pc_url + 'venue/' + item.symbol + '/' + item.venue_id}
                  className="the_link"
                  rel="noopener noreferrer">
                  {item.name}
                </a>
              </div>
            ))}
          {picks.length === 0 && <span>empty</span>}
        </CellHalf>
      </Row>
    </PcCard>
  );
};

const mapStateToProps = ({ data }) => {
  const { venue_list, chapter } = data;

  return {
    venue_list: venue_list || [],
    chapter_name: chapter.name,
    chapter_id: chapter.chapter_id,
    editors_picks: chapter.editors_picks || [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    modifyItem: (chapter_id, data) => {
      const ids = data.map(el => el.venue_id);
      dispatch(
        queueUpdate('chapter', chapter_id, 'editors_picks_venue_ids', ids),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChapterCardPicksWithState);
