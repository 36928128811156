import React from 'react';

import { connect } from 'react-redux';

import ErrorBoundary from '../ErrorBoundary';

import VenueCardServices from './VenueCardServices';
import VenueCardName from './VenueCardName';
import VenueCardHotel from './VenueCardHotel/VenueCardHotel';
import VenueCardSettings from './VenueCardSettings';
import VenueCardLocation from './VenueCardLocation/VenueCardLocation';
import VenueCardWww from './VenueCardWww';
import VenueCardContact from './VenueCardContact';
import VenueCardBody from './VenueCardBody';
import VenueCardLead from './VenueCardLead';
import VenueCardAppLinks from './VenueCardAppLinks';
import VenueCardOpenGrid from './VenueCardOpenGrid/VenueCardOpenGrid';
import VenueCardConnectedVenues from './VenueCardConnectedVenues';
import VenueCardParents from './VenueCardParents/VenueCardParents';
import VenueCardPrices from './VenueCardPrices';
import BtnNextPrev from './BtnNextPrev';
import VenueAppBar from './VenueAppBar';
import VenueCardSiblings from './VenueCardSiblings';
import VenueInfo from './VenueInfo';
import VenueCardMapExport from './VenueCardMapExport';

import SaveButton from '../shared/TopBar/SaveButton';

import CardTextMulti from '../shared/CardTextMulti';
import BreadCrumbs from '../shared/BreadCrumbs';
import EditedBy from '../shared/EditedBy';
import PcCard from '../shared/PcCard';
import CommentsLink from '../shared/CommentsLink';
import ResearcherComment from '../shared/ResearcherComment';

import CitySelect from '../shared/Drawer/CitySelect';
import ChapterSelect from '../shared/Drawer/ChapterSelect';
import CategorySelect from '../shared/Drawer/CategorySelect';
import CardImages from '../shared/Images/CardImages';
import CardImagesBackground from '../shared/Images/CardImagesBackground';

import VenueList from '../shared/Drawer/VenueList';

import PcDrawer from '../shared/Drawer/PcDrawer';
import BackgroundMobile from '../shared/Images/BackgroundMobile';
import RemoveArchive from '../shared/RemoveArchive';
import CardRedirect from '../shared/CardRedirect.js';

import PcTextField from '../shared/PcTextField.js';
import { modifyWrapper } from '../../core/ModifyWrapper';

import Toc from '../shared/Toc';
import toc from './venue_toc';
import { isSuperAdmin, isEditorPlus } from '../../helpers/hasAccess';
import CardOffer from '../shared/CardOffer';

//const Venue = ({data_type, data_id, name, city_id, chapter_id, category_id, country_id, country_name, redirect_to, lat, venue_id}) => {

const Venue = ({
  venue_id,
  city_id,
  chapter_id,
  category_id,
  country_id,
  archived,
}) => {
  const CustomSaveComponent = () => (
    <span>
      <BtnNextPrev type="prev" />
      <SaveButton />
      <BtnNextPrev type="next" />
    </span>
  );

  // only a few contries has access to siblings
  const countries_with_siblings = [21, 11, 314, 18, 13, 9];

  const display_siblings = countries_with_siblings.includes(country_id);
  const display_offer = isEditorPlus() || isSuperAdmin();
  const display_tours360 = country_id === 21;

  const toc_filtered = toc.filter(
    item =>
      (item[1] !== 'card_siblings' || display_siblings) &&
      (item[1] !== 'card_offer' || display_offer) &&
      (item[1] !== 'card_tour360' || display_tours360),
  );

  // console.log('toc_filtered', toc_filtered);

  const MTextField = modifyWrapper(PcTextField, 'venue');

  return (
    <div>
      <VenueAppBar custom_save_component={CustomSaveComponent} />

      <PcDrawer>
        <CitySelect city_id={city_id} />
        <ChapterSelect chapter_id={chapter_id} />
        <CategorySelect category_id={category_id} />
        {!archived && <VenueList />}
      </PcDrawer>

      <Toc data={toc_filtered} />

      <div className="pd pc-content">
        <div className="top-line">
          <BreadCrumbs />
          <div>
            <EditedBy />
            <CommentsLink />
          </div>
        </div>

        <VenueInfo />

        <VenueCardSettings />

        <VenueCardName />
        <VenueCardLocation />
        <VenueCardWww />
        <VenueCardContact />
        <VenueCardBody data_type="venue" data_id={venue_id} />

        <ResearcherComment type="venue" />

        <VenueCardOpenGrid />

        <CardTextMulti
          card_title="Open Hours Description"
          field="open_text"
          type="venue"
        />

        <ErrorBoundary>
          <VenueCardHotel />
        </ErrorBoundary>

        <CardTextMulti
          card_title="Reservation Description"
          field="reservation_text"
          type="venue"
          id="card_reservation_text"
        />

        <VenueCardPrices />

        <VenueCardLead />

        <CardTextMulti
          card_title="Meta keywords"
          field="meta_tag"
          type="venue"
          id="card_meta_tags"
          tip="Use this field for additional keywords that will help users find the venue through website search"
        />

        <VenueCardConnectedVenues />

        <VenueCardAppLinks />

        <VenueCardServices />

        <VenueCardParents />

        <CardImages data_type="venue" data_id={venue_id} />

        <CardImagesBackground data_type="venue" data_id={venue_id} />

        <PcCard cardTitle="Image App Mobile" id="card_images_app">
          <BackgroundMobile />
        </PcCard>

        {/*
									<InfoNotice message='This page has redirect set and will not display on www' />
									<InfoWarning message='Define map coordinates please' />
					*/}

        {display_siblings && <VenueCardSiblings />}

        {display_offer && (
          <div>
            <CardOffer />
            <CardTextMulti
              card_title="Offer widget"
              field="object"
              type="venue"
            />
          </div>
        )}

        {display_tours360 && (
          <PcCard cardTitle="360° Tour link" id="card_tour360">
            <MTextField name="tour360" floatingLabelText="url" type="url" />
          </PcCard>
        )}

        <VenueCardMapExport />

        <CardRedirect data_type="venue" />

        <RemoveArchive label="Venue" />
      </div>
    </div>
  );
};

//<CardRedirect data_type="venue" data_id={data_id} />

const mapStateToProps = state => {
  const { data } = state;

  const {
    venue_id,
    city_id,
    country_id,
    chapter_id,
    category_id,
    archived,
  } = data.venue;

  return {
    venue_id,
    country_id,
    city_id,
    chapter_id,
    category_id,
    archived,
  };
};

export default connect(mapStateToProps)(Venue);
