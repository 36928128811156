export const preserveCityData = (city_id, data) => {
  return {
    type: 'PRESERVE_CITY_DATA',
    data,
    city_id,
  };
};

export const clearCityDataAll = () => {
  return {
    type: 'CLEAR_CITY_DATA_ALL',
  };
};
