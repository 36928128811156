import React from 'react';
import PcCard from '../shared/PcCard.js';

import PropTypes from 'prop-types';

const propTypes = {
  TextFieldTyped: PropTypes.func.isRequired, // react element wrapped by Modify HOC
};

const CardMetaTags = ({ TextFieldTyped }) => {
  const TextField = TextFieldTyped;

  return (
    <PcCard cardTitle="Meta tags" id="card_meta_tags">
      <TextField
        name="meta_title"
        floatingLabelText="Title"
        hintText="up to 60 characters"
      />
      <TextField
        name="meta_description"
        floatingLabelText="Description"
        hintText="up to 160 characters"
      />
      <TextField
        name="meta_tag"
        floatingLabelText="Keywords"
        hintText="comma seperated"
      />
    </PcCard>
  );
};

CardMetaTags.propTypes = propTypes;

export default CardMetaTags;
