// @flow
import React, { useState } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Chip from 'material-ui/Chip';

import PcCard from '../../../page/shared/PcCard.js';
import countryListIso from '../../../helpers/country_list_iso.json';

type CountriesProps = {
  selected: Array<string>,
  handleChange: (Array<string>) => void,
};

const Countries = ({ selected, handleChange }: CountriesProps) => {
  const handleSelectChange = (event, index, values) => {
    handleChange(values);
  };

  const onChipRemoveClick = iso_code_to_be_removed => {
    const filtered = selected.filter(
      iso_code => iso_code !== iso_code_to_be_removed,
    );
    handleChange(filtered);
  };

  const chip_data = countryListIso
    .filter(iso => selected.includes(iso[1]))
    .sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <PcCard
      cardTitle="Target Countries"
      description="Display widget to users who are staying in selected countries:">
      <SelectField
        multiple={true}
        hintText="Select Country"
        value={selected}
        onChange={handleSelectChange}>
        {countryListIso.map(c => (
          <MenuItem
            key={c[1]}
            insetChildren={true}
            checked={selected && selected.indexOf(c[1]) > -1}
            value={c[1]}
            primaryText={c[0]}
          />
        ))}
      </SelectField>

      <div style={{ display: 'flex' }}>
        {chip_data.map(l => {
          return (
            <Chip
              key={l[1]}
              style={{ margin: '2px' }}
              onRequestDelete={() => onChipRemoveClick(l[1])}>
              {l[0]}
            </Chip>
          );
        })}
      </div>
    </PcCard>
  );
};

const CountriesWithState = ({ selected, handleChange }: CountriesProps) => {
  const [selectedState, setSelected] = useState(selected);
  const _handleSelectChange = selectedState => {
    handleChange(selectedState);
    setSelected(selectedState);
  };
  return (
    <Countries selected={selectedState} handleChange={_handleSelectChange} />
  );
};

export { CountriesWithState };

export default Countries;
