// @flow

import React, { useState, useEffect } from 'react';
import PcCard from '../shared/PcCard.js';
import { connect } from 'react-redux';
import { queueUpdate } from '../../actions/actions.js';
import { fetchAfterSave } from '../../actions/pageControl.js';
import TextField from 'material-ui/TextField';
import { VenueCardMapExportImage } from './VenueCardMapExportImage.js';
import { static_url } from '../../config/config.js';

type MiniguideKeywordViewProps = {
  value: string | null,
  label: string,
  char_limit: number,
  handleChange: (value: string) => void,
};

const getImgPath = (venueId: number) =>
  static_url + 'img/miniguide_photo/venue/' + venueId + '.jpg';

const MiniguideKeywordView = ({
  value,
  label,
  char_limit,
  handleChange,
}: MiniguideKeywordViewProps) => {
  const counter_left = char_limit - (value || '').length;

  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <TextField
            name="any"
            value={value || ''}
            multiLine={true}
            fullWidth={true}
            onChange={(ev, value) => {
              handleChange(value.substr(0, char_limit));
            }}
            floatingLabelText={label}
          />
        </div>
      </div>
      <div>Chars left: {counter_left}</div>
    </>
  );
};

type MapExportProps = {
  miniguide_keyword_1: string | null,
  miniguide_keyword_2: string | null,
  miniguide_has_photo: string | null,
  venue_id: number,
  show_local: boolean,
  dispatch: any => void,
};

const MapExport = ({
  miniguide_keyword_1,
  miniguide_keyword_2,
  miniguide_has_photo,
  venue_id,
  show_local,
  dispatch,
}: MapExportProps) => {
  const [miniguideKeyword1, setMiniguideKeyword1] = useState(
    miniguide_keyword_1,
  );
  const [miniguideKeyword2, setMiniguideKeyword2] = useState(
    miniguide_keyword_2,
  );

  const [removedClicked, setRemovedClicked] = useState(false);

  useEffect(() => {
    setRemovedClicked(false);
  }, [miniguide_has_photo]);

  useEffect(() => {
    setMiniguideKeyword1(miniguide_keyword_1);
    setMiniguideKeyword2(miniguide_keyword_2);
    setRemovedClicked(false);
  }, [venue_id]);

  const handleMiniguideKeyword1 = (value: string) => {
    setMiniguideKeyword1(value);
    dispatch(queueUpdate('venue', venue_id, 'miniguide_keyword_1', value));
  };

  const handleMiniguideKeyword2 = (value: string) => {
    setMiniguideKeyword2(value);
    dispatch(queueUpdate('venue', venue_id, 'miniguide_keyword_2', value));
  };

  const img_url = miniguide_has_photo ? getImgPath(venue_id) : null;

  return (
    <PcCard cardTitle="Map export" id="map_export">
      <MiniguideKeywordView
        value={miniguideKeyword1}
        handleChange={handleMiniguideKeyword1}
        char_limit={140}
        label="Miniguide keyword 1"
      />
      <MiniguideKeywordView
        value={miniguideKeyword2}
        handleChange={handleMiniguideKeyword2}
        char_limit={140}
        label="Miniguide keyword 2"
      />
      <div className="pd-h">
        <VenueCardMapExportImage
          img_url={img_url}
          removedClicked={removedClicked}
          onRemove={() => {
            setRemovedClicked(true);
            dispatch(
              queueUpdate('venue', venue_id, 'miniguide_has_photo', null),
            );
            dispatch(fetchAfterSave(true));
          }}
        />
      </div>
    </PcCard>
  );
};

const mapStateToProps = ({ data }) => {
  const {
    miniguide_keyword_1,
    miniguide_keyword_2,
    miniguide_has_photo,
    venue_id,
  } = data.venue;

  return {
    miniguide_keyword_1,
    miniguide_keyword_2,
    miniguide_has_photo,
    venue_id,
  };
};

export default connect(mapStateToProps)(MapExport);
