import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import PcCard from '../shared/PcCard';
import PcAppBar from '../shared/TopBar/PcAppBar';
import { RowFlex } from '../shared/Grid';
import BreadCrumbs from '../shared/BreadCrumbs';
import PcTextField from '../shared/PcTextField';
import { queueUpdate } from '../../actions/actions.js';
import PropTypes from 'prop-types';

const propTypes = {
  // as in 'material-ui/TextField' +
  comments: PropTypes.array,
  name: PropTypes.string,
};

const CommentList = ({ comments }) => (
  <div>
    {comments.map(comment => (
      <CommentItem comment={comment} key={comment.comments_id} />
    ))}
  </div>
);

class CommentItemConatiner extends Component {
  constructor(props) {
    super(props);

    const c = props.comment;
    let status = null;
    if (c.active === 1) status = 'accepted';
    else if (c.checked === 1) status = 'checked';
    this.state = { status };
  }

  handleChange = (field, value) =>
    this.props.dispatch(
      queueUpdate('comments', this.props.comment.comments_id, field, value),
    );

  handleChangeStatus = value => {
    this.setState({ status: value });

    switch (value) {
      case 'accepted':
        this.handleChange('active', 1);
        this.handleChange('checked', 1);
        break;

      case 'remove':
        this.handleChange('active', null);
        this.handleChange('checked', 2);
        break;

      case 'checked':
        this.handleChange('active', null);
        this.handleChange('checked', 1);
        break;

      default:
    }
  };

  render() {
    const { comment } = this.props;
    const { reader } = comment;
    const { handleChange } = this;
    const comment_formated = comment.user_comment
      ? comment.user_comment.replace(/\n\n/g, '\n')
      : '';

    console.log('reader', reader);
    return (
      <PcCard cardTitle={comment.user_name}>
        <div className="pd" />

        <RowFlex>
          <span>{comment.created} </span>
          {comment.user_email && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'mailto:' + comment.user_email}>
              {comment.user_email}
            </a>
          )}

          {comment.ip && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={'http://whatismyipaddress.com/ip/' + comment.ip}>
              {comment.ip}
            </a>
          )}
          {reader && reader.link_socials && (
            <a
              href={reader.link_socials}
              target="_blank"
              rel="noopener noreferrer">
              {reader.link_socials}{' '}
            </a>
          )}
        </RowFlex>

        <RowFlex>
          <PcTextField
            value={reader ? reader.display_name : comment.user_name}
            multiLine={true}
            handleChange={val => handleChange('user_name', val)}
            fullWidth={false}
            floatingLabelText="name"
            disabled={reader ? true : false}
          />

          <PcTextField
            value={reader ? reader.location : comment.user_location}
            multiLine={true}
            handleChange={val => handleChange('user_location', val)}
            fullWidth={false}
            floatingLabelText="from"
            disabled={reader ? true : false}
          />

          <SelectField
            floatingLabelText="Action"
            value={this.state.status}
            onChange={(obj, key, val) => this.handleChangeStatus(val)}>
            <MenuItem value="accepted" primaryText="Accepted" />
            <MenuItem value="remove" primaryText="Remove" />
            <MenuItem value="checked" primaryText="Checked (inactive)" />
          </SelectField>
        </RowFlex>

        <div className="pd">
          <PcTextField
            value={comment_formated}
            multiLine={true}
            handleChange={val => handleChange('user_comment', val)}
            floatingLabelText="comment"
          />
        </div>
        <div className="pd" />
      </PcCard>
    );
  }
}

const CommentItem = connect()(CommentItemConatiner); // to get dispatch fn

const Comments = ({ name, comments }) => (
  <div>
    <PcAppBar title={name} no_drawer={true} />
    <div className="pd pc-content-wide">
      <div className="top-line">
        <BreadCrumbs />
      </div>
      <div className="pd">
        {comments.length > 0 && <CommentList comments={comments} />}
        {comments.length === 0 && <div className="pd">No comments yet</div>}
      </div>
    </div>
  </div>
);

Comments.propTypes = propTypes;

export default Comments;
