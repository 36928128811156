import React from 'react';
import { Row } from '../../shared/Grid';
import FlatButton from 'material-ui/FlatButton';
import InputCell from './InputCell';
import Currency from './Currency';
import Stars from './Stars';

export const NumberCell = ({ name, value, label, handleChange }) => (
  <div className="col-xs-3 pd-w">
    <InputCell
      name={name}
      label={label}
      value={value}
      type="number"
      handleChange={handleChange}
      min={0}
    />
  </div>
);

export const TextCell = ({ name, value, label, handleChange }) => (
  <div className="col-xs-3 pd-w">
    <InputCell
      name={name}
      label={label}
      value={value}
      handleChange={handleChange}
    />
  </div>
);

export const RoomLabel = ({ name }) => (
  <div className="col-xs-3 pd-w">
    <div className="in-cell-label">{name}:</div>
  </div>
);

export const RoomsView = p => (
  <div>
    {(p.show_all || p.total_nr || p.stars || p.currency_id || p.stars) && (
      <Row>
        {(p.show_all || p.total_nr) && (
          <div className="col-md-2 pd-w">
            <InputCell
              name="total_nr"
              label="total number"
              value={p.total_nr}
              type="number"
              min="0"
              handleChange={p.handleChange}
              fullWidth={false}
            />
          </div>
        )}

        {(p.show_all || p.currency_id) && (
          <div className="col-md-4 pd-w">
            <Currency />
          </div>
        )}

        {(p.show_all || p.currency_id) && (
          <div className="col-md-4 pd-w">
            <Stars />
          </div>
        )}
      </Row>
    )}
    <div id="hotel-columns">
      {(p.show_all || p.single_nr || p.single_min || p.single_max) && (
        <Row>
          <RoomLabel name="Single" />
          <NumberCell
            name="single_nr"
            value={p.single_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="single_min"
            value={p.single_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="single_max"
            value={p.single_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.double_nr || p.double_min || p.double_max) && (
        <Row>
          <RoomLabel name="Double" />
          <NumberCell
            name="double_nr"
            value={p.double_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="double_min"
            value={p.double_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="double_max"
            value={p.double_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.triple_nr || p.triple_min || p.triple_max) && (
        <Row>
          <RoomLabel name="Triple" />
          <NumberCell
            name="triple_nr"
            value={p.triple_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="triple_min"
            value={p.triple_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="triple_max"
            value={p.triple_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.quad_nr || p.quad_min || p.quad_max) && (
        <Row>
          <RoomLabel name="Quad" />
          <NumberCell
            name="quad_nr"
            value={p.quad_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="quad_min"
            value={p.quad_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="quad_max"
            value={p.quad_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.suite_nr || p.suite_min || p.suite_max) && (
        <Row>
          <RoomLabel name="Suite" />
          <NumberCell
            name="suite_nr"
            value={p.suite_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="suite_min"
            value={p.suite_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="suite_max"
            value={p.suite_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.apartment_nr || p.apartment_min || p.apartment_max) && (
        <Row>
          <RoomLabel name="Apartment" />
          <NumberCell
            name="apartment_nr"
            value={p.apartment_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="apartment_min"
            value={p.apartment_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="apartment_max"
            value={p.apartment_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.special1_nr || p.special1_min || p.special1_max) && (
        <Row>
          <TextCell
            name="special1_title"
            value={p.special1_title}
            label="Special 1"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special1_nr"
            value={p.special1_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special1_min"
            value={p.special1_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special1_max"
            value={p.special1_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.special2_nr || p.special2_min || p.special2_max) && (
        <Row>
          <TextCell
            name="special2_title"
            value={p.special2_title}
            label="Special 2"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special2_nr"
            value={p.special2_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special2_min"
            value={p.special2_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special2_max"
            value={p.special2_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.special3_nr || p.special3_min || p.special3_max) && (
        <Row>
          <TextCell
            name="special3_title"
            value={p.special3_title}
            label="Special 3"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special3_nr"
            value={p.special3_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special3_min"
            value={p.special3_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special3_max"
            value={p.special3_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.special4_nr || p.special4_min || p.special4_max) && (
        <Row>
          <TextCell
            name="special4_title"
            value={p.special4_title}
            label="Special 4"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special4_nr"
            value={p.special4_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special4_min"
            value={p.special4_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special4_max"
            value={p.special4_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}

      {(p.show_all || p.special5_nr || p.special5_min || p.special5_max) && (
        <Row>
          <TextCell
            name="special5_title"
            value={p.special5_title}
            label="Special 5"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special5_nr"
            value={p.special5_nr}
            label="Quanity"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special5_min"
            value={p.special5_min}
            label="Min"
            handleChange={p.handleChange}
          />
          <NumberCell
            name="special5_max"
            value={p.special5_max}
            label="Max"
            handleChange={p.handleChange}
          />
        </Row>
      )}
    </div>{' '}
    {/* #hotel-columns */}
    {!p.show_all && <BtnShowAll handleShowAll={p.handleShowAll} />}
  </div>
);

export const BtnShowAll = ({ handleShowAll }) => (
  <Row>
    <div className="col-xs-12 pd-w text-center">
      <FlatButton label="Show all hotel details" onClick={handleShowAll} />
    </div>
  </Row>
);
