import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PcAppBar from '../shared/TopBar/PcAppBar';
import BreadCrumbs from '../shared/BreadCrumbs';
import PcSearch from '../shared/PcSearch';
import FlatButton from 'material-ui/FlatButton';
import { queueUpdate } from '../../actions/actions';
import PcTextField from '../shared/PcTextField';
import PcCard from '../shared/PcCard';
import { CellFree, RowFlexTop } from '../shared/Grid';
import EditableList from '../shared/EditableList';
import Checkbox from 'material-ui/Checkbox';
import { colors_warning } from 'page/shared/Info';

import { isSuperAdmin, isEditorPlus, isEditor } from '../../helpers/hasAccess';

class Section extends React.Component {
  _handleLinkChange = link =>
    this.props.handleSectionChange({ ...this.props.data, link });

  _handleNameChange = name =>
    this.props.handleSectionChange({ ...this.props.data, name });

  _handleOrderingChange = ordering =>
    this.props.handleSectionChange({ ...this.props.data, ordering });

  _handleItemsChange = items =>
    this.props.handleSectionChange({ ...this.props.data, items });

  _removeSection = () => this.props.handleRemoveSection();

  _handleTypeChange = event =>
    this.props.handleSectionChange({
      ...this.props.data,
      type: event.target.checked ? 'random' : 'fixed',
    });

  _addItem = data => {
    const items = [...(this.props.data.items || []), data];
    this._handleItemsChange(items);
  };

  render() {
    const {
      _handleLinkChange,
      _handleNameChange,
      _handleOrderingChange,
      _handleItemsChange,
      _handleTypeChange,
      _removeSection,
    } = this;
    const { city_id, city_name, section_index } = this.props;
    const { name, link, ordering, items, type } = this.props.data;

    if (!isSuperAdmin() && isEditorPlus() && isEditor()) {
      return <span>no access</span>;
    }

    return (
      <RowFlexTop>
        <div>
          <h1 style={{ marginTop: '40px' }}>{section_index + 1}</h1>
        </div>
        <div>
          <div>
            <CellFree className="pd-w">
              <PcTextField
                value={name}
                floatingLabelText="section name"
                style={{ width: '200px' }}
                handleChange={_handleNameChange}
              />
            </CellFree>
            <CellFree className="pd-w">
              www.inyourpocket.com/{this.props.city_symbol}/
              <PcTextField
                value={link}
                floatingLabelText="more link"
                style={{ width: '200px' }}
                handleChange={_handleLinkChange}
              />
            </CellFree>
            <CellFree className="pd-w">
              <PcTextField
                style={{ width: '70px' }}
                value={ordering}
                floatingLabelText="ordering"
                fullWidth={false}
                handleChange={_handleOrderingChange}
              />
            </CellFree>
            <CellFree className="pd-w">
              <Checkbox
                style={{ marginTop: '35px' }}
                label="random"
                checked={type === 'random'}
                onCheck={_handleTypeChange}
              />
            </CellFree>
          </div>

          <div className="pd-w">
            {items && items.length > 4 && type !== 'random' && (
              <div>
                <span
                  style={{
                    ...colors_warning,
                    padding: '10px',
                  }}>
                  Only first four elements will display. Mark 'random' to
                  display all items in randomized way.
                </span>
              </div>
            )}
          </div>

          <div className="pd-w">
            <RowFlexTop>
              <div style={{ maxWidth: '300px' }}>
                <PcSearch
                  narrow_field_key="city_id"
                  narrow_field_id={city_id}
                  table="venue"
                  onResultClick={this._addItem}
                  tip={'venues in ' + city_name}
                  search_id={'section_venues_' + section_index}
                />
                <PcSearch
                  narrow_field_key="city_id"
                  narrow_field_id={city_id}
                  table="event"
                  onResultClick={this._addItem}
                  tip={'events in ' + city_name}
                  search_id={'section_events_' + section_index}
                />
                <PcSearch
                  narrow_field_key="city_id"
                  narrow_field_id={city_id}
                  table="feature"
                  onResultClick={this._addItem}
                  tip={'features in ' + city_name}
                  search_id={'section_features_' + section_index}
                />
              </div>
              <div style={{ width: '400px' }}>
                {items && (
                  <EditableList
                    items={items || []}
                    is_sortable={true}
                    handleChange={_handleItemsChange}
                  />
                )}
                {!items && <div className="pd"> no veneus yet </div>}
              </div>
              <div>
                <FlatButton
                  style={{ marginTop: '30px' }}
                  label="remove section"
                  onClick={_removeSection}
                />
              </div>
            </RowFlexTop>
          </div>
        </div>
      </RowFlexTop>
    );
  }
}

class NewSection extends React.Component {
  initial_state = {
    name: '',
    link: '',
    type: 'fixed', // fixed || random allowed
  };

  constructor(props) {
    super(props);
    this.state = this.initial_state;
  }

  _addSection = () => {
    this.props.addSection(this.state);
    this.setState(this.initial_state);
  };

  _handleNameChange = name => this.setState({ name });

  _handleLinkChange = link => this.setState({ link });

  render() {
    const { name, link } = this.state;

    const { _addSection, _handleNameChange, _handleLinkChange } = this;

    return (
      <PcCard cardTitle="Add section">
        <CellFree className="pd-w">
          <PcTextField
            value={name}
            floatingLabelText="section name"
            style={{ width: '200px' }}
            handleChange={_handleNameChange}
          />
        </CellFree>
        <CellFree className="pd-w">
          www.inyourpocket.com/{this.props.symbol}/
          <PcTextField
            value={link}
            floatingLabelText="more link"
            style={{ width: '200px' }}
            handleChange={_handleLinkChange}
          />
        </CellFree>
        <CellFree className="pd-w">
          <FlatButton
            style={{ marginTop: '30px' }}
            label="add section"
            disabled={!name || !link}
            onClick={_addSection}
          />
        </CellFree>
      </PcCard>
    );
  }
}

class Features extends React.Component {
  static propTypes = {
    features: PropTypes.array,
    featuresChange: PropTypes.func.isRequired,
    country_id: PropTypes.number.isRequired,
    country_name: PropTypes.string.isRequired,
  };

  _handleFeaturesChange = items => this.props.featuresChange(items);

  _handleFeatureAdd = data => {
    const items = [...(this.props.features || []), data];
    this._handleFeaturesChange(items);
  };

  render() {
    const { country_id, country_name, features } = this.props;
    const { _handleFeatureAdd, _handleFeaturesChange } = this;

    return (
      <PcCard>
        <div className="pd-w">
          <RowFlexTop>
            <div style={{ maxWidth: '300px' }}>
              <PcSearch
                narrow_field_key="country_id"
                narrow_field_id={country_id}
                table="feature"
                onResultClick={_handleFeatureAdd}
                tip={'features in ' + country_name}
                search_id={'features' + country_id}
              />
            </div>
            <div style={{ width: '400px' }}>
              {features && (
                <EditableList
                  items={features || []}
                  is_sortable={true}
                  handleChange={_handleFeaturesChange}
                />
              )}
              {!features && <div className="pd"> no features yet </div>}
            </div>
          </RowFlexTop>
        </div>
      </PcCard>
    );
  }
}

class CityEntrySwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city_entry_enabled: this.props.city_entry_enabled,
    };
  }

  toggle = () => {
    this.setState(
      {
        city_entry_enabled: this.state.city_entry_enabled ? null : 1,
      },
      () =>
        this.props.dispatch(
          queueUpdate(
            'city',
            this.props.city_id,
            'city_entry_enabled',
            this.state.city_entry_enabled,
          ),
        ),
    );
  };

  render() {
    return (
      <input
        style={{ fontSize: '2em' }}
        type="checkbox"
        checked={this.state.city_entry_enabled ? true : false}
        onChange={this.toggle}
      />
    );
  }
}

class CityHome extends React.Component {
  initial_state = {
    large: {},
    sections: [],
  };

  constructor(props) {
    super(props);
    this.state = props.custom_home || this.initial_state;
  }

  _addItemLarge = data => {
    this.setState({ large: data }, () => this.save());
  };

  _removeItemLarge = () => {
    this.setState({ large: {} }, () => this.save());
  };

  _addSection = ({ name, link }) => {
    this.setState(
      { sections: [...(this.state.sections || []), { name, link }] },
      () => this.save(),
    );
  };

  _handleSectionChange = (index, data) => {
    let sections = [...this.state.sections];
    sections[index] = data;
    this.setState({ sections }, () => this.save());
  };

  _featuresChange = features => {
    this.setState({ features }, () => this.save());
  };

  _handleRemoveSection = index => {
    if (!window.confirm('Are you sure?')) return;
    let sections = [...this.state.sections];
    sections.splice(index, 1);
    this.setState({ sections }, () => this.save());
  };

  _sortSections = sections => {
    let sections_with_ordering = sections.filter(el => el.ordering) || [];
    const sections_without_ordering = sections.filter(el => !el.ordering) || [];

    sections_with_ordering = sections_with_ordering.sort((a, b) => {
      if (a === b) {
        return 0;
      } else {
        return a.ordering < b.ordering ? -1 : 1;
      }
    });

    return [...sections_with_ordering, ...sections_without_ordering];
  };

  save() {
    const { sections, features } = this.state;
    const sections_sorted = this._sortSections(sections);

    this.props.dispatch(
      queueUpdate('city', this.props.city_id, 'custom_home', {
        sections: sections_sorted || [],
        features: features || [],
      }),
    );
  }

  render() {
    const {
      name,
      city_id,
      symbol,
      country_id,
      country_name,
      city_entry_enabled,
    } = this.props;
    const { sections, features } = this.state;
    const link = 'https://test.inyourpocket.com/new-' + symbol;
    return (
      <div>
        <PcAppBar no_drawer={true} />
        <div className="pd pc-content-wide" style={{ paddingBottom: '200px' }}>
          <div className="top-line">
            <BreadCrumbs />
          </div>
          <div style={{ margin: '30px 0', fontSize: '20px' }}>
            preview:{' '}
            <a
              href={link}
              className="the_link"
              target="_blank"
              rel="noopener noreferrer">
              {link}
            </a>
            <div style={{ float: 'right' }}>
              <label>
                <CityEntrySwitcher
                  city_id={city_id}
                  city_entry_enabled={city_entry_enabled}
                  dispatch={this.props.dispatch}
                />
                <span style={{ fontSize: '15px' }}>
                  Make city entry live on www
                </span>
              </label>
            </div>
          </div>

          <h1>Features</h1>
          <Features
            features={features || []}
            featuresChange={this._featuresChange}
            country_id={country_id}
            country_name={country_name}
          />
          {/*
					<h1>Large box</h1>
					{!large.name &&
						<div style={{maxWidth:'400px'}} className='pd'>
							<PcSearch  narrow_field_key='city_id' narrow_field_id={city_id} table='venue' onResultClick={this._addItemLarge} tip={"venues in "+name} search_id={'venue_large'+ city_id}/>
						</div>
					}
					{large.name &&
						<div>
							<a href={large.url} target="_blank" className='the_link'>{large.name}</a>
							 <FlatButton label="Remove" secondary={true} onClick={this._removeItemLarge}/>
						</div>
					}
					*/}

          <h1>Custom sections</h1>

          <NewSection addSection={this._addSection} symbol={symbol} />

          <PcCard cardTitle="Sections">
            {sections &&
              sections.map((section, index) => (
                <Section
                  key={index}
                  data={section}
                  handleSectionChange={data =>
                    this._handleSectionChange(index, data)
                  }
                  handleRemoveSection={() => this._handleRemoveSection(index)}
                  city_id={city_id}
                  city_name={name}
                  city_symbol={symbol}
                  section_index={index}
                />
              ))}
            {(!sections || !sections.length) && (
              <span>No custom sections yet</span>
            )}
          </PcCard>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { name, city_id, custom_home, symbol, country_id, city_entry_enabled } =
    data.city;

  return {
    name,
    symbol,
    city_entry_enabled,
    city_id,
    country_id,
    custom_home,
    country_name: data.country.name,
  };
};

export default connect(mapStateToProps)(CityHome);
