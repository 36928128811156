import { createSelector } from 'reselect';

const getChapterList = data => data.chapter_list || [];
const getCategoryList = data => data.category_list || [];

const getVenueParentStructure = createSelector(
  [getChapterList, getCategoryList],
  (chapter_list, category_list) => {
    const l_ch = chapter_list.length;
    const l_ca = category_list.length;
    let items = [];

    for (let i = 0; i < l_ch; i++) {
      const chapter = chapter_list[i];
      items.push(chapter);
      for (let j = 0; j < l_ca; j++) {
        const category = category_list[j];
        if (category.chapter_id !== chapter.chapter_id) continue;
        items.push(category);
      }
    }
    //console.log('items', items)
    return items;
  },
);

export default getVenueParentStructure;
