import React from 'react';
import { connect } from 'react-redux';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { Link } from 'react-router-dom';

const ChapterSelectView = ({ chapter_id, chapter_list, city }) => {
  return (
    <SelectField
      value={chapter_id}
      floatingLabelText="Chapter"
      autoWidth={true}
      style={{ maxWidth: '100%' }}>
      <MenuItem
        value={-1}
        primaryText="Events"
        containerElement={
          <Link to={'/events_chapter/' + city.symbol + '/' + city.city_id} />
        }
      />

      {chapter_list.map((chapter, i) => {
        const style = chapter.active ? {} : { color: 'grey' };
        const link = '/chapter/' + chapter.symbol + '/' + chapter.chapter_id;

        return (
          <MenuItem
            value={chapter.chapter_id}
            key={i}
            primaryText={chapter.name}
            style={style}
            containerElement={<Link to={link} />}
          />
        );
      })}
    </SelectField>
  );
};

const mapStateToProps = ({ data }, ownProps) => {
  const chapter_id = ['events_chapter', 'event_type', 'event'].includes(
    data.page_type,
  )
    ? -1
    : ownProps.chapter_id;

  return {
    chapter_list: data.chapter_list || [],
    chapter_id,
    city: data.city,
  };
};

export default connect(mapStateToProps)(ChapterSelectView);
