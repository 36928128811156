import React, { Component } from 'react';
import { List, ListItem } from 'material-ui/List';
import Close from 'material-ui/svg-icons/navigation/close';
import ActionPanTool from 'material-ui/svg-icons/action/pan-tool';
import IconButton from 'material-ui/IconButton';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { pc_url } from '../../config/config';

const propTypes = {
  items: PropTypes.array.isRequired,
  is_sortable: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const ItemView = ({
  name,
  url,
  subtitle = null,
  the_index,
  is_sortable = false,
  removeItem,
}) => (
  <ListItem
    disableTouchRipple={true}
    primaryText={
      <a
        className="the_link"
        href={pc_url + url}
        rel="noopener noreferrer"
        target="_blank">
        {name}
      </a>
    }
    secondaryText={subtitle}
    rightIconButton={
      <IconButton style={{ width: '90px' }} disableTouchRipple={true}>
        {!is_sortable ? null : <DragHandle />}
        <span className="editable_list_sep" />

        <Close onClick={removeItem} />
      </IconButton>
    }
  />
);

const ItemSortable = SortableElement(props => <ItemView {...props} />);

const DragHandle = SortableHandle(() => <ActionPanTool />);

const TheItem = props => {
  if (props.is_sortable) return <ItemSortable {...props} />;
  return <ItemView {...props} />;
};

const ListView = ({ items, is_sortable, removeItem }) => (
  <List>
    {items.map((item, index) => (
      <TheItem
        {...item}
        is_sortable={is_sortable}
        key={index}
        index={index}
        removeItem={() => removeItem(index)}
      />
    ))}
  </List>
);

const ListSortable = SortableContainer(props => <ListView {...props} />);

const TheList = props => {
  if (props.is_sortable)
    return <ListSortable {...props} useDragHandle={true} />;
  return <ListView {...props} />;
};

class EditableList extends Component {
  constructor(props) {
    super(props);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.props.handleChange(arrayMove(this.props.items, oldIndex, newIndex));
  }

  removeItem(key) {
    this.props.handleChange(
      update([...this.props.items], { $splice: [[key, 1]] }),
    );
  }

  render() {
    const { is_sortable, items } = this.props;
    const { onSortEnd, removeItem } = this;

    const sortable_flag = is_sortable && items.length > 1 ? true : false;

    return (
      <TheList
        is_sortable={sortable_flag}
        items={items}
        onSortEnd={onSortEnd}
        removeItem={removeItem}
      />
    );
  }
}

EditableList.propTypes = propTypes;

export default EditableList;
