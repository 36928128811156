import React, { Component } from 'react';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import PropTypes from 'prop-types';
import { indicatorOn, indicatorOff } from '../../../actions/actions';
import exif from 'exif-js';

const MAX_FILESIZES = 52428800;
const MAX_FILESIZES_HUMAN = '50MB';

const prop_types = {
  name: PropTypes.string.isRequired,
  data_key: PropTypes.string.isRequired,
  label: PropTypes.string,
  min_width: PropTypes.number,
  min_height: PropTypes.number,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  orientation: PropTypes.oneOf(['portrait', 'landscape']),
  accept: PropTypes.string,
  preview_size: PropTypes.number,
  info: PropTypes.string,
  secondary: PropTypes.bool, // if true button will be red
};

// https://stackoverflow.com/questions/20600800/js-client-side-exif-orientation-rotate-and-mirror-jpeg-images
const getImageOrientation = image => {
  return new Promise(function (resolve, reject) {
    exif.getData(image, function () {
      const orientation = exif.getTag(this, 'Orientation');
      resolve(orientation);
    });
  });
};

class FileImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      files_data: [],
      error: '',
      loading: false,
    };
    this.filesize_all = 0;

    this.input_label = this.props.label
      ? this.props.label
      : this.props.multiple
      ? 'add image(s)'
      : 'add image';
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data_key === nextProps.data_key) return;
    this.refs.form.reset();
    this.setState({
      files: [],
      files_data: [],
      error: '',
      loading: false,
    });
    this.filesize_all = 0;
  }

  executeError = message => {
    this.indicatorOff();
    if (this.state.files_data.length > 3) {
      alert(message);
    }
    this.setState({
      error: message,
      files_data: [],
    });
    this.refs.form.reset();
    this.filesize_all = 0;
  };

  validateImage(image) {
    const { min_width, min_height, orientation } = this.props;

    let error = '';
    if (!image.width || !image.height) {
      return { error: 'This file is not a valid image' };
    }

    let { width, height } = image;

    // http://sylvana.net/jpegcrop/exif_orientation.html
    if ([5, 6, 7, 8].indexOf(image.exif_orientation) !== -1) {
      width = image.height;
      height = image.width;
    }

    if (orientation === 'portrait' && width > height)
      error = 'Image must be portrait orientation';
    if (orientation === 'landscape' && height > width)
      error = 'Image must be landscape orientation';
    if (min_width && width < min_width)
      error = 'Image must be at least ' + min_width + 'px wide';
    if (min_height && height < min_height)
      error = 'Image must be at least ' + min_height + 'px tall';

    if (error) {
      return { error };
    }

    return { success: true };
  }

  processImage(file, i, event) {
    this.filesize_all += file.size;

    if (this.filesize_all > MAX_FILESIZES) {
      return this.executeError(
        'Maximum image(s) batch filesize ' + MAX_FILESIZES_HUMAN + ' exceeded',
      );
    }

    let reader = new FileReader();

    reader.onloadend = () => {
      if (this.state.error) return;

      let image = new Image();
      image.src = reader.result;

      image.onload = () => {
        getImageOrientation(image).then(orientation => {
          image.exif_orientation = orientation;

          if (this.state.error) return;

          const valid_test = this.validateImage(image);
          if (valid_test.error) {
            const message =
              'Error: ' + valid_test.error + ' (' + file.name + ')';

            this.executeError(message);
          } else {
            this.setState({
              files: [...this.state.files, file],
              files_data: [...this.state.files_data, reader.result],
            });
          }

          if (i + 1 === this.files_length) return this.indicatorOff();

          const current_index = i + 1;

          this.processImage(
            event.target.files[current_index],
            current_index,
            event,
          );
        });
      };

      image.onerror = () => {
        const message = 'Error: Image file is currupted (' + file.name + ')';
        this.executeError(message);
      };
    };
    reader.readAsDataURL(file);
  }

  indicatorOn() {
    this.props.indicatorOn();
    this.setState({ loading: true });
  }

  indicatorOff() {
    this.props.indicatorOff();
    this.setState({ loading: false });
  }

  handleImageChange(event) {
    event.preventDefault();

    event.persist();

    this.setState(
      {
        files: [],
        files_data: [],
        error: '',
        loading: false,
      },
      () => {
        this.files_length = event.target.files.length;

        if (!this.files_length) return;

        this.indicatorOn();

        this.processImage(event.target.files[0], 0, event);
      },
    );
  }

  render() {
    const accept = this.props.accept || 'image/*';
    const preview_size = this.props.preview_size || 200;
    const style_preview = {
      width: preview_size + 'px',
      height: preview_size + 'px',
      backgroundSize: 'cover',
      display: 'inline-block',
      marginRight: '2px',
      marginBottom: '2px',
      backgroundPosition: 'center',
    };

    const files_data = this.state.files_data;

    return (
      <form ref="form">
        <RaisedButton
          label={this.input_label}
          containerElement="label"
          disabled={this.props.disabled}
          secondary={this.props.secondary}>
          <input
            style={{ display: 'none' }}
            multiple={this.props.multiple}
            type="file"
            accept={accept}
            name={this.props.name}
            onChange={e => this.handleImageChange(e)}
            disabled={this.props.disabled}
          />
        </RaisedButton>
        {this.props.info && <div className="pd-h"> {this.props.info} </div>}
        {this.state.loading && (
          <span style={{ padding: '5px' }}>
            analyzing file{this.files_length > 1 ? 's' : ''}
          </span>
        )}
        <div style={{ marginTop: '10px' }}>
          {files_data.length === 1 && (
            <small>
              Following file will be uploaded once you click SAVE button:
              <br />
            </small>
          )}
          {files_data.length > 1 && (
            <small>
              Following files will be uploaded once you click SAVE button:
              <br />
            </small>
          )}
          {files_data.map((val, i) => {
            return (
              <div
                style={{
                  ...style_preview,
                  backgroundImage: 'url(' + val + ')',
                }}
                key={i}
              />
            );
          })}
        </div>
        {this.state.error && (
          <div style={{ color: 'red', padding: '5px' }}>{this.state.error}</div>
        )}
      </form>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    indicatorOn: () => dispatch(indicatorOn()),
    indicatorOff: () => dispatch(indicatorOff()),
  };
}

const mapStateToProps = state => {
  return {
    data_key: state.data.data_key, // reset button
  };
};

FileImage.propTypes = prop_types;

export default connect(mapStateToProps, mapDispatchToProps)(FileImage);
