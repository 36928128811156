import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { connect } from 'react-redux';
import { queueUpdate } from '../../../actions/actions.js';

class StarsWithState extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, index, value) {
    this.props.handleUpdate(this.props.venue_id, value);
    this.setState({ stars: value });
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(this.props);
  }

  init({ stars }) {
    this.setState({ stars: parseInt(stars, 10) });
  }

  render() {
    return (
      <SelectField
        floatingLabelText="Stars"
        value={this.state.stars}
        onChange={this.handleChange}>
        <MenuItem value={null} primaryText="" />
        {[1, 2, 3, 4, 5].map((item, index) => (
          <MenuItem value={item} primaryText={item} key={index} />
        ))}
      </SelectField>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    stars: data.venue_room.stars,
    venue_id: data.venue.venue_id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleUpdate: (venue_id, value) => {
      dispatch(queueUpdate('venue_room', venue_id, 'stars', value, 'venue_id'));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StarsWithState);
