import { createSelector } from 'reselect';

const getCountryList = ({ constants }) => constants.country_list || [];
const getUserCities = ({ data }) => data.user_cities || [];

const getUserCitiesAccess = createSelector(
  [getCountryList, getUserCities],
  (country_list, user_cities) => {
    let countries = [];
    user_cities.map((city, i) => {
      const country_id = city.country_id;
      let countries_index = countries.findIndex(
        co => co.country_id === country_id,
      );
      if (countries_index === -1) {
        countries_index = countries.length;
        const country = country_list.find(
          country => country.country_id === country_id,
        );
        if (!country) {
          // user may be associtaed with country previously removed
          return null;
        }
        countries[countries_index] = {
          country_id,
          country_name: country.name,
          cities: [],
        };
      }
      const { city_id, ...city_lean } = city;
      return countries[countries_index].cities.push(city_lean);
    });

    countries.sort(function (a, b) {
      return a.country_name.localeCompare(b.country_name);
    });
    return countries;
  },
);

export default getUserCitiesAccess;
