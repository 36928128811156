// @flow

import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { queueUpdateAuto } from '../../../actions/actions';
import arrayUnique from '../../../helpers/arrayUnique';

/*
Gdansk
 - [Whole city]
 - [About page]
 - events
    - [Events home]
    - [All events]   
 - features
    - [All features]
    - [Hevelius]
    - [Brovar amber]
 - districts
    - [All districts]
    - [Brzezno]
    - [Oliwa]
- chapters
    - [Restaurants]
        - [Home]
        - [All descendants]
*/

const BtnOn = ({ children, onClick, isInactive }) => (
  <span
    onClick={onClick}
    className={'btn btn_on ' + (isInactive ? 'inactive' : '')}>
    {children}
  </span>
);

const BtnOff = ({ children, onClick, isInactive }) => (
  <span onClick={onClick} className={'btn ' + (isInactive ? 'inactive' : '')}>
    {children}
  </span>
);

const BtnDisabled = ({ children }) => (
  <span className="btn btn_disabled">{children}</span>
);

type t_btn = {
  label: string,
  isOn: boolean,
  isDisabled: boolean,
  isInactive?: boolean,
  onClick?: (*) => void,
};

const Btn = ({ label, isOn, isDisabled, isInactive, onClick }: t_btn) => {
  if (isDisabled) {
    return <BtnDisabled>{label}</BtnDisabled>;
  }
  if (isOn) {
    return (
      <BtnOn onClick={onClick} isInactive={isInactive}>
        {label}
      </BtnOn>
    );
  }
  return (
    <BtnOff onClick={onClick} isInactive={isInactive}>
      {label}
    </BtnOff>
  );
};

const WholeCity = React.memo(({ city_id, isOn }) => {
  return <Btn isDisabled={false} isOn={isOn} label="Whole city" />;
});

const CityAbout = React.memo(({ city_id, isOn, isDisabled }) => {
  return <Btn isDisabled={isDisabled} isOn={isOn} label="City About" />;
});

const EventsAll = React.memo(({ city_id, isOn, isDisabled, handleChange }) => {
  return <Btn isDisabled={isDisabled} isOn={isOn} label="Events all" />;
});

const FeatureAll = React.memo(({ city_id, isOn, isDisabled }) => {
  return <Btn isDisabled={isDisabled} isOn={isOn} label="Features all" />;
});

const EventsHome = React.memo(({ city_id, isOn, isDisabled }) => {
  return <Btn isDisabled={isDisabled} isOn={isOn} label="Events home" />;
});

const ChapterWhole = React.memo(({ chapter_id, isOn, isDisabled }) => {
  return <Btn isDisabled={isDisabled} isOn={isOn} label="Whole chapter" />;
});

const Chapter = React.memo(({ chapter_id, isOn, isDisabled }) => {
  return <Btn isDisabled={isDisabled} isOn={isOn} label="Chapter home" />;
});

const DistrictWhole = React.memo(({ district_id, isOn, isDisabled }) => {
  return <Btn isDisabled={isDisabled} isOn={isOn} label="Whole district" />;
});

const District = React.memo(({ district_id, isOn, isDisabled }) => {
  return <Btn isDisabled={isDisabled} isOn={isOn} label="District home" />;
});

const Feature = React.memo(
  ({ feature, isOn, isDisabled, isInactive }) => {
    return (
      <Btn
        isDisabled={isDisabled}
        isOn={isOn}
        isInactive={feature.active ? false : true}
        label={feature.name}
      />
    );
  },
  (p: t_btn, n: t_btn) =>
    p.isOn === n.isOn &&
    p.isDisabled === n.isDisabled &&
    p.isInactive === n.isInactive,
);

const BranchChapterList = ({
  isCityWhole,
  chapter_list,
  display_chapter,
  display_chapter_whole,
  handleChange,
}) => {
  return (
    <ul>
      {chapter_list.map(({ chapter_id, active, name }) => {
        const chapter_whole = display_chapter_whole.includes(chapter_id);
        const isChapterOn = display_chapter.includes(chapter_id);

        return (
          <li key={chapter_id} className={active ? null : 'inactive'}>
            <span className={isCityWhole ? 'disabled' : null}>{name}</span>
            <ul>
              <li>
                <span
                  onClick={() => {
                    handleChange(
                      'display_chapter_whole',
                      !chapter_whole,
                      chapter_id,
                    );
                  }}>
                  <ChapterWhole
                    chapter_id={chapter_id}
                    isOn={chapter_whole}
                    isDisabled={isCityWhole}
                  />
                </span>
              </li>
              <li>
                <span
                  onClick={() => {
                    handleChange('display_chapter', !isChapterOn, chapter_id);
                  }}>
                  <Chapter
                    chapter_id={chapter_id}
                    isOn={isChapterOn}
                    isDisabled={isCityWhole || chapter_whole}
                  />
                </span>
              </li>
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

const BranchDistrictList = React.memo(
  ({
    isCityWhole,
    district_list,
    display_district,
    display_district_whole,
    handleChange,
  }) => {
    return (
      <ul>
        {district_list.map(({ district_id, name }) => {
          const district_whole = display_district_whole.includes(district_id);

          const isDistrictOn = display_district.includes(district_id);

          return (
            <li key={district_id}>
              <span className={isCityWhole ? 'disabled' : null}>{name}</span>
              <ul>
                <li>
                  <span
                    onClick={() => {
                      handleChange(
                        'display_district_whole',
                        !district_whole,
                        district_id,
                      );
                    }}>
                    <DistrictWhole
                      district_id={district_id}
                      isOn={district_whole}
                      isDisabled={isCityWhole}
                    />
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      handleChange(
                        'display_district',
                        !isDistrictOn,
                        district_id,
                      );
                    }}>
                    <District
                      district_id={district_id}
                      isOn={isDistrictOn}
                      isDisabled={isCityWhole || district_whole}
                    />
                  </span>
                </li>
              </ul>
            </li>
          );
        })}
      </ul>
    );
  },
  (p, n) => {
    return (
      p.isCityWhole === n.isCityWhole &&
      p.display_district.length === n.display_district.length && // any change cause array change
      p.display_district_whole.length === n.display_district_whole.length // any change cause array change
    );
  },
);

type t_marked = {
  display_city_whole: Array<number>,
  display_city_about: Array<number>,
  display_events_home: Array<number>,
  display_events_all: Array<number>,
  display_feature_all: Array<number>,
  display_feature: Array<number>,
  display_chapter: Array<number>,
  display_chapter_whole: Array<number>,
  display_district: Array<number>,
  display_district_whole: Array<number>,
};

type t_props_data = {
  marked: t_marked,
  city_list: Array<*>,
  chapter_list: Array<*>,
  feature_list: Array<*>,
  district_list: Array<*>,
  queueModify: any,
};

type t_open = {
  branch_city: Array<number>,
  branch_city_events: Array<number>,
  branch_city_features: Array<number>,
  branch_city_districts: Array<number>,
  branch_city_chapters: Array<number>,
};

type t_open_initial = {
  open_initial: t_open,
};

const PageTree = ({
  city_list,
  chapter_list,
  feature_list,
  district_list,
  marked,
  queueModify,
}: t_props_data & t_open_initial) => {
  const [state, setState] = useState(marked);

  const [did_open_change, setDidOpenChange] = useState(false);

  // if fn openInitial is used in setState it's called every time. We want to call it once
  const open_initial = did_open_change
    ? {
        branch_city: [],
        branch_city_events: [],
        branch_city_features: [],
        branch_city_districts: [],
        branch_city_chapters: [],
      }
    : openInitial(city_list, chapter_list, feature_list, district_list, marked);
  const [open: t_open, setOpen] = useState(open_initial);

  const toggleOpen = (type, id) => {
    if (typeof open[type] === 'undefined') {
      throw new Error('Ivalid open type.');
    }
    const new_branch = open[type].includes(id)
      ? open[type].filter(el => el !== id)
      : [...open[type], id];
    setOpen({ ...open, [type]: new_branch });
    setDidOpenChange(true);
  };

  const ExpandEndSelect = (
    city_id,
    chapter_ids_by_city,
    district_ids_by_city,
    feature_ids_by_city,
  ) => {
    setOpen({
      ...open,
      branch_city_chapters: arrayUnique([
        ...open.branch_city_chapters,
        city_id,
      ]),
      branch_city_districts: arrayUnique([
        ...open.branch_city_districts,
        city_id,
      ]),
      branch_city_features: arrayUnique([
        ...open.branch_city_features,
        city_id,
      ]),
      branch_city_events: arrayUnique([...open.branch_city_events, city_id]),
    });

    const display_chapter_whole = arrayUnique([
      ...state.display_chapter_whole,
      ...chapter_ids_by_city,
    ]);
    const display_events_all = arrayUnique([
      ...state.display_events_all,
      city_id,
    ]);
    const display_district_whole = arrayUnique([
      ...state.display_district_whole,
      ...district_ids_by_city,
    ]);
    const display_feature = arrayUnique([
      ...state.display_feature,
      ...feature_ids_by_city,
    ]);
    const display_city_about = arrayUnique([
      ...state.display_city_about,
      city_id,
    ]);

    setState({
      ...state,
      display_chapter_whole,
      display_events_all,
      display_district_whole,
      display_feature,
      display_city_about,
    });

    setDidOpenChange(true);

    queueModify('display_chapter_whole', display_chapter_whole);
    queueModify('display_events_all', display_events_all);
    queueModify('display_district_whole', display_district_whole);
    queueModify('display_feature', display_feature);
    queueModify('display_city_about', display_city_about);
  };

  const handleChange = (field, isOn, item_id) => {
    //console.log("field", field, "isOn", isOn, "item_id", item_id);
    if (typeof state[field] === 'undefined') {
      throw new Error('Ivalid field type. Did you forget "display_" suffix?');
    }

    const marked_page_prev = state[field];

    const marked_page_new = isOn
      ? [...marked_page_prev, item_id]
      : marked_page_prev.filter(el => el !== item_id);

    setState({
      ...state,
      [field]: marked_page_new,
    });

    queueModify(field, marked_page_new);
  };

  return (
    <ul className="affiliate_page_tree">
      {city_list.map(city => {
        const { city_id, active, name } = city;
        const {
          display_city_whole,
          display_feature,
          display_events_all,
          display_events_home,
          display_chapter,
          display_chapter_whole,
          display_city_about,
          display_feature_all,
          display_district,
          display_district_whole,
        } = state;

        const isCityWhole = useMemo(
          () => display_city_whole.includes(city_id),
          [display_city_whole, city_id],
        );
        const feature_list_by_city = useMemo(
          () => feature_list.filter(f => f.city_id === city_id),
          [feature_list, city_id],
        );
        const feature_ids_by_city = useMemo(
          () => feature_list_by_city.map(({ feature_id }) => feature_id),
          [feature_list_by_city, city_id],
        );
        const chapter_list_by_city = useMemo(
          () => chapter_list.filter(ch => ch.city_id === city_id),
          [chapter_list, city_id],
        );
        const chapter_ids_by_city = useMemo(
          () => chapter_list_by_city.map(({ chapter_id }) => chapter_id),
          [chapter_list_by_city, city_id],
        );
        const display_chapter_by_city = useMemo(
          () =>
            display_chapter.filter(chapter_id =>
              chapter_ids_by_city.includes(chapter_id),
            ),
          [display_chapter, chapter_ids_by_city],
        );
        const display_chapter_whole_by_city = useMemo(
          () =>
            display_chapter_whole.filter(chapter_id =>
              chapter_ids_by_city.includes(chapter_id),
            ),
          [display_chapter_whole, chapter_ids_by_city],
        );

        const district_list_by_city = useMemo(
          () => district_list.filter(ch => ch.city_id === city_id),
          [district_list, city_id],
        );
        const district_ids_by_city = useMemo(
          () => district_list_by_city.map(({ district_id }) => district_id),
          [district_list_by_city, city_id],
        );
        const display_district_by_city = useMemo(
          () =>
            display_district.filter(district_id =>
              district_ids_by_city.includes(district_id),
            ),
          [display_district, district_ids_by_city],
        );
        const display_district_whole_by_city = useMemo(
          () =>
            display_district_whole.filter(district_id =>
              district_ids_by_city.includes(district_id),
            ),
          [display_district_whole, district_ids_by_city],
        );

        const display_features_all_by_city =
          display_feature_all.includes(city_id);

        const isCityAboutOn = display_city_about.includes(city_id);

        const isEventsAllOn = display_events_all.includes(city_id);

        const isEventsHomeOn = display_events_home.includes(city_id);

        return (
          <li key={city_id} className={active ? null : ' inactive '}>
            <span
              className={
                'collapsible ' +
                (open.branch_city.includes(city_id) ? ' open ' : '')
              }
              onClick={() => toggleOpen('branch_city', city_id)}>
              {name}
            </span>
            <ul>
              <li>
                <span
                  onClick={() => {
                    handleChange('display_city_whole', !isCityWhole, city_id);
                  }}>
                  <WholeCity isOn={isCityWhole} city_id={city_id} />
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    ExpandEndSelect(
                      city_id,
                      chapter_ids_by_city,
                      district_ids_by_city,
                      feature_ids_by_city,
                    );
                  }}>
                  {' '}
                  Expand and select children{' '}
                </span>
              </li>
              <li>
                <span
                  onClick={() => {
                    handleChange('display_city_about', !isCityAboutOn, city_id);
                  }}>
                  <CityAbout
                    isOn={isCityAboutOn}
                    isDisabled={isCityWhole}
                    city_id={city_id}
                  />
                </span>
              </li>

              {feature_list_by_city.length > 0 && (
                <li>
                  <span
                    className={
                      ' collapsible ' +
                      (isCityWhole ? ' disabled ' : '') +
                      (open.branch_city_features.includes(city_id)
                        ? ' open '
                        : '')
                    }
                    onClick={() => toggleOpen('branch_city_features', city_id)}>
                    Features
                  </span>
                  <ul>
                    <li>
                      <span
                        onClick={() => {
                          handleChange(
                            'display_feature_all',
                            !display_features_all_by_city,
                            city_id,
                          );
                        }}>
                        <FeatureAll
                          isOn={display_features_all_by_city}
                          isDisabled={isCityWhole}
                          city_id={city_id}
                        />
                      </span>
                    </li>
                    {feature_list_by_city.map(feature => {
                      const isFeatureOn = display_feature.includes(
                        feature.feature_id,
                      );
                      return (
                        <li key={feature.feature_id}>
                          <span
                            onClick={() => {
                              handleChange(
                                'display_feature',
                                !isFeatureOn,
                                feature.feature_id,
                              );
                            }}>
                            <Feature
                              feature={feature}
                              isOn={isFeatureOn}
                              isDisabled={
                                isCityWhole ||
                                display_feature_all.includes(city_id)
                              }
                              label=""
                            />
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              )}

              {district_list_by_city.length > 0 && (
                <li>
                  <span
                    className={
                      'collapsible ' +
                      (isCityWhole ? ' disabled ' : '') +
                      (open.branch_city_districts.includes(city_id)
                        ? ' open '
                        : '')
                    }
                    onClick={() =>
                      toggleOpen('branch_city_districts', city_id)
                    }>
                    Districts
                  </span>
                  <BranchDistrictList
                    district_list={district_list_by_city}
                    isCityWhole={isCityWhole}
                    handleChange={handleChange}
                    display_district={display_district_by_city}
                    display_district_whole={display_district_whole_by_city}
                  />
                </li>
              )}

              {chapter_list_by_city.length > 0 && (
                <li>
                  <span
                    className={
                      'collapsible ' +
                      (isCityWhole ? ' disabled ' : '') +
                      (open.branch_city_chapters.includes(city_id)
                        ? ' open '
                        : '')
                    }
                    onClick={() => toggleOpen('branch_city_chapters', city_id)}>
                    Chapters
                  </span>
                  <BranchChapterList
                    chapter_list={chapter_list_by_city}
                    isCityWhole={isCityWhole}
                    handleChange={handleChange}
                    display_chapter={display_chapter_by_city}
                    display_chapter_whole={display_chapter_whole_by_city}
                  />
                </li>
              )}

              <li>
                <span
                  className={
                    'collapsible ' +
                    (isCityWhole ? ' disabled ' : '') +
                    (open.branch_city_events.includes(city_id) ? ' open ' : '')
                  }
                  onClick={() => toggleOpen('branch_city_events', city_id)}>
                  Events
                </span>
                <ul>
                  <li>
                    <span
                      onClick={() => {
                        handleChange(
                          'display_events_all',
                          !isEventsAllOn,
                          city_id,
                        );
                      }}>
                      <EventsAll
                        city_id={city_id}
                        isOn={isEventsAllOn}
                        isDisabled={isCityWhole}
                      />
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() => {
                        handleChange(
                          'display_events_home',
                          !isEventsHomeOn,
                          city_id,
                        );
                      }}>
                      <EventsHome
                        city_id={city_id}
                        isOn={isEventsHomeOn}
                        isDisabled={isCityWhole}
                      />
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

const openInitial = (
  city_list,
  chapter_list,
  feature_list,
  district_list,
  marked,
) => {
  const {
    display_city_whole,
    display_city_about,
    display_events_home,
    display_events_all,
    display_feature,
    display_feature_all,
    display_chapter_whole,
    display_chapter,
    display_district_whole,
    display_district,
  } = marked;

  const city_ids_from_chapters = chapter_list
    .filter(chapter =>
      [...display_chapter, ...display_chapter_whole].includes(
        chapter.chapter_id,
      ),
    )
    .map(({ city_id }) => city_id);

  const city_ids_from_features = feature_list
    .filter(feature => display_feature.includes(feature.feature_id))
    .map(({ city_id }) => city_id);

  const city_ids_from_districts = district_list
    .filter(district =>
      [...display_district, ...display_district_whole].includes(
        district.district_id,
      ),
    )
    .map(({ city_id }) => city_id);

  const out = {
    branch_city: arrayUnique(
      [].concat(
        display_city_whole,
        display_city_about,
        display_events_home,
        display_events_all,
        display_feature_all,
        city_ids_from_features,
        city_ids_from_chapters,
        city_ids_from_districts,
      ),
    ),
    branch_city_events: [].concat(display_events_home, display_events_all),
    branch_city_features: arrayUnique(
      [].concat(display_feature_all, city_ids_from_features),
    ),
    branch_city_districts: arrayUnique(city_ids_from_districts),
    branch_city_chapters: arrayUnique(city_ids_from_chapters),
  };

  return out;
};

const mapStateToProps = ({ data }) => {
  const {
    affiliate_widget,
    city_list,
    chapter_list,
    feature_list,
    district_list,
  } = data;

  const {
    display_city_whole,
    display_city_about,
    display_events_home,
    display_events_all,
    display_feature_all,
    display_feature,
    display_chapter,
    display_chapter_whole,
    display_district,
    display_district_whole,
  } = affiliate_widget;

  const marked = {
    display_city_whole,
    display_city_about,
    display_events_home,
    display_events_all,
    display_feature_all,
    display_feature,
    display_chapter,
    display_chapter_whole,
    display_district,
    display_district_whole,
  };

  return {
    city_list,
    chapter_list,
    feature_list,
    district_list,
    marked,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    queueModify: (field, value) => {
      dispatch(queueUpdateAuto(field, value));
    },
  };
};

const PageTreeWithData = connect(mapStateToProps, mapDispatchToProps)(PageTree);

export default PageTreeWithData;
