// @flow

import React from 'react';
import { connect } from 'react-redux';

//!!!!!!
//import Cookies from "js-cookie";

import fetchPageData from '../actions/fetchPageData';
import NotFound from '../page/NotFound';

type TMapRouteToApiParams = (*) => {
  page_type: string,
  page_id: number,
  api_call: string,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchPageData: data => dispatch(fetchPageData(data)),
    ...ownProps,
  };
};

const mapStateToProps = ({ data, page_control }) => {
  return {
    data_key: data.data_key || null,
    error: page_control.api_fetch_error,
  };
};

function withDataFromUrl(
  mapRouteToApiParams: TMapRouteToApiParams,
  Wrapped: any,
) {
  class DataFromUrl extends React.Component<
    {
      data_key: string,
      error: string,
      match: any, // from router
      fetchPageData: (*) => void,
    },
    {
      error: ?string,
    },
  > {
    state = {
      error: null,
    };

    fetchData() {
      const { page_type, page_id, api_call } = mapRouteToApiParams(
        this.props.match.params,
      );

      this.props.fetchPageData({ page_type, page_id, api_call });
    }

    componentDidMount() {
      this.fetchData();
    }

    componentDidUpdate(prevProps) {
      if (this.props.match.url !== prevProps.match.url) {
        this.fetchData();
      }
    }

    render() {
      const { data_key, error } = this.props;

      if (error) return <NotFound message={'backend error: ' + error} />;

      return <Wrapped key={data_key} {...this.props} />;
    }
  }

  const DataFromUrlConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DataFromUrl);

  return DataFromUrlConnected;
}

export default withDataFromUrl;
