import React, { Component } from 'react';

import Checkbox from 'material-ui/Checkbox';

const Label = ({ name, abbr }) => (
  <span style={{ whiteSpace: 'nowrap' }}>
    {name}
    <div
      style={{
        display: 'inline-block',
        opacity: 0.5,
        transform: 'scale(0.8) translate(-2px,-4px)',
      }}>
      &nbsp;{abbr}
    </div>
  </span>
);
export { Label };

export default class PcCheckbox extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: props.value ? true : false,
    };

    if (typeof props.checked !== 'undefined') {
      console.warn(
        'PcCheckbox component expects "value", not "checked" attribute',
      );
    }
  }

  componentWillReceiveProps({ value }) {
    this.setState({
      value: value ? true : false,
    });
  }

  handleChange(event, isChecked) {
    const local_value = isChecked;
    this.setState({ value: local_value }, () => {
      const db_value = local_value ? '1' : null;
      this.props.handleChange(db_value);
    });
  }

  render() {
    const style = {
      marginRight: '2em',
      display: 'inline-block',
      width: 'auto',
    };
    const iconStyleChanged = { marginRight: '5px', ...this.props.iconStyle };

    const {
      value,
      checked,
      onCheck,
      handleChange,
      handleRemoveError,
      handleAddError,
      iconStyle,
      ...props
    } = this.props;

    return (
      <div style={style}>
        <Checkbox
          checked={this.state.value}
          onCheck={this.handleChange}
          iconStyle={iconStyleChanged}
          {...props}
        />
      </div>
    );
  }
}
