import './css/App.css';
import './css/bootstrap-grid-only.css';

import React, { Component } from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from './history.js';
import PageLoader from './core/PageLoader';
import PromptLeave from './page/shared/PromptLeave';

import Search from './page/Search';
import LoginPage from './page/LoginPage';
import NotFound from './page/NotFound';
import Test from './page/Test';
import Snacks from './page/shared/Snacks';
import Spinner from './page/shared/Spinner';
import AddNew from './page/shared/AddNew/AddNew';

import AffiliateHome from './page/affiliate/AffiliateHome';
import {
  AffiliateListByCountry,
  AffiliateListGlobal,
} from './page/affiliate/AffiliateList';
import AffiliateEdit from './page/affiliate/widget/AffiliateEdit';
import AffiliateNew from './page/affiliate/AffiliateNew';
import AffiliateVenueExlusions from './page/affiliate/AffiliateVenueExlusions';
import AffiliateTargetGroupAll from './page/affiliate/AffiliateTargetGroupAll';
import AffiliateTargetGroupEdit from './page/affiliate/group/AffiliateTargetGroupEdit';
import AffiliateTargetGroupNew from './page/affiliate/AffiliateTargetGroupNew';

import DoFollowLinks from './page/do_follow_links/DoFollowLinks';
import { FooterArticleList } from './page/footer_article/FooterArticleList';
import { FooterArticle } from './page/footer_article/FooterArticle';

//import injectTapEventPlugin from 'react-tap-event-plugin'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import store from './store';

//injectTapEventPlugin();

let theme = getMuiTheme();
theme.baseTheme.fontFamily = 'sans-serif';
theme.textField.floatingLabelColor = 'rgba(0, 0, 0, 0.5)';

/*
if (process.env.NODE_ENV !== 'production') {
  const {whyDidYouUpdate} = require('why-did-you-update')
  whyDidYouUpdate(React)
}
*/

class App extends Component {
  render() {
    return (
      <MuiThemeProvider muiTheme={theme}>
        <Provider store={store}>
          <Router history={history}>
            <div>
              <PromptLeave />
              <Snacks />
              <Spinner />
              <AddNew />
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/home" />} />
                <Route path="/login" component={LoginPage} />
                <Route path="/test" component={Test} />
                <Route
                  exact
                  path="/affiliate/by_country/:country_id/new"
                  component={AffiliateNew}
                />

                <Route
                  exact
                  path="/affiliate/global/new"
                  component={AffiliateNew}
                />

                <Route
                  exact
                  path="/affiliate/global/all"
                  component={AffiliateListGlobal}
                />

                <Route
                  exact
                  path="/affiliate/global/:affiliate_widget_id"
                  component={AffiliateEdit}
                />

                <Route
                  exact
                  path="/affiliate/target_group/all"
                  component={AffiliateTargetGroupAll}
                />

                <Route
                  exact
                  path="/affiliate/target_group/new"
                  component={AffiliateTargetGroupNew}
                />

                <Route
                  exact
                  path="/affiliate/target_group/:affiliate_target_group_id"
                  component={AffiliateTargetGroupEdit}
                />

                <Route
                  exact
                  path="/affiliate/by_country/:country_id/venue_exclusions"
                  component={AffiliateVenueExlusions}
                />

                <Route
                  exact
                  path="/affiliate/by_country/:country_id/all"
                  component={AffiliateListByCountry}
                />

                <Route
                  exact
                  path="/affiliate/by_country/:country_id/:affiliate_widget_id"
                  component={AffiliateEdit}
                />

                <Route exact path="/affiliate" component={AffiliateHome} />
                <Route
                  path="/search/:country_id/:city_id/:q"
                  component={Search}
                />
                <Route
                  path="/:page_type/:page_symbol/:page_id"
                  component={PageLoader}
                />
                <Route
                  exact
                  path="/do_follow_links"
                  component={DoFollowLinks}
                />
                <Route
                  exact
                  path="/footer_article"
                  component={FooterArticleList}
                />
                <Route
                  exact
                  path="/footer_article/:footer_article_id"
                  component={FooterArticle}
                />
                <Route path="/:page_type/:page_id" component={PageLoader} />
                <Route path="/:page_type" component={PageLoader} />
                <Route path="*" component={NotFound} />
              </Switch>
            </div>
          </Router>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
