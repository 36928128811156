export default [
  ['Top', 'breadcrumbs'],
  ['Name', 'card_name'],
  ['Description', 'card_description'],
  ['Www', 'card_www'],
  ['Price & tickets', 'card_price'],
  ['Dates / Venue', 'card_date'],
  ['Connected Events', 'card_connected_events'],
  ['Connected Features', 'card_features'],
  ['Display in', 'card_parents'],
  ['Researcher comments', 'card_researcher_comments'],
  ['Images text', 'card_images_text'],
  ['Images background', 'card_images_bg'],
  ['Images App mobile', 'card_images_app'],
  ['Meta tags', 'card_meta_tags'],
];
