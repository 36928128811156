// @flow

// $FlowFixMe - after flow update https://github.com/facebook/flow/pull/7149/
import React, { useState } from 'react';
import { connect } from 'react-redux';

import RaisedButton from 'material-ui/RaisedButton';

import type { TAffiliateWidgetGroup } from '../types';

import PcAppBar from '../../shared/TopBar/PcAppBar';
import BreadCrumbs from '../../shared/BreadCrumbs';
import PcTextField from '../../shared/PcTextField';
import PcCard from '../../shared/PcCard';
import Info from '../../shared/Info';
import FlatButton from 'material-ui/FlatButton';

import countryListIso from '../../../helpers/country_list_iso.json';
import languageListIso from '../../../helpers/language_list_iso.json';

import withUserDataFetched from '../../../core/withUserDataFetched';
import withDataFromUrl from '../../../core/withDataFromUrl';
import { withAccessSuperadmin } from '../../../core/withAccess';

import { queueUpdateObj } from '../../../actions/actions';
import { save } from '../../../actions/save';
import { archive } from '../../../actions/removeArchive';

import Countries from './Countries';
import Languages from './Languages';
import LogicalConnective from './LogicalConnective';

const getDataError = ({
  utmCampaign,
  countryCodes,
  languageCodes,
  logicalConnective,
}) => {
  // if more than one conditions given then expect logicalConnective not empty
  if (
    getNumberOfConditionsSet({ utmCampaign, countryCodes, languageCodes }) >
      1 &&
    !logicalConnective
  ) {
    return 'Define logical connective when more than one condition definied';
  }
  return null;
};

const getDescription = ({
  utmCampaign,
  countryCodes,
  languageCodes,
  logicalConnective,
}) => {
  if (
    getNumberOfConditionsSet({ utmCampaign, countryCodes, languageCodes }) === 0
  ) {
    return 'This group is inactive / no conditions definied';
  }
  let conds = [];
  if (utmCampaign.length > 0) {
    conds.push('Page url contains given UTM param. ');
  }
  if (countryCodes.length > 0) {
    const countries = countryListIso
      .filter(item => countryCodes.includes(item[1]))
      .map(c => c[0]);
    conds.push(
      countries.length === 1
        ? 'User is now in ' + countries[0] + '. '
        : 'User is now in any of ' + countries.join(', ') + ' countries. ',
    );
  }
  if (languageCodes.length > 0) {
    const languages = languageListIso
      .filter(item => languageCodes.includes(item[1]))
      .map(c => c[0]);
    conds.push(
      languages.length === 1
        ? 'User use ' + languages[0] + ' language. '
        : 'User use any of ' + languages.join(', ') + ' languages. ',
    );
  }
  if (conds.length === 1) return conds[0];

  if (!logicalConnective) return 'error'; // should not happen

  return (
    (logicalConnective === 'AND'
      ? 'All of the following conditions must be met: '
      : 'Any of the conditions must be met: ') + conds.join(' ')
  );
};

const getNumberOfConditionsSet = ({
  utmCampaign,
  countryCodes,
  languageCodes,
}) => {
  const utm_flag = utmCampaign.length > 0 ? 1 : 0;
  const countries_flag = countryCodes.length > 0 ? 1 : 0;
  const languages_flag = languageCodes.length > 0 ? 1 : 0;
  return utm_flag + countries_flag + languages_flag;
};

type TProps = {
  target_group: TAffiliateWidgetGroup,
  onUpdate: any,
  onSave: any,
  onArchive: any,
};

const AffiliateTargetGroupEdit = ({
  target_group,
  onUpdate,
  onSave,
  onArchive,
}: TProps) => {
  if (!target_group) return null;

  const { country_codes, language_codes, utm_campaign, logical_connective } =
    target_group;

  const [name, setName] = useState(target_group.name || '');
  const [utmCampaign, setUtmCampaign] = useState(utm_campaign || '');
  const [countryCodes, setCountryCodes] = useState(country_codes || []);
  const [languageCodes, setLanguageCodes] = useState(language_codes || []);
  const [logicalConnective, setLogicalConnective] = useState(
    logical_connective || null,
  );

  const _setCountryCodes = (country_codes: Array<string>) => {
    setCountryCodes(country_codes);
    _modify({ country_codes });
  };

  const _setLanguageCodes = (language_codes: Array<string>) => {
    setLanguageCodes(language_codes);
    _modify({ language_codes });
  };

  const _setUtmCampaign = (utm_campaign: string) => {
    const utm_trimmed = utm_campaign.trim();
    setUtmCampaign(utm_trimmed);
    _modify({ utm_campaign: utm_trimmed });
  };

  const _setName = (name: string) => {
    const name_trimmed = name.trim();
    setName(name_trimmed);
    _modify({ name: name_trimmed });
  };

  const _setLogicalConnective = (logical_connective: 'AND' | 'OR' | null) => {
    setLogicalConnective(logical_connective);
    _modify({ logical_connective });
  };

  const _modify = (data, target_group_id) => {
    onUpdate(data, target_group.affiliate_target_group_id);
  };

  if (!target_group) return null;

  const breadcrumbs = [
    {
      url: '/',
      name: 'home',
    },
    {
      url: '/affiliate',
      name: 'Affliliate home',
    },
    {
      url: '/affiliate/target_group/all',
      name: 'target groups',
    },

    {
      url: '/affiliate/target_group/' + target_group.affiliate_target_group_id,
      name: target_group.name,
    },
  ];

  const dataError = getDataError({
    utmCampaign,
    countryCodes,
    languageCodes,
    logicalConnective,
  });

  const dataDescription = dataError
    ? null
    : getDescription({
        utmCampaign,
        countryCodes,
        languageCodes,
        logicalConnective,
      });

  const shouldShowLogicalConnective =
    getNumberOfConditionsSet({ utmCampaign, countryCodes, languageCodes }) > 1;

  return (
    <>
      <PcAppBar title={'Affiliate Target Groups '} no_drawer={true} />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
          {/* <h1>{country ? country.name : 'Global'}</h1> */}
        </div>

        <div style={{ maxWidth: '700px', margin: 'auto' }}>
          <PcCard cardTitle="Name">
            <PcTextField handleChange={_setName} value={name} />
          </PcCard>
          <br />
          <br />

          <PcCard cardTitle="UTM campaign code">
            The widget will be displayed if the page url conatins given code:
            <PcTextField handleChange={_setUtmCampaign} value={utmCampaign} />
          </PcCard>

          <br />
          <br />

          <Countries handleChange={_setCountryCodes} selected={countryCodes} />

          <br />
          <br />

          <Languages
            handleChange={_setLanguageCodes}
            selected={languageCodes}
          />

          <br />
          <br />
          <LogicalConnective
            handleChange={_setLogicalConnective}
            logical_connective={logicalConnective}
            disabled={!shouldShowLogicalConnective}
          />

          <br />
          {dataError && <Info errors={[dataError]} />}
          {dataDescription && <Info notices={[dataDescription]} />}
          <br />

          <div className="pd">
            <RaisedButton
              disabled={!!dataError || !name}
              label={'save'}
              onClick={onSave}
            />
            {!target_group.archived && (
              <div className="pull-right">
                <FlatButton
                  label="Archive"
                  secondary={true}
                  onClick={() =>
                    onArchive(target_group.affiliate_target_group_id)
                  }
                />
              </div>
            )}
          </div>
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ data }) => {
  return {
    target_group: data.target_group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdate: (data, affiliate_target_group_id) => {
      dispatch(
        queueUpdateObj(
          'affiliate_target_group',
          affiliate_target_group_id,
          data,
        ),
      );
    },

    onArchive: affiliate_target_group_id => {
      if (!window.confirm('Are you sure?')) return;
      const go_to = 'affiliate/target_group/all';
      dispatch(
        archive(
          'affiliate_target_group',
          'affiliate_target_group_id',
          affiliate_target_group_id,
          go_to,
        ),
      );
    },
    onSave: () => dispatch(save()),
  };
};

const AffiliateTargetGroupItem = withUserDataFetched(
  withDataFromUrl(({ affiliate_target_group_id }) => {
    return {
      page_type: 'affiliate_target_group',
      page_id: affiliate_target_group_id,
      api_call: 'page/affiliate/target_group/' + affiliate_target_group_id,
    };
  }, withAccessSuperadmin(connect(mapStateToProps, mapDispatchToProps)(AffiliateTargetGroupEdit))),
);

export default AffiliateTargetGroupItem;
