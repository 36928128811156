import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { connect } from 'react-redux';
import { languages } from '../../config/languages';
import { queueUpdate } from '../../actions/actions';

const CityLanguageView = ({
  lang,
  lang_parent,
  city_list,
  handleLangChange,
  handleParentChange,
}) => (
  <div>
    <div className="pd-w"> Use only if non english </div>

    <div className="row-flex">
      <SelectField
        floatingLabelText="Language"
        value={lang}
        onChange={(e, i, payload) => handleLangChange(payload)}
        autoWidth={true}>
        <MenuItem value="" primaryText="None (English)" />
        {languages.map((item, index) => (
          <MenuItem value={item[0]} primaryText={item[1]} key={index} />
        ))}
      </SelectField>

      <SelectField
        floatingLabelText="English version parent"
        value={lang_parent}
        onChange={(e, i, payload) => handleParentChange(payload)}
        disabled={lang ? false : true}
        autoWidth={true}>
        <MenuItem value="" primaryText="None" />
        {city_list.map(city => (
          <MenuItem
            value={city.city_id}
            primaryText={city.name}
            key={city.city_id}
            style={!city.active ? { color: 'grey' } : {}}
          />
        ))}
      </SelectField>
    </div>
  </div>
);

class CityLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: props.lang,
      lang_parent: props.lang_parent,
    };
  }

  handleLangChange = lang => {
    let new_state = { lang };
    if (!lang) new_state = { lang_parent: null, ...new_state };
    this.setState(new_state);
    const { dispatch, city_id } = this.props;
    dispatch(queueUpdate('city', city_id, 'lang', lang));
  };

  handleParentChange = lang_parent => {
    this.setState({ lang_parent });
    const { dispatch, city_id } = this.props;
    dispatch(queueUpdate('city', city_id, 'lang_parent', lang_parent));
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.city_id === this.props.city_id &&
      nextProps.city_list.length === this.props.city_list.length
    )
      return;
    this.setState({
      lang: nextProps.lang,
      lang_parent: nextProps.lang_parent,
    });
  }

  render() {
    const { lang, lang_parent } = this.state;
    const { handleLangChange, handleParentChange } = this;
    const { city_list } = this.props;

    return (
      <CityLanguageView
        city_list={city_list}
        lang={lang}
        lang_parent={lang_parent}
        handleLangChange={handleLangChange}
        handleParentChange={handleParentChange}
      />
    );
  }
}

const mapStateToProps = ({ data, constants }) => {
  const { lang, city_id, lang_parent } = data.city;

  const constants_loaded = Object.keys(constants).length > 0 ? true : false;

  const city_list = constants_loaded ? constants.city_list : []; // causes break as constansts load asnyc

  const citiesFiltered = city_list =>
    city_list.filter(
      city => city.country_id === data.country.country_id && !city.lang,
    );

  return {
    lang,
    city_id,
    lang_parent,
    city_list: city_list ? citiesFiltered(city_list) : [],
  };
};

export default connect(mapStateToProps)(CityLanguage);
