import React from 'react';
import { connect } from 'react-redux';
import { queueUpdate } from '../../actions/actions.js';
import PcCard from '../shared/PcCard.js';
import PcCheckbox, { Label } from '../shared/PcCheckbox.js';
import EditorState from '../shared/EditorState.js';
import { modifyWrapper } from '../../core/ModifyWrapper';

//import config from '../config/config';

/************** Part checkbxes flags ************/

const MCheckbox = modifyWrapper(PcCheckbox, 'venue');

const PartCheckboxes = () => (
  <div>
    <MCheckbox name="print" label={<Label name="print" abbr="p" />} />
    <MCheckbox name="web" label={<Label name="web" abbr="w" />} />
    <MCheckbox name="mobile" label={<Label name="app" abbr="a" />} />
    <MCheckbox name="mini" label={<Label name="mini" abbr="m" />} />
    <MCheckbox name="chapter_pick" label={<Label name="featured" abbr="h" />} />
    <MCheckbox name="instant" label={<Label name="instant" abbr="i" />} />
    <MCheckbox name="filter1" label={<Label name="filter1" abbr="s" />} />
    <MCheckbox name="filter2" label={<Label name="filter2" abbr="f" />} />

    <div style={{ float: 'right' }}>
      <MCheckbox name="closed" label={<Label name="closed" abbr="c" />} />
      <MCheckbox name="is_new" label={<Label name="new" abbr="n" />} />
    </div>
  </div>
);

/************** Part radios state ************/

const mapStateToProps = ({ data }) => {
  const { venue, data_key } = data;
  const { state, venue_id } = venue;
  return {
    value: state,
    data_id: venue_id,
    dom_key: data_key,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleChange: (data_id, value) => {
      dispatch(queueUpdate('venue', data_id, 'state', value));
    },
  };
};

const PartStateRadios = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditorState);

/******************* Final component ************/

const VenueCardSettings = ({ data_id }) => (
  <PcCard cardTitle="" id="card_top">
    <div className="pd" />
    <PartCheckboxes />
    <PartStateRadios data_id={data_id} />
  </PcCard>
);

export default VenueCardSettings;
