import { createSelector } from 'reselect';

const getCountryList = ({ constants }) => constants.country_list || [];
const getCityList = ({ constants }) => constants.city_list || [];
const getCityId = ({ data }) => (data.city ? data.city.city_id : null);
const getCountryId = ({ data }) =>
  data.country ? data.country.country_id : null;

const getCityMenu = createSelector(
  [getCountryList, getCityList, getCityId, getCountryId],
  (country_list, city_list, city_id, country_id) => {
    //console.log('getCityMenu selector')

    if (!city_list || city_list.length === 0) return [];

    const l1 = country_list ? country_list.length : [];
    const l2 = city_list ? city_list.length : [];

    const display_inactive =
      city_list.filter(item => !item.active).length > 10 ? false : true;

    let data = [];

    for (let i = 0; i < l1; i++) {
      const country = country_list[i];
      data.push({
        name: country.name,
        value: 'country' + country.country_id,
        link: '/country/' + country.symbol + '/' + country.country_id,
        active: country.active ? true : false,
        current:
          !city_id && country_id && country_id === country.country_id
            ? true
            : false,
        is_country: true,
      });
      for (let j = 0; j < l2; j++) {
        const city = city_list[j];
        if (city.country_id !== country.country_id) continue;

        // display inactive citiesonly if marked as current when more tha 10 inactive cities
        if (
          !city.active &&
          !display_inactive &&
          (!city_id || (city_id && city_id !== city.city_id))
        )
          continue;

        data.push({
          name: city.name,
          value: 'city' + city.city_id,
          link: '/city/' + city.symbol + '/' + city.city_id,
          active: city.active ? true : false,
          current: city_id && city_id === city.city_id ? true : false,
        });
      }
    }
    return data;
  },
);

export default getCityMenu;
