// @flow
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { leloFetch } from '../../helpers';
import PcAppBar from '../shared/TopBar/PcAppBar';
import BreadCrumbs from '../shared/BreadCrumbs';
import { TFooterArticle } from './TFooterArticle';
import PcHtmlEditor from '../shared/PcHtmlEditor';
import TextField from 'material-ui/TextField';
import { SaveButtonPure } from '../shared/TopBar/SaveButton.js';
import { connect } from 'react-redux';
import { queueUpdateObj } from '../../actions/actions';

import { breadcrumbs as footerArticleListBreadcrumbs } from './FooterArticleList';
import { save } from '../../actions/save';
import { isSuperAdmin } from '../../helpers/hasAccess';

const FooterArticleFn = ({ dispatch, match }) => {
  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [name, setName] = useState<string>('');

  const footer_article_id = parseInt(match.params.footer_article_id, 10);

  useEffect(() => {
    const fetchData = async () => {
      const data: TFooterArticle[] = await leloFetch('footerArticles');
      const article = (data || []).find(a => a.static_id === footer_article_id);
      if (article) {
        setTitle(article.name_text);
        setBody(article.body);
        setName(article.name);
      }
    };
    fetchData();
  }, []);

  const breadcrumbs = useMemo(
    () => [
      ...footerArticleListBreadcrumbs,
      {
        url: '/footer_article/' + footer_article_id,
        name,
      },
    ],
    [name, footer_article_id],
  );

  if (!title || !body || !name) return null;

  if (!isSuperAdmin) return false;

  return (
    <>
      <PcAppBar
        title={name}
        no_drawer={true}
        custom_save_component={() => (
          <SaveButtonPure label="Save" onClick={() => dispatch(save())} />
        )}
      />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
        </div>

        <div className="pd">
          <TextField
            floatingLabelText={'title'}
            value={title}
            onChange={e => {
              const title = e.target.value;
              setTitle(title);
              dispatch(
                queueUpdateObj('static', footer_article_id, {
                  name_text: title,
                }),
              );
            }}
          ></TextField>
        </div>

        <div className="pd">
          <PcHtmlEditor
            value={body}
            handleChange={body => {
              setBody(body);
              dispatch(queueUpdateObj('static', footer_article_id, { body }));
            }}
            dom_key={'1'}
          ></PcHtmlEditor>
        </div>
      </div>
    </>
  );
};

export const FooterArticle = connect(
  null,
  null,
)(FooterArticleFn);
