const sourceData = (state = [], action) => {
  switch (action.type) {
    case 'LOAD_DATA':
      const { data } = action;

      return data;

    case 'SOURCE_UPDATE':
      const { data_type, field, value } = action;

      let newObj = {};
      newObj[field] = value;

      let branch_values = state[data_type];
      branch_values = Object.assign({}, branch_values, newObj);

      let branch = {};
      branch[data_type] = branch_values;

      //			console.log('data source update')

      return Object.assign({}, state, branch);

    default:
      return state;
  }
};

export default sourceData;
