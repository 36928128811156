// @flow

// $FlowFixMe - after flow update https://github.com/facebook/flow/pull/7149/
import React, { useState } from 'react';

import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';

import addNew from '../../actions/addNew';
import PcAppBar from '../shared/TopBar/PcAppBar';
import BreadCrumbs from '../shared/BreadCrumbs';

import withUserDataFetched from '../../core/withUserDataFetched';
import { withAccessSuperadmin } from '../../core/withAccess';

import PcTextField from '../shared/PcTextField.js';

const AffiliateGroupNew = ({ dispatchAdd }) => {
  const [name, setName] = useState('');

  const breadcrumbs = [
    {
      url: '/',
      name: 'home',
    },
    {
      url: '/affiliate',
      name: 'Affliliate home',
    },
    {
      url: '/affiliate/target_group/all',
      name: 'target groups',
    },
    {
      url: '/affiliate/target_group/new',
      name: 'new',
    },
  ];

  const handleChange = value => {
    setName(value);
  };

  const handleAdd = () => {
    dispatchAdd(name);
  };

  return (
    <>
      <PcAppBar title={'Add new affiliate widget'} no_drawer={true} />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
          <h1>{'Add Target Group'}</h1>
        </div>
        <div style={{ maxWidth: '500px', margin: 'auto' }}>
          <PcTextField
            name="name"
            value={name}
            fullWidth={false}
            floatingLabelText="name"
            handleChange={handleChange}
          />
          <FlatButton
            label="Add Group"
            primary={true}
            disabled={name ? false : true}
            onClick={handleAdd}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchAdd: name => {
      dispatch(
        addNew('affiliate_target_group', {
          name,
          new_url_after_insert: 'affiliate/target_group',
        }),
      );
    },
  };
};

export default withUserDataFetched(
  withAccessSuperadmin(connect(null, mapDispatchToProps)(AffiliateGroupNew)),
);
