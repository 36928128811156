import React from 'react';
import BodySplited from '../shared/BodySplited';
import PcCard from '../shared/PcCard';

const VenueCardBody = ({ data_type, data_id }) => (
  <PcCard cardTitle="Description" id="card_description">
    <BodySplited data_type={data_type} data_id={data_id} />
  </PcCard>
);

export default VenueCardBody;
