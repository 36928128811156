import React from 'react';
import PcAppBar from '../shared/TopBar/PcAppBar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isSuperAdmin } from '../../helpers/hasAccess';
import BreadCrumbs from '../shared/BreadCrumbs';
import FlatButton from 'material-ui/FlatButton';

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

const PcUserList = ({ cms_users }) => {
  if (!isSuperAdmin) {
    return null;
  }

  const breadcrumbs = [
    {
      url: '/',
      name: 'home',
    },
    {
      url: '/pc_user_list/1',
      name: 'User List',
    },
  ];

  return (
    <div>
      <PcAppBar title="User List" no_drawer={true} no_save={true} />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
          <br />
          <Link to="/pc_new_user/1">
            <FlatButton secondary={true}>Add new user</FlatButton>
          </Link>
        </div>

        <Table>
          <TableHeader displaySelectAll={false}>
            <TableRow>
              <TableHeaderColumn>Name</TableHeaderColumn>
              <TableHeaderColumn>Login</TableHeaderColumn>
              <TableHeaderColumn>Group</TableHeaderColumn>
              <TableHeaderColumn>Last Logged</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {cms_users.map(user => (
              <TableRow key={user.user_id}>
                <TableRowColumn>
                  <Link to={'/pc_user/' + user.user_id} className="the_link">
                    {user.name || '[no name]'}
                  </Link>
                </TableRowColumn>
                <TableRowColumn>{user.login}</TableRowColumn>
                <TableRowColumn>{user.group_name}</TableRowColumn>
                <TableRowColumn>{user.last_login}</TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  return {
    cms_users: data.cms_users,
  };
};

export default connect(mapStateToProps)(PcUserList);
