/* gallery */

import React, { Component } from 'react';
import Close from 'material-ui/svg-icons/navigation/close';
import ActionPanTool from 'material-ui/svg-icons/action/pan-tool';
import IconButton from 'material-ui/IconButton';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PcTextField from '../../shared/PcTextField';
import FileImage from './FileImage';
import { queueUpdate, queueDelete } from '../../../actions/actions';
import { static_url } from '../../../config/config';

const propTypes = {
  gallery: PropTypes.array.isRequired,
  only_first_active: PropTypes.bool.isRequired,
  handleImagesChange: PropTypes.func.isRequired,
};

const Thumb = ({ gallery_id }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={static_url + 'gallery/' + gallery_id + '.jpg'}>
    <img
      style={{ verticalAlign: 'middle', margin: '3px' }}
      src={static_url + 'gallery/' + gallery_id + 'm.jpg'}
      alt=""
      width="120"
    />
  </a>
);
const DragHandle = SortableHandle(() => (
  <div style={{ display: 'inline-block' }} className="pd">
    <ActionPanTool className="hand-drag" />
  </div>
));

const SortableItem = SortableElement(
  ({ is_active, item, handleDescChange, itemRemove, use_drag }) => {
    let style = {
      backgroundColor: 'rgba(255,255,255,0.8)',
      opacity: is_active ? 1 : 0.4,
    };

    return (
      <div style={style}>
        <Thumb gallery_id={item.gallery_id} />
        <div className="pd" style={{ display: 'inline-block' }}>
          <PcTextField
            style={{ width: '160px' }}
            name="description"
            value={item.description}
            fullWidth={false}
            handleChange={handleDescChange}
            floatingLabelText="description"
          />
        </div>

        {use_drag && <DragHandle />}
        {/* icon remove */}
        <IconButton onClick={itemRemove}>
          <Close />
        </IconButton>
      </div>
    );
  },
);

const SortableList = SortableContainer(
  ({ gallery, only_first_active, itemRemove, handleDescChange }) => {
    return (
      <div>
        {gallery.map((item, index) => (
          <SortableItem
            key={item.gallery_id}
            index={index}
            item={item}
            itemRemove={() => {
              itemRemove(index);
            }}
            handleDescChange={val => {
              handleDescChange(index, val);
            }}
            is_active={only_first_active && index ? false : true}
            use_drag={gallery.length > 1 ? true : false}
          />
        ))}
      </div>
    );
  },
);

class GalleryWithState extends Component {
  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  componentWillMount() {
    this.init(this.props);
  }

  init(props) {
    this.setState({
      gallery: props.gallery,
      ordering_data: '',
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      {
        gallery: arrayMove(this.state.gallery, oldIndex, newIndex),
      },
      () => {
        this.state.gallery.map((item, index) => {
          return this.props.dispatch(
            queueUpdate('gallery', item.gallery_id, 'ordering', index + 1),
          );
        });
      },
    );
  };

  removeItem = index => {
    const confirm = window.confirm('Removing gallery image. Are you sure?');

    if (!confirm) return;

    this.props.dispatch(
      queueDelete('gallery', this.state.gallery[index].gallery_id),
    );

    this.setState({
      gallery: update(this.state.gallery, { $splice: [[index, 1]] }),
    });
  };

  handleDescChange = (index, val) => {
    const item_updated = { ...this.state.gallery[index], description: val };

    this.setState(
      {
        gallery: update(this.state.gallery, {
          [index]: { $set: item_updated },
        }),
      },
      // level up
      () => {
        this.props.handleImagesChange({ gallery: [...this.state.gallery] });
        return this.props.dispatch(
          queueUpdate('gallery', item_updated.gallery_id, 'description', val),
        );
      },
    );
  };

  render() {
    return (
      <div>
        <div className="pd">
          <FileImage
            name="gallery"
            label="add gallery file "
            multiple={true}
            preview_size={80}
            info="Minimum: 400px width, 400px height"
            min_width={400}
            min_height={400}
          />
        </div>

        {this.state.gallery.length === 0 && (
          <div className="pd" style={{ color: 'maroon' }}>
            Define at least gallery image for listing purpose
          </div>
        )}
        {this.state.gallery.length !== 0 && (
          <div>
            <SortableList
              gallery={this.state.gallery}
              useDragHandle={true}
              only_first_active={this.props.only_first_active}
              onSortEnd={this.onSortEnd}
              itemRemove={this.removeItem}
              handleDescChange={this.handleDescChange}
            />
          </div>
        )}
      </div>
    );
  }
}

const Gallery = connect(null)(GalleryWithState);

Gallery.propTypes = propTypes;

export default Gallery;
