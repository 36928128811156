import React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

import { prompt_changed } from '../../config/config';

const PromptLeaveView = ({ isBlocking }) => (
  <Prompt
    when={isBlocking}
    message={location =>
      'Some changes might be not saved. Are you sure you want to leave?\nClick OK to discard unsaved data.\nClick Cancel to go back and save changes.'
    }
  />
);

const objEmpty = data => {
  return Object.keys(data).length ? false : true;
};

const mapStateToProps = state => {
  if (!prompt_changed) return { isBlocking: false };

  const { queue_update, queue_delete, queue_insert } = state.queue_modify;

  // The 'modify' field is added autamtically if detected in page data. To get known if anything was changed we have to remove it
  // used in page leave only!
  const update_without_modify = {};
  Object.keys(queue_update).map(key => {
    const { modified, ...fields } = queue_update[key];
    return Object.keys(fields).length > 0
      ? (update_without_modify[key] = fields)
      : null;
  });

  const isBlocking =
    !objEmpty(update_without_modify) ||
    !objEmpty(queue_delete) ||
    !objEmpty(queue_insert)
      ? true
      : false;

  return {
    isBlocking: isBlocking,
  };
};

export default connect(mapStateToProps)(PromptLeaveView);
