// @flow

import React from 'react';
import PcCard from '../shared/PcCard.js';
import { connect } from 'react-redux';
import { queueUpdate } from '../../actions/actions.js';
import TextField from 'material-ui/TextField';
import { debounce } from 'lodash';

const COUNTRY_IDS_SHOW_LOCAL = [18];

type LeadTypes = 'lead' | 'lead_local';

type LeadViewProps = {
  value: string | null,
  label: string,
  char_limit: number,
  handleChange: (value: string) => void,
};

const LeadView = ({
  value,
  label,
  char_limit,
  handleChange,
}: LeadViewProps) => {
  const counter_left = char_limit - (value || '').length;
  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <TextField
            name="any"
            value={value || ''}
            multiLine={true}
            fullWidth={true}
            onChange={(ev, value) => {
              handleChange(value);
            }}
            floatingLabelText={label}
          />
        </div>
      </div>
      <div>Chars: {(value || '').length}</div>
    </>
  );
};

type LeadProps = {
  lead: string | null,
  lead_local: string | null,
  venue_id: number,
  show_local: boolean,
  dispatch: any => void,
};

type LeadState = {
  lead: string | null,
  lead_local: string | null,
};

class Lead extends React.Component<LeadProps, LeadState> {
  modifyDebounced = debounce((key, value) => {
    this.props.dispatch(queueUpdate('venue', this.props.venue_id, key, value));
  }, 300);

  applyPropsToState = () => {
    const { lead, lead_local } = this.props;
    this.setState({ lead, lead_local });
  };

  componentDidMount = () => {
    this.applyPropsToState();
  };

  componentDidUpdate = ({ venue_id }: LeadProps) => {
    if (venue_id === this.props.venue_id) return;
    this.applyPropsToState();
  };

  handleChange = (key: LeadTypes, value) => {
    this.setState({ [key]: value });
    this.modifyDebounced(key, value);
  };

  render() {
    if (!this.state) return null;
    const { show_local } = this.props;
    const { lead, lead_local } = this.state;
    return (
      <PcCard cardTitle="Lead" id="card_lead">
        <LeadView
          value={lead}
          handleChange={value => this.handleChange('lead', value)}
          char_limit={140}
          label="Lead"
        />
        {show_local && (
          <LeadView
            value={lead_local}
            char_limit={165}
            handleChange={value => this.handleChange('lead_local', value)}
            label="Lead local language"
          />
        )}
      </PcCard>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { lead, lead_local, venue_id, country_id } = data.venue;
  return {
    lead,
    lead_local,
    venue_id,
    show_local: COUNTRY_IDS_SHOW_LOCAL.includes(country_id),
  };
};

export default connect(mapStateToProps)(Lead);
