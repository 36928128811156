import React, { Component } from 'react';
import { leloFetch } from '../helpers';
import { indicatorOn, indicatorOff } from '../actions/actions';
import { connect } from 'react-redux';
import PcAppBar from './shared/TopBar/PcAppBar';

import getConstants from '../actions/getConstants';

import SearchForm from './shared/TopBar/SearchForm';
import { Link } from 'react-router-dom';
import BreadCrumbs from './shared/BreadCrumbs';

class Search extends Component {
  constructor(props) {
    super(props);
    const { country_id, city_id, q } = props.match.params;
    this.state = {
      results: [],
      country_id,
      city_id,
      q,
      fetching: false,
    };
  }

  componentWillMount() {
    this.getSearchData(this.props.match.params.q);
    this.props.dispatch(getConstants());
    //this.getConstants()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.q === nextProps.match.params.q) return;
    this.getSearchData(nextProps.match.params.q);
  }

  getSearchData(q) {
    const { country_id, city_id } = this.props.match.params;
    const { dispatch } = this.props;

    this.setState({
      fetching: true,
    });

    dispatch(indicatorOn());

    const path = 'search_all/' + country_id + '/' + city_id + '/' + q;

    leloFetch(path).then(
      results => {
        dispatch(indicatorOff());
        this.setState({
          results,
          q,
          fetching: false,
        });
      },
      error => {
        dispatch(indicatorOff());
        if (error.message) alert(error.message);
      },
    );
  }

  renderItem({ name, subtitle, active, url }, key) {
    const inactive_style = active ? {} : { color: 'grey' };

    return (
      <div key={key} className="pd-h">
        <Link
          className="the_link ellipsis"
          to={'/' + url}
          style={inactive_style}>
          {key + 1}. {name}
        </Link>
        <div className="ellipsis">{subtitle}</div>
      </div>
    );
  }

  breadcrumbsData() {
    const { country, city } = this.props;
    if (!country) return;
    let breadcrumbs = [
      {
        url: '/',
        name: 'home',
      },
      {
        url: '/country/' + country.symbol + '/' + country.country_id,
        name: country.name,
      },
    ];
    if (city) {
      breadcrumbs.push({
        url: '/city/' + city.symbol + '/' + city.city_id,
        name: city.name,
      });
    }
    return breadcrumbs;
  }

  render() {
    const { results, q, fetching } = this.state;
    const label = this.props.label || '...';
    const { country, city } = this.props;
    const breadcrumbs = this.breadcrumbsData();

    return (
      <div>
        <PcAppBar
          title={'Search in ' + label}
          no_drawer={true}
          no_save={true}
        />

        <div className="pd pc-content-wide">
          {breadcrumbs && <BreadCrumbs link_data={breadcrumbs} />}

          <div style={{ margin: 'auto', maxWidth: '400px' }}>
            <div className="pd" />

            {country && (
              <SearchForm
                label={label}
                country_id={country.country_id}
                city_id={city ? city.city_id : null}
              />
            )}

            <div className="pd-h" style={{ fontSize: '2em' }}>
              Search for <i>{q}</i>
            </div>

            {results && (
              <div>
                {results.map((item, i) => {
                  return this.renderItem(item, i);
                })}
              </div>
            )}

            {results.length === 0 && !fetching && (
              <div className="pd">
                <span>Nothing found</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { constants } = state;
  const city_id = parseInt(ownProps.match.params.city_id, 10);
  const country_id = parseInt(ownProps.match.params.country_id, 10);

  let city, country, label;

  if (constants.city_list && constants.city_list.length > 0) {
    city = constants.city_list.find(item => item.city_id === city_id);
    country = constants.country_list.find(
      item => item.country_id === country_id,
    );
    label = city ? city.name : country.name;
  }
  return {
    label,
    country,
    city,
  };
};

export default connect(mapStateToProps)(Search);
