import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const CommentsLink = ({ link, count_all, count_pending }) => {
  const text_all = count_all === 1 ? 'comment' : 'comments';
  const text_pending = count_pending === 1 ? 'comment' : 'comments';

  if (count_all === 0) {
    return ' | No comments';
  }

  if (count_pending === 0) {
    return (
      <span>
        {' | '}
        <Link to={link} className="the_link">
          {count_all + ' ' + text_all}
        </Link>
      </span>
    );
  }

  if (count_all === count_pending) {
    return (
      <Link to={link} className="the_link">
        {' | '}
        <strong>{count_pending + ' pending ' + text_pending}</strong>
      </Link>
    );
  } else {
    return (
      <Link to={link} className="the_link">
        {' | ' + count_all + ' ' + text_all + ', '}
        <strong>{count_pending + ' pending'}</strong>
      </Link>
    );
  }
};

const mapStateToProps = ({ data }) => {
  const { page_type, venue, feature, comments_count } = data;

  const { count_all, count_pending } = comments_count;

  let link = '';

  if (page_type === 'venue')
    link = '/venue_comments/' + venue.symbol + '/' + venue.venue_id;
  else if (page_type === 'feature')
    link = '/feature_comments/' + feature.symbol + '/' + feature.feature_id;
  else console.error('unsuported page_type in Comments link');

  return {
    link,
    count_all,
    count_pending,
  };
};

export default connect(mapStateToProps)(CommentsLink);
