/**
 * @flow
 */

import React from 'react';
import { connect } from 'react-redux';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { Link } from 'react-router-dom';

type feature_type = {
  name: string,
  symbol: string,
  feature_type_id: number,
  ordering: ?number,
};

const FeatureTypeSelect = ({
  feature_type_id,
  feature_type_list,
}: {
  feature_type_id: number,
  feature_type_list: Array<feature_type>,
}) => {
  return (
    <SelectField
      value={feature_type_id}
      floatingLabelText="Feature category"
      autoWidth={true}
      style={{ maxWidth: '100%' }}>
      {feature_type_list.length === 0 && (
        <div className="pd text-center">No categories yet</div>
      )}
      {feature_type_list.length > 0 &&
        feature_type_list.map(({ symbol, feature_type_id, name, ordering }) => {
          const link = '/feature_type/' + symbol + '/' + feature_type_id;
          return (
            <MenuItem
              primaryText={name}
              secondaryText={ordering}
              containerElement={<Link to={link} />}
              key={feature_type_id}
              value={feature_type_id}
            />
          );
        })}
    </SelectField>
  );
};

const mapStateToProps = ({ data }) => {
  const { feature_type, feature_type_list } = data;

  const feature_type_id = feature_type ? feature_type.feature_type_id : null;

  return {
    feature_type_list: feature_type_list || [],
    feature_type_id,
    key: 'feature_type' + (feature_type_id || ''),
  };
};

export default connect(mapStateToProps)(FeatureTypeSelect);
