export const snackSaveSuccess = () => {
  return dispatch => dispatch(snackSuccess('Item saved'));
};

const snackSuccessOn = message => {
  return {
    type: 'SNACK_SUCCESS_ON',
    message,
  };
};

const snackSuccessOff = () => {
  return {
    type: 'SNACK_SUCCESS_OFF',
  };
};

export const snackSuccess = (message, duration = 2000) => {
  return dispatch => {
    setTimeout(() => dispatch(snackSuccessOff()), duration);
    return dispatch(snackSuccessOn(message));
  };
};

const snackErrorOn = message => {
  return {
    type: 'SNACK_ERROR_ON',
    message,
  };
};

const snackErrorOff = () => {
  return {
    type: 'SNACK_ERROR_OFF',
  };
};

export const snackError = (message, duration = 5000) => {
  return dispatch => {
    setTimeout(() => dispatch(snackErrorOff()), duration);
    return dispatch(snackErrorOn(message));
  };
};
