// @flow
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { leloFetch } from '../../helpers';
import PcAppBar from '../shared/TopBar/PcAppBar';
import BreadCrumbs from '../shared/BreadCrumbs';
import { TFooterArticle } from './TFooterArticle';

export const breadcrumbs = [
  {
    url: '/',
    name: 'home',
  },
  {
    url: '/footer_article',
    name: 'Footer articles',
  },
];

export const FooterArticleList = () => {
  const [articles, setArticles] = useState<TFooterArticle[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await leloFetch('footerArticles');
      setArticles(data);
    };
    fetchData();
  }, []);

  if (!articles) return null;

  return (
    <>
      <PcAppBar title={'Footer articles'} no_drawer={true} no_save={true} />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
        </div>
        <div className="pd">
          {articles.map(a => (
            <div className="pd" key={a.static_id}>
              <Link to={'footer_article/' + a.static_id} className="the_link">
                {a.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
