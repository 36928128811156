export const env_local =
  window.location.hostname === 'localhost' ? true : false;

//export const api_url		= (env_local) ? 'https://pc-api.inyourpocket.com/html/' : 'https://newpc.inyourpocket.com/pc-api/html/'
export const api_url = env_local
  ? 'http://localhost:9000/'
  : 'https://newpc.inyourpocket.com/pc-api/html/';

//export const api_url 		= 'http://localhost:8000/pc-api/html/'

export const pc_url = env_local
  ? 'http://localhost:3000/'
  : 'https://newpc.inyourpocket.com/';

export const www_url = 'https://www.inyourpocket.com/'; // used by pdf

export const static_url = 'https://s.inyourpocket.com/';

export const test_url = 'https://test.inyourpocket.com/';

export const prompt_changed = env_local ? false : true;
//export const prompt_changed = true

export const is_debug = env_local ? true : false;

export const city_colors = [
  '#a5b739',
  '#f8971d',
  '#16bdc5',
  '#53ace0',
  '#ef4623',
  '#7f4994',
  '#458e34',
  '#c69b50',
  '#00597c',
  '#007c3b',
  '#00a8d5',
  '#f0533f',
  '#842b91',
  '#b4c92b',
  '#e9e611',
  '#c41e26',
  '#008c78',
  '#a50043',
  '#5e6167',
  '#3164af',
  '#1da1dc',
  '#20a0af',
  '#ed1c24',
  '#0aa34a',
  '#2d449b',
  '#5bba47',
  '#859d4b',
  '#a50575',
  '#847297',
  '#1d4592',
  '#9c213c',
];

export const state_colors = {
  6: 'black',
  2: 'blue',
  4: 'purple',
  3: '#aaaa00',
  5: 'red',
  1: 'green',
};

export const state_colors_old = {
  1: 'black',
  2: 'blue',
  3: '#4dbc4d',
  4: 'purple',
  5: '#ff0000',
  6: '#aaaa00',
};
