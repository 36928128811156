import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { connect } from 'react-redux';
import PcTextField from '../PcTextField';
import addNew from '../../../actions/addNew';
import { RowFlex } from '../Grid';
import getCityStructureData from 'selectors/getCityStructureData';

class AddNewVenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      category_id: props.category_id || 0,
      chapter_id: props.chapter_id || 0,
    };
  }

  componentDidMount() {
    window.setTimeout(
      function () {
        const ref = this.refs['name'];
        if (ref) ref.focusInput();
      }.bind(this),
      200,
    );
  }

  handleNameChange = name => this.setState({ name });

  handleChangeChapter = (e, k, p) => {
    this.setState({
      chapter_id: p,
      category_id: 0,
    });
  };

  handleChangeCategory = (e, k, p) => {
    this.setState({ category_id: p });
  };

  handleAddNewClick = () => {
    const { name, category_id, chapter_id } = this.state;
    const { country_id, city_id } = this.props;
    this.props.handleCloseModal();
    this.props.dispatch(
      addNew('venue', { name, category_id, country_id, city_id, chapter_id }),
    );
  };

  render() {
    const category_list = this.props.category_list_all.filter(
      el => el.chapter_id === this.state.chapter_id,
    );

    return (
      <Dialog
        title="Add new venue"
        actions={[
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={this.props.handleCloseModal}
          />,
          <FlatButton
            label="Add"
            primary={true}
            keyboardFocused={true}
            onClick={this.handleAddNewClick}
            disabled={!this.state.name || !this.state.category_id}
          />,
        ]}
        modal={false}
        open={true}
        onRequestClose={this.props.handleCloseModal}>
        <RowFlex>
          <PcTextField
            handleChange={this.handleNameChange}
            floatingLabelText="name"
            value={this.state.name}
            ref="name"
          />
        </RowFlex>

        <RowFlex>
          <SelectField
            value={this.state.chapter_id}
            floatingLabelText="chapter"
            autoWidth={true}
            onChange={this.handleChangeChapter}>
            <MenuItem value={0} primaryText="select chappter" />
            {this.props.chapter_list.map(el => {
              return (
                <MenuItem
                  value={el.chapter_id}
                  primaryText={el.name}
                  key={el.chapter_id}
                />
              );
            })}
          </SelectField>

          <SelectField
            value={this.state.category_id}
            floatingLabelText="category"
            autoWidth={true}
            onChange={this.handleChangeCategory}>
            <MenuItem value={0} primaryText="select category" />
            {category_list.map(el => {
              const name = el.parent_id ? '- ' + el.name : el.name;
              return (
                <MenuItem
                  value={el.category_id}
                  primaryText={name}
                  key={el.category_id}
                />
              );
            })}
          </SelectField>
        </RowFlex>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ data_city, data }) => {
  const city_id = data.city.city_id;
  const chapter_id = data.chapter ? data.chapter.chapter_id : null;
  const category_id = data.category ? data.category.category_id : null;

  const { category_list, chapter_list } = getCityStructureData({
    data_city,
    city_id,
  });

  return {
    category_list_all: category_list,
    country_id: data.country.country_id,
    city_id: data.city.city_id,
    chapter_list,
    chapter_id,
    category_id,
  };
};

export default connect(mapStateToProps)(AddNewVenue);
