import React, { Component } from 'react';
import FlatButton from 'material-ui/FlatButton';
import { connect } from 'react-redux';
import FileImage from './FileImage';
import { static_url } from '../../../config/config';
import { RowFlexCenter } from '../../shared/Grid';
import { queueUpdate } from '../../../actions/actions';
import { fetchAfterSave } from '../../../actions/pageControl';

const BgMobileView = ({ img_url, removed_clicked, remove }) => (
  <div>
    {img_url && !removed_clicked && (
      <RowFlexCenter>
        <img
          src={img_url}
          style={{ maxWidth: '100px', height: 'auto' }}
          alt=""
        />
        <FlatButton label="Remove Image" onClick={remove} />
      </RowFlexCenter>
    )}
    {img_url && removed_clicked && (
      <div className="pd-h">Image will be removed (click save) </div>
    )}
    {!img_url && (
      <div className="pd-h">
        <FileImage
          name="mobile_background"
          label="select file"
          preview_size={80}
          info="Must be portrait orientation and minimum 500px height (1000px recomended)"
          min_height={500}
          orientation="portrait"
        />
      </div>
    )}
  </div>
);

class BgMobileState extends Component {
  constructor(props) {
    super(props);
    this.state = { removed_clicked: false };
  }

  remove = () => {
    const { page_type, page_id, dispatch } = this.props;

    const confirm = window.confirm('Removing mobile background. Are you sure?');
    if (!confirm) return;
    this.setState({ removed_clicked: true });

    const custom_key = page_type === 'events_chapter' ? 'city_id' : null;

    dispatch(
      queueUpdate(page_type, page_id, 'mobile_background', null, custom_key),
    );
    dispatch(fetchAfterSave(true));
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.data_key === nextProps.data_key) return;
    this.setState({
      removed_clicked: false,
    });
  }

  render() {
    return (
      <BgMobileView
        img_url={this.props.img_url}
        removed_clicked={this.state.removed_clicked}
        remove={this.remove}
      />
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { page_type, page_id, data_key } = data;
  const db_value = data[page_type].mobile_background;

  const img_url = db_value
    ? static_url +
      'img/mobile_background/' +
      (page_type === 'events_chapter'
        ? 'city_events/' + data.city.city_id
        : page_type + '/' + page_id) +
      '/300x500.jpg?' +
      data_key // data_key will help us prevent browser hash
    : null;

  return {
    img_url,
    page_type,
    page_id,
    data_key,
  };
};

export default connect(mapStateToProps)(BgMobileState);
