import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import PropTypes from 'prop-types';

const propTypes = {
  dow: PropTypes.string.isRequired,
  dow_full: PropTypes.string.isRequired /*label*/,
  handleDowChange: PropTypes.func,
  value: PropTypes.string,
};

class OpenHoursDay extends Component {
  handleChangeText = event => {
    const value = event.target.value;
    this.props.handleDowChange(value);
  };

  handleChangeBox = event => {
    const checked = event.target.checked;
    const value = checked ? 'Closed' : '';
    this.props.handleDowChange(value);
    if (!checked) this.focus();
  };

  focus = () => this.textInput.focus();

  render() {
    const style8inRow = { width: '12.5%' };

    const { dow_full, value } = this.props;
    const checked = value === 'Closed' ? true : false;
    const value_printed = value === 'Closed' ? '' : value || '';

    return (
      <div className="col-sm-2" style={style8inRow}>
        <div>
          <TextField
            ref={input => {
              this.textInput = input;
            }}
            floatingLabelText={dow_full}
            value={value_printed}
            onChange={this.handleChangeText}
            fullWidth={true}
            disabled={checked}
          />
        </div>

        <div style={{ marginTop: '10px' }}>
          <label>
            <input
              type="checkbox"
              onChange={this.handleChangeBox}
              checked={checked}
            />
            Closed
          </label>
        </div>
      </div>
    );
  }
}

OpenHoursDay.propTypes = propTypes;

export default OpenHoursDay;
