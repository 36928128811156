import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import PcTextField from '../PcTextField';
import addNew from '../../../actions/addNew';

class AddNewCountry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: '' };
  }

  componentDidMount() {
    window.setTimeout(
      function () {
        const ref = this.refs['name'];
        if (ref) ref.focusInput();
      }.bind(this),
      200,
    );
  }

  handleNameChange = name => this.setState({ name });

  handleAddNewClick = () => {
    const { name } = this.state;
    this.props.handleCloseModal();
    this.props.dispatch(addNew('country', { name }));
  };

  render() {
    return (
      <Dialog
        title="Add new Country"
        actions={[
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={this.props.handleCloseModal}
          />,
          <FlatButton
            label="Add"
            primary={true}
            keyboardFocused={true}
            onClick={this.handleAddNewClick}
            disabled={!this.state.name}
          />,
        ]}
        modal={false}
        open={true}
        onRequestClose={this.props.handleCloseModal}>
        <PcTextField
          handleChange={this.handleNameChange}
          floatingLabelText="name"
          value={this.state.name}
          ref="name"
        />
      </Dialog>
    );
  }
}

export default AddNewCountry;
