import React, { Component } from 'react';
import { connect } from 'react-redux';
import PcCard from '../shared/PcCard';
import PcSearch from '../shared/PcSearch';
import { Row, CellHalf } from '../shared/Grid';

import { queueUpdate } from '../../actions/actions.js';
import EditableList from '../shared/EditableList';

class EventsHomePicksWithState extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.addItem = this.addItem.bind(this);

    this.state = {
      editors_picks: props.editors_picks || [],
    };
  }

  componentWillReceiveProps({ city_id, editors_picks }) {
    if (city_id === this.props.city_id) return;
    this.setState({
      editors_picks: editors_picks || [],
    });
  }

  addItem(data) {
    this.setState(
      {
        editors_picks: this.state.editors_picks.concat([data]),
      },
      () => this.props.modifyItem(this.props.city_id, this.state.editors_picks),
    );
  }

  handleChange(items) {
    this.setState(
      {
        editors_picks: items,
      },
      () => this.props.modifyItem(this.props.city_id, items),
    );
  }

  render() {
    const { handleChange, addItem } = this;
    const { city_id, city_name } = this.props;
    const { editors_picks } = this.state;

    const props = { editors_picks, city_id, city_name, handleChange, addItem };

    return <EventsHomePicksView {...props} />;
  }
}

const EventsHomePicksView = ({
  editors_picks,
  city_id,
  city_name,
  handleChange,
  addItem,
}) => {
  return (
    <PcCard cardTitle="Editor's picks" id="card_picks">
      <Row>
        <CellHalf>
          <PcSearch
            narrow_field_key="city_id"
            narrow_field_id={city_id}
            table="event"
            search_id={'city' + city_id}
            onResultClick={addItem}
            tip={'events in ' + city_name}
          />
          <EditableList
            items={editors_picks}
            is_sortable={true}
            handleChange={handleChange}
          />
        </CellHalf>
      </Row>
    </PcCard>
  );
};

const mapStateToProps = ({ data }) => {
  const { city, events_chapter } = data;

  return {
    city_id: city.city_id,
    city_name: city.name,
    editors_picks: events_chapter.event_picks || [],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    modifyItem: (city_id, data) => {
      const ids = data.map(el => el.event_id);
      dispatch(
        queueUpdate('events_chapter', city_id, 'picks_ids', ids, 'city_id'),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventsHomePicksWithState);
