import EditorState from '../shared/EditorState.js';
import { queueUpdate } from '../../actions/actions.js';
import { connect } from 'react-redux';

const mapStateToProps = ({ data }) => {
  const { feature } = data;
  const { state, feature_id } = feature;
  return {
    data_id: feature_id,
    dom_key: 'feature' + feature_id,
    value: state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleChange: (feature_id, value) => {
      dispatch(queueUpdate('feature', feature_id, 'state', value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditorState);
