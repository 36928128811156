import React from 'react';
import { connect } from 'react-redux';
import CitySelect from '../shared/Drawer/CitySelect';
import ChapterSelect from '../shared/Drawer/ChapterSelect';
import DistrictSelect from '../shared/Drawer/DistrictSelect';
import VenueList from '../shared/Drawer/VenueList';

import BreadCrumbs from '../shared/BreadCrumbs';
import EditedBy from '../shared/EditedBy';
import RemoveArchive from '../shared/RemoveArchive';

import PcCard from '../shared/PcCard';
import PcTextField from '../shared/PcTextField.js';
import PcAppBar from '../shared/TopBar/PcAppBar';
import PcDrawer from '../shared/Drawer/PcDrawer';

import CardImages from '../shared/Images/CardImages';
import PcHtmlEditor from '../shared/PcHtmlEditor';

import { modifyWrapper } from '../../core/ModifyWrapper';
import { RowFlex } from '../shared/Grid';

const MEditorText = modifyWrapper(PcHtmlEditor, 'district');
const MTextField = modifyWrapper(PcTextField, 'district');

const District = ({ name, city_id, district_id }) => {
  document.title = name;
  return (
    <div>
      <PcAppBar title={name} />
      <PcDrawer>
        <CitySelect city_id={city_id} />
        <ChapterSelect />
        <DistrictSelect city_id={city_id} district_id={district_id} />
        <VenueList />
      </PcDrawer>

      <div className="pd pc-content">
        <div className="top-line">
          <BreadCrumbs />
          <EditedBy />
        </div>

        <PcCard>
          <RowFlex>
            <MTextField
              name="name"
              floatingLabelText="name"
              fullWidth={false}
            />
            <MTextField
              name="symbol"
              floatingLabelText="symbol"
              fullWidth={false}
            />
            <MTextField
              name="ordering"
              floatingLabelText="ordering"
              type="number"
              fullWidth={false}
              style={{ width: '100px' }}
            />
          </RowFlex>

          <h2>Intro text</h2>
          <MEditorText name="body" dom_key={'district' + district_id} />
        </PcCard>

        <CardImages data_type="district" data_id={district_id} />

        <RemoveArchive />
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { district } = data;

  return {
    district_id: district.district_id,
    name: district.name,
    city_id: district.city_id,
  };
};

export default connect(mapStateToProps)(District);
