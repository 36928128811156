/* video */

import React, { Component } from 'react';
//import Close from 'material-ui/svg-icons/navigation/close';
import PcTextField from '../PcTextField';
import FlatButton from 'material-ui/FlatButton';
import { api_url } from '../../../config/config';
import { connect } from 'react-redux';
import { queueUpdate } from '../../../actions/actions.js';

const VideoForm = ({
  handleChange,
  video_link,
  handleImportClick,
  link_error,
}) => (
  <div style={{ overflow: 'auto' }}>
    <PcTextField
      floatingLabelText="Video Link"
      type="url"
      rowsMax={3}
      handleChange={handleChange}
      value={video_link}
      fullWidth={true}
      hintText="paste video link & click IMPORT VIDEO"
    />

    <FlatButton
      label="Import video"
      primary={true}
      onClick={handleImportClick}
      style={{ float: 'right' }}
      disabled={video_link ? false : true}
    />

    {link_error && (
      <p className="pd" style={{ color: 'maroon' }}>
        {link_error}
        <br />
        The link must point into single video one of: Youtube, Vimeo. No
        channels or playlists alowed.
      </p>
    )}
  </div>
);

const VideoPreview = props => (
  <div>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={props.video_link}
      style={{
        display: 'block',
        width: '100%',
        paddingTop: '46.25%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: "url('" + props.thumb + "')",
      }}>
      {/*<img src={props.thumb} style={{maxWidth:"50%"}} alt=""/>*/}
    </a>

    <div className="pd-h">{props.title}</div>

    <div style={{ overflow: 'auto' }}>
      <FlatButton
        label="Remove Video"
        className="pull-right"
        onClick={props.handleRemove}
      />
    </div>
  </div>
);

class VideoWithState extends Component {
  init(props) {
    this.setState({
      video_json: props.video_json,
      video_link: props.video_link || '',
      link_error: null,
    });
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  _handleChange = value => {
    this.setState({
      video_link: value,
    });
  };

  _handleImportClick = () => {
    if (!this.state.video_link) {
      return;
    }

    const url = api_url + 'video?url=' + this.state.video_link;

    fetch(url)
      .then(r => r.json())
      .then(data => {
        if (data.error) {
          this.setState({
            link_error: data.error,
          });
        } else {
          this.setState(
            {
              video_json: data,
              link_error: null,
            },
            () => {
              const { video_json, video_link } = this.state;
              this.props.modiffyItem(video_json, video_link);
              this.props.handleImagesChange({
                video_json,
                video_link,
              });
            },
          );
        }
      });
  };

  _handleRemove = () => {
    const data = {
      video_json: null,
      video_link: null,
    };
    // local
    this.setState(data);

    // store
    this.props.modiffyItem(null, null);

    //levelup
    this.props.handleImagesChange(data);
  };

  render() {
    const { video_json, video_link, link_error } = this.state;
    const { _handleRemove, _handleChange, _handleImportClick } = this;

    return (
      <div>
        {video_json && (
          <VideoPreview
            thumb={video_json.thumb}
            title={video_json.title}
            video_link={video_link}
            handleRemove={_handleRemove}
          />
        )}

        {!this.state.video_json && (
          <VideoForm
            handleChange={_handleChange}
            handleImportClick={_handleImportClick}
            video_link={video_link}
            link_error={link_error}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps, handleImagesChange) => {
  const { data_type, data_id } = ownProps;

  return {
    modiffyItem: (video_json, video_link) => {
      dispatch(queueUpdate(data_type, data_id, 'video_json', video_json));
      // for old pc compatibile only
      dispatch(queueUpdate(data_type, data_id, 'video_data', video_json));
      dispatch(queueUpdate(data_type, data_id, 'video_link', video_link));
    },
  };
};

export default connect(null, mapDispatchToProps)(VideoWithState);
