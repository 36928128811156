import React from 'react';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import unarchive from '../../../actions/unarchive';
import { hasAccessLevel } from '../../../helpers/hasAccess';

const UnArchiveButton = ({ page_type, page_id, dispatch }) => {
  const button_style = { color: 'white' };

  const wrapper_style = {
    verticalAlign: '30%',
    display: 'inline-block',
  };

  if (!hasAccessLevel(2, page_type)) return null;

  return (
    <div style={wrapper_style}>
      <FlatButton
        style={button_style}
        onClick={() => dispatch(unarchive(page_type, page_id))}
        label="Unarchive"
      />
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { page_type, page_id } = data;

  return {
    page_type,
    page_id,
  };
};

export default connect(mapStateToProps)(UnArchiveButton);
