import React, { Component } from 'react';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import PcCard from '../shared/PcCard';
import update from 'immutability-helper';
import PcTextField from './PcTextField';
import { leloFetch } from 'helpers';
import { debounce } from 'lodash';
import { indicatorOn, indicatorOff, queueUpdate } from 'actions/actions';

class OfferAddContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
    };
  }

  _handleUrlChange = val => {
    this.setState({
      url: val,
    });
  };

  _handleImportClickDebounced = debounce(() => {
    this._handleImportClick();
  }, 2000);

  //https://shop.inyourpocket.com/collections/popular/products/an-illustrated-history-of-slavic-misery
  _handleImportClick = async () => {
    const url = this.state.url;
    if (!url) return;
    this.props.dispatch(indicatorOn());
    try {
      const data = await leloFetch(
        'fetch_offer?url=' + encodeURIComponent(this.state.url),
      );
      if (data.error) {
        this.props.dispatch(indicatorOff());
        return alert('Could not fetch this url');
      }
      this.props.handleItemAdd(data);
      this.setState({
        url: '',
      });
      this.props.dispatch(indicatorOff());
    } catch (error) {
      this.props.dispatch(indicatorOff());
      alert('server response error');
      console.error(error);
    }
  };

  render() {
    return (
      <div>
        <PcTextField
          fullWidth={false}
          floatingLabelText="offer url"
          handleChange={this._handleUrlChange}
          value={this.state.url}
        />
        <div style={{ display: 'inline-block' }} className="pd-w" />
        <RaisedButton
          label="import offer"
          onClick={this._handleImportClickDebounced}
        />
      </div>
    );
  }
}

const OfferAdd = connect()(OfferAddContainer);

const OfferList = ({ data, handleRemove }) => {
  const cssBg = url => 'url(' + url + ')';

  const img_styles = {
    width: 100,
    height: 100,
    backgroundSize: 'cover',
  };

  return (
    <div>
      {data.map((item, index) => (
        <div className="flex-wrapper" key={index}>
          <div
            style={{ ...img_styles, backgroundImage: cssBg(item.image) }}
            className="pd"
          />
          <div style={{ width: 200 }}>
            <div className="pd">
              <a className="the_link" href={item.url} target="blank">
                {item.title}
              </a>
            </div>
            <div className="pd">{item.price}</div>
          </div>
          <div>
            <FlatButton
              label="remove"
              secondary={true}
              onClick={() => handleRemove(index)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

class CardOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.offer_json,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data_key === this.props.data_key) return;
    this.setState({
      data: nextProps.offer_json,
    });
  }

  _handleRemove = index => {
    this.setState(
      prevState => ({
        data: update(prevState.data, { $splice: [[index, 1]] }),
      }),
      () => this._handleModiffy(),
    );
  };

  _handleItemAdd = item => {
    this.setState(
      prevState => ({
        data: [item, ...prevState.data],
      }),
      () => this._handleModiffy(),
    );
  };

  _handleModiffy = () => {
    const { page_type, page_id, dispatch } = this.props;
    const { data } = this.state;
    dispatch(queueUpdate(page_type, page_id, 'offer_json', data));
  };

  render() {
    return (
      <PcCard cardTitle="Offers" id="card_offer">
        <div className="row">
          <div className="col-md-6">
            <OfferAdd handleItemAdd={this._handleItemAdd} />
          </div>
          <div className="col-md-6">
            <OfferList
              data={this.state.data}
              handleRemove={this._handleRemove}
            />
          </div>
        </div>
      </PcCard>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { page_type, page_id, data_key } = data;
  return {
    offer_json: data[page_type].offer_json || [],
    page_type,
    page_id,
    data_key,
  };
};

export default connect(mapStateToProps)(CardOffer);
