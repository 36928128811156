// @flow

import { combineReducers } from 'redux';
import sourceData from './sourceData';
import formErrors from './formErrors';
import drawer from './drawer';
import loadConstants from './loadConstants';
import indicator from './indicator';
import cityData from './cityData';
import customPostData from './customPostData';

import { forceFetch, forceFetchQueue } from './page_control/forceFetch';
import notLoggedLinkPreserve from './page_control/notLoggedLinkPreserve';
import apiFetchError from './page_control/apiFetchError';

import { snackSuccess, snackError } from './snack';
import userData from './userData';

import queueUpdate from './modify/queueUpdate';
import queueDelete from './modify/queueDelete';

const mainReducer = combineReducers({
  data: sourceData,
  data_city: cityData,
  form_errors: formErrors,
  drawer_open: drawer,
  constants: loadConstants,
  page_control: combineReducers({
    force_fetch: forceFetch,
    force_fetch_after_save: forceFetchQueue,
    not_logged_link_preserve: notLoggedLinkPreserve,
    api_fetch_error: apiFetchError,
  }),
  indicator,
  user_data: userData,
  queue_modify: combineReducers({
    queue_update: queueUpdate,
    queue_delete: queueDelete,
    queue_insert: () => {
      return {};
    },
    //insert
  }),
  custom_post: customPostData,
  snack: combineReducers({
    success: snackSuccess,
    error: snackError,
  }),
});

export default mainReducer;
