// @flow

// $FlowFixMe - after flow update https://github.com/facebook/flow/pull/7149/
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

import type { TAffiliateWidgetGroup } from './types';

import PcAppBar from '../shared/TopBar/PcAppBar';
import BreadCrumbs from '../shared/BreadCrumbs';

import withUserDataFetched from '../../core/withUserDataFetched';
import withDataFromUrl from '../../core/withDataFromUrl';
import { withAccessSuperadmin } from '../../core/withAccess';

type TProps = {
  target_group_list: Array<TAffiliateWidgetGroup>,
};

const GroupList = ({ target_group_list }: TProps) => {
  const [showing_archived, setShowArchived] = useState(false);

  const toggleShowArchived = new_value => {
    window.scrollTo(0, 0);
    setShowArchived(new_value);
  };

  const breadcrumbs = [
    {
      url: '/',
      name: 'home',
    },
    {
      url: '/affiliate',
      name: 'Affliliate home',
    },
    {
      url: '/affiliate/target_group/all',
      name: 'target groups',
    },
  ];

  const list = target_group_list.filter(
    (af: TAffiliateWidgetGroup) =>
      (!showing_archived && !af.archived) || (showing_archived && af.archived),
  );

  const count_archived = target_group_list.filter(af => af.archived).length;

  return (
    <>
      <PcAppBar title={'Affiliate Target Groups '} no_drawer={true} />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
        </div>

        <div style={{ maxWidth: '700px', margin: 'auto' }}>
          <br />
          <br />
          {!showing_archived && (
            <Link to={'/affiliate/target_group/new'}>
              <RaisedButton primary={true} label="Add New Target Group" />
            </Link>
          )}

          <Table>
            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableHeaderColumn style={{ width: '80px' }}>
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn>Name</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {list.map(target_group => {
                const { affiliate_target_group_id, name } = target_group;
                return (
                  <TableRow key={affiliate_target_group_id}>
                    <TableRowColumn style={{ width: '80px' }}>
                      {affiliate_target_group_id}
                    </TableRowColumn>
                    <TableRowColumn>
                      <Link
                        className="the_link"
                        to={
                          '/affiliate/target_group/' + affiliate_target_group_id
                        }>
                        {name}
                      </Link>
                    </TableRowColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <br />
          <br />
          {list.length === 0 && (
            <div className="pd" style={{ textAlign: 'center' }}>
              <br />
              Nothing to show yet
            </div>
          )}
          {!showing_archived && count_archived > 0 && (
            <FlatButton
              label={'Show Archived (' + count_archived + ')'}
              onClick={() => toggleShowArchived(true)}
            />
          )}
          {showing_archived && (
            <FlatButton
              label={'Show Unarchived'}
              onClick={() => toggleShowArchived(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ data }, ownProps) => {
  return {
    target_group_list: data.target_group_list || [],
  };
};

const AffiliateTargetGroupAll = withUserDataFetched(
  withDataFromUrl(match => {
    return {
      page_type: 'affiliate-target-group',
      page_id: 0,
      api_call: 'page/affiliate/target_group/all',
    };
  }, withAccessSuperadmin(connect(mapStateToProps)(GroupList))),
);

export default AffiliateTargetGroupAll;
