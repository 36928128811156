import React, { Component } from 'react';

import PcCard from '../shared/PcCard';
import PcAppBar from '../shared/TopBar/PcAppBar';
import { connect } from 'react-redux';
import NotFound from '../NotFound';
import PcTextField from '../shared/PcTextField';
import PcSelect from '../shared/PcSelect';
import PcCheckbox from '../shared/PcCheckbox';
import { modifyWrapper } from '../../core/ModifyWrapper';
import { RowFlexTop, RowFlex } from '../shared/Grid';
import { queueUpdate } from '../../actions/actions.js';
import update from 'immutability-helper';
import { cssActive } from '../../helpers';
import { isSuperAdmin } from '../../helpers/hasAccess';
import BreadCrumbs from '../shared/BreadCrumbs';
import getUserCitiesAccess from 'selectors/getUserCitiesAccess';

const MTextField = modifyWrapper(PcTextField, 'cms_users', {
  custom_key: 'user_id',
});
const MSelect = modifyWrapper(PcSelect, 'cms_users', { custom_key: 'user_id' });
const MCheckBox = modifyWrapper(PcCheckbox, 'cms_users', {
  custom_key: 'user_id',
});

// !!!!cms_users = cms_user (one). Table name mismatch

class CountryBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: props.cities,
      show_inactive: false,
    };
  }

  handleChange = (id, is_checked) => {
    const value = is_checked ? 1 : null;
    this.props.dispatch(queueUpdate('cms_users_cities', id, 'active', value));
    const index = this.state.cities.findIndex(el => el.id === id);
    this.setState({
      cities: update(this.state.cities, {
        [index]: { $merge: { selected: value } },
      }),
    });
  };

  changeAll = is_checked => {
    const new_cites = [];
    const value = is_checked ? 1 : null;
    this.state.cities.map((el, index) => {
      if (el.selected !== value) {
        this.props.dispatch(
          queueUpdate('cms_users_cities', el.id, 'active', value),
        );
      }
      const new_city = update(this.state.cities[index], {
        $merge: { selected: value },
      });
      return new_cites.push(new_city);
    });
    this.setState({ cities: new_cites });
  };

  selectAll = () => this.changeAll(true);

  deSelectAll = () => this.changeAll(false);

  showInactive = () => this.setState({ show_inactive: true });

  render() {
    const cities_all = this.state.cities;

    const inactive_hidden_count =
      this.state.show_inactive ||
      cities_all.length < 7 ||
      !cities_all.find(city => !city.active)
        ? null
        : cities_all.filter(city => !city.active).length;

    const cities = inactive_hidden_count
      ? cities_all.filter(city => city.active)
      : cities_all;

    const styles = {
      wrapper: {
        display: 'inline-block',
        margin: '10px 20px 10px 0',
      },
      city_name: {
        display: 'inline-block',
      },
      label: {
        display: 'flex',
        padding: '1px 0',
      },
      select_all: {
        fontWeight: '200',
        fontSize: '14px',
        margin: '0 7px',
      },
      ellipsis: {
        display: 'block',
        maxWidth: '150px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    };

    return (
      <div style={styles.wrapper}>
        <span style={{ fontSize: '1.3em', ...styles.ellipsis }}>
          {this.props.name}
        </span>

        <div>
          {cities.length > 5 && (
            <small
              className="the_link"
              style={styles.select_all}
              onClick={this.selectAll}>
              {' '}
              select all{' '}
            </small>
          )}
          {cities.length > 5 && (
            <small
              className="the_link"
              style={styles.select_all}
              onClick={this.deSelectAll}>
              {' '}
              deselect all{' '}
            </small>
          )}
        </div>

        <div>
          {cities.map(city => {
            const active_style = cssActive(city.active);
            return (
              <div key={city.id}>
                <label style={styles.label}>
                  <input
                    type="checkbox"
                    checked={city.selected ? true : false}
                    onChange={ev =>
                      this.handleChange(city.id, ev.target.checked)
                    }
                  />
                  <span
                    style={{
                      ...active_style,
                      ...styles.ellipsis,
                      ...styles.city_name,
                    }}>
                    {city.name}
                  </span>
                </label>
              </div>
            );
          })}
        </div>
        {inactive_hidden_count && (
          <small className="the_link" onClick={this.showInactive}>
            {' '}
            Show {inactive_hidden_count} more ...{' '}
          </small>
        )}
      </div>
    );
  }
}

const PcUser = ({
  cms_user,
  cms_group_list,
  data_access,
  has_access,
  dispatch,
}) => {
  if (!has_access) return <NotFound message="no access" />;

  if (!cms_user) return <NotFound message="user doesn't exist" />;

  const breadcrumbs = [
    {
      url: '/',
      name: 'home',
    },
    {
      url: '/pc_user_list/1',
      name: 'User List',
    },
    {
      url: '/pc_user/' + cms_user.user_id,
      name: cms_user.name || '[no name]',
    },
  ];

  const group_list = cms_group_list.map(group => ({
    name: group.name,
    value: group.group_id,
  }));

  return (
    <div>
      <PcAppBar
        title={'User: ' + (cms_user.name || '[no name]')}
        no_drawer={true}
      />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
        </div>

        <PcCard cardTitle="Details">
          <RowFlex>
            <MTextField
              name="name"
              floatingLabelText="name"
              fullWidth={false}
              required={true}
            />
            <MTextField
              name="login"
              floatingLabelText="login"
              fullWidth={false}
              required={true}
            />
            <MTextField
              name="description"
              floatingLabelText="description"
              fullWidth={false}
            />
            <MTextField
              name="password_new"
              floatingLabelText="password"
              fullWidth={false}
            />

            <MSelect
              name="group_id"
              floatingLabelText="group"
              options={group_list}
            />
            <MCheckBox name="active" label="active" />
          </RowFlex>
        </PcCard>

        <PcCard>
          <RowFlexTop>
            <AccessCities data_access={data_access} dispatch={dispatch} />
          </RowFlexTop>
        </PcCard>
      </div>
    </div>
  );
};

class AccessCities extends Component {
  // without AccessCities with shouldComponentUpdate the dispatch in CountryBlock caused all CountryBlocks rerender
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
  render() {
    let data_access = this.props.data_access;

    //data_access = data_access.slice(0,3)

    return data_access.map(country => (
      <CountryBlock
        name={country.country_name}
        cities={country.cities}
        key={country.country_id}
        dispatch={this.props.dispatch}
      />
    ));
  }
}

/* data_access
{
	countries = [
		{
			country_id:18,
			cities = [
				{
					name		: 'gdansk'	,
					city_id		: 123		,
					checked		:
				}
			]
		}
	]
}
*/

const mapStateToProps = state => {
  const { cms_users, cms_group_list } = state.data;

  return {
    cms_user: cms_users,
    cms_group_list: cms_group_list,
    data_access: getUserCitiesAccess(state),
    has_access: isSuperAdmin(),
  };
};

export default connect(mapStateToProps)(PcUser);
