import React, { Component } from 'react';
import PcCard from '../../shared/PcCard.js';
import OpenHoursLine from './OpenGridLine.js';
import Toggle from 'material-ui/Toggle';
import FlatButton from 'material-ui/FlatButton';
import { connect } from 'react-redux';
import { queueUpdate } from '../../../actions/actions.js';
import update from 'immutability-helper';
import openPretty from '../../../helpers/openPretty.js';

class OpenGridWithState extends Component {
  init(props) {
    this.setState({
      open_hours: props.open_hours,
      seasons_toggle: props.open_hours.length > 1 ? true : false,
    });
  }

  // Wrapper for setState -> to send modiffied data to store
  applyChange(new_state) {
    const { handleChange, venue_id } = this.props;

    const open_hours = new_state.open_hours.sort(
      (a, b) => a.date_start && b.date_start && a.date_start > b.date_start,
    );

    this.setState(
      {
        open_hours,
        seasons_toggle: open_hours.length > 1 ? true : false,
      },
      () => {
        // protect empty at the end
        let without_empty =
          open_hours.length === 1
            ? open_hours
            : open_hours.filter(
                item => item.date_start && item.date_stop && item.open_pretty,
              );
        // if fileterd is zero use 1st
        if (without_empty.length === 0 && open_hours.length !== 0)
          without_empty = [open_hours[0]];

        handleChange(venue_id, without_empty);
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data_key === this.props.data_key) return;
    this.init(nextProps);
  }

  componentWillMount() {
    this.init(this.props);
  }

  handleAddSeason = () => {
    this.applyChange({ open_hours: this.state.open_hours.concat([{}]) });
  };

  handleRemoveSeason = line_number => {
    const new_open_hours = update(this.state.open_hours, {
      $splice: [[line_number, 1]],
    });

    if (new_open_hours.length === 1) {
      new_open_hours[0] = this.cleanDates(new_open_hours[0]);
    }

    this.applyChange({
      open_hours: new_open_hours,
      seasons_toggle: new_open_hours.length === 1 ? false : true,
    });
  };

  cleanDates(line) {
    const { day_start, day_stop, month_start, month_stop, ...rest } = line;
    return rest;
  }

  handleItemChange = (key, value, line_number) => {
    let open_hours = update(this.state.open_hours, {
      [line_number]: { [key]: { $set: value } },
    });
    open_hours[line_number]['open_pretty'] = openPretty(
      open_hours[line_number],
    );
    this.applyChange({ open_hours });
  };

  toggleSeasons = () => {
    const new_seasons_toggle = !this.state.seasons_toggle;
    let new_open_hours = [];

    if (new_seasons_toggle) {
      new_open_hours = this.state.open_hours.concat([{}]);
    } else {
      // mutable??????

      new_open_hours = this.state.open_hours.slice(0, 1);
      new_open_hours[0] = this.cleanDates(new_open_hours[0]);
    }

    this.applyChange({
      seasons_toggle: new_seasons_toggle,
      open_hours: new_open_hours,
    });
  };

  render() {
    return (
      <PcCard cardTitle="Open hours" id="card_open_hours">
        <div className="row" style={{ marginTop: '-2em' }}>
          <div className="col-xs-12">
            <div className="pull-right" style={{ display: 'inline-block' }}>
              <Toggle
                label="Split&nbsp;to&nbsp;date&nbsp;ranges"
                toggled={this.state.seasons_toggle}
                onToggle={this.toggleSeasons}
              />
            </div>
          </div>
        </div>

        {this.state.open_hours.map(function (line_data, i) {
          return (
            <OpenHoursLine
              {...line_data}
              key={i}
              line_number={i}
              multi={this.state.seasons_toggle}
              handleItemChange={this.handleItemChange}
              onRemove={this.handleRemoveSeason}
            />
          );
        }, this)}

        <div className={!this.state.seasons_toggle ? 'hide' : ''}>
          <br />
          <br />

          <FlatButton label="Add season" onClick={this.handleAddSeason} />
        </div>
      </PcCard>
    );
  }
}

const mapStateToProps = ({ data }) => {
  // open_hours - new field
  const { data_key, venue } = data;

  const {
    venue_id,
    open_hours,
    open_daily,
    open_mon,
    open_tue,
    open_wed,
    open_thu,
    open_fri,
    open_sat,
    open_sun,
  } = venue;

  // remove when old pc disabled
  const open_hours_obj = open_hours || [
    {
      open_daily,
      open_mon,
      open_tue,
      open_wed,
      open_thu,
      open_fri,
      open_sat,
      open_sun,
    },
  ];

  return {
    open_hours: open_hours_obj,
    venue_id,
    data_key,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (venue_id, open_hours) => {
      dispatch(queueUpdate('venue', venue_id, 'open_hours', open_hours));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenGridWithState);
