// @flow
import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import { connect, DispatchProp } from 'react-redux';

import PcAppBar from '../shared/TopBar/PcAppBar';
import BreadCrumbs from '../shared/BreadCrumbs';
import Center from '../shared/Center';
import { leloFetch } from '../../helpers';
import PcCard from '../shared/PcCard';
import { DoFollowLinksAddNew } from './DoFollowLinksAddNew';
import { remove } from '../../actions/removeArchive';
import { withAccessSuperadmin } from '../../core/withAccess';

const breadcrumbs = [
  {
    url: '/',
    name: 'home',
  },
  {
    url: '/do_follow_links',
    name: 'Do follow links',
  },
];

type TLink = {
  do_follow_link_id: number,
  created_by_name: string,
  created_when: string,
  link: string,
};

const DoFollowLinks = ({ dispatch }: { dispatch: DispatchProp }) => {
  const [links, setLinks] = useState<TLink[] | null>(null);

  const fetchData = useCallback(async () => {
    const links = await leloFetch('page/do_follow_links');
    setLinks(links);
  }, []);

  const onRemoveClicked = useCallback(
    async (linkId: number) => {
      if (window.confirm('Are you sure?')) {
        await dispatch(
          remove({
            item_type: 'do_follow_link',
            item_key: 'do_follow_link_id',
            item_id: linkId,
          }),
        );
        fetchData();
      }
    },
    [fetchData],
  );

  useEffect(() => {
    fetchData();
  }, []);

  const isEmpty = !links && links !== null;

  return (
    <>
      <PcAppBar title="DoFollow links" no_drawer={true} />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
        </div>
        <div style={{ maxWidth: '1024px', margin: 'auto' }}>
          <DoFollowLinksAddNew
            onNewLinkAdded={() => {
              fetchData();
            }}
          />

          <PcCard cardTitle="List">
            {isEmpty ? (
              <Center>Nothing to show</Center>
            ) : (
              <Table>
                <TableBody displayRowCheckbox={false}>
                  <TableRow>
                    <TableHeaderColumn style={{ width: '30px' }}>
                      ID
                    </TableHeaderColumn>
                    <TableHeaderColumn style={{ width: '300px' }}>
                      Link
                    </TableHeaderColumn>
                    <TableHeaderColumn style={{ width: '130px' }}>
                      Added By
                    </TableHeaderColumn>
                    <TableHeaderColumn style={{ width: '90px' }}>
                      Added When
                    </TableHeaderColumn>
                    <TableHeaderColumn style={{ width: '120px' }}>
                      {' '}
                    </TableHeaderColumn>
                  </TableRow>
                  {links &&
                    links.map(item => (
                      <TableRow key={item.do_follow_link_id}>
                        <TableRowColumn>
                          {item.do_follow_link_id}
                        </TableRowColumn>
                        <TableRowColumn>
                          <a
                            className="ellipsis the_link"
                            href={item.link}
                            title={item.link}
                            target="_blank"
                            rel="noopener noreferrer">
                            {item.link}
                          </a>
                        </TableRowColumn>
                        <TableRowColumn>{item.created_by_name}</TableRowColumn>
                        <TableRowColumn>
                          {item.created_when.substring(0, 10)}
                        </TableRowColumn>
                        <TableRowColumn>
                          <span
                            className="the_link"
                            onClick={() =>
                              onRemoveClicked(item.do_follow_link_id)
                            }>
                            remove
                          </span>
                        </TableRowColumn>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </PcCard>
        </div>
      </div>
    </>
  );
};

export default withAccessSuperadmin(connect()(DoFollowLinks));
