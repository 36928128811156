export const notLoggedLinkPreserve = (state = null, action) => {
  switch (action.type) {
    case 'ADD_NOT_LOGGED_GO_LINK':
      return action.url;

    case 'REMOVE_NOT_LOGGED_GO_LINK':
      return null;

    default:
      return state;
  }
};

export default notLoggedLinkPreserve;
