import React, { Component } from 'react';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import PropTypes from 'prop-types';

const prop_types = {
  floatingLabelText: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

class PcSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps({ value }) {
    this.setState({ value });
  }

  handleChange(event, index, value) {
    this.setState({ value }, () => {
      this.props.handleChange(value);
    });
  }

  renderOptionsSimple(items) {
    const options = [];
    items.map((val, i) => {
      return options.push(<MenuItem value={val} key={i} primaryText={val} />);
    });
    return options;
  }

  renderOptionsWithKeys(items) {
    const options = [];
    items.map((item, i) => {
      return options.push(
        <MenuItem value={item.value} key={i} primaryText={item.name} />,
      );
    });
    return options;
  }

  renderOptions(items) {
    if (typeof items[0] === 'object') return this.renderOptionsWithKeys(items);
    return this.renderOptionsSimple(items);
  }

  render() {
    const { options, floatingLabelText } = this.props;

    return (
      <SelectField
        floatingLabelText={floatingLabelText}
        value={this.state.value}
        onChange={this.handleChange}>
        {this.renderOptions(options)}
      </SelectField>
    );
  }
}

PcSelect.propTypes = prop_types;

export default PcSelect;
