const formErrors = (state = {}, action) => {
  switch (action.type) {
    case 'FORM_ERROR_ADD':
      console.log(state, action);

      let el = {};
      el[action.id] = action.name;

      return { ...state, ...el };

    case 'FORM_ERROR_REMOVE':
      console.log(state, action);

      let state_new = { ...state };

      return delete state_new[action.id];

    default:
      return state;
  }
};

export default formErrors;
