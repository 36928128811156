import React from 'react';

import withUserData from '../core/withUserDataFetched';

class Test extends React.Component {
  componentDidMount() {
    const L = window.L;
    const position = [54.351354173372, 18, 64688873291];
    const map = L.map('map').setView(position, 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    L.marker(position, {
      draggable: true,
    })
      .on('dragend', function (e) {
        const new_coords = [this.getLatLng().lat, this.getLatLng().lng];
        console.log(new_coords);
      })
      .addTo(map);
  }

  render() {
    return (
      <div>
        <div id="map" style={{ width: '500px', height: '500px' }} />
      </div>
    );
  }
}

export default withUserData(Test);
