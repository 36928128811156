import { is_debug } from '../config/config';
import {
  indicatorOn,
  indicatorOff,
  cleanModiffy,
  clearPostData,
} from './actions';
import { snackSuccess, snackError } from './snacks';
import { leloFetch } from '../helpers';
import { setForceFetch } from './pageControl';
//import history 			from '../history.js'

const unarchive = (page_type, page_id) => {
  return dispatch => {
    dispatch(indicatorOn());
    dispatch(cleanModiffy());
    dispatch(clearPostData());
    return leloFetch('unarchive/' + page_type + '/' + page_id, {
      method: 'POST',
    }).then(
      data => {
        dispatch(indicatorOff());

        if (data.success) {
          dispatch(snackSuccess('Item unarchived'));
          dispatch(setForceFetch(true));
        }
        if (data.error) {
          if (is_debug) dispatch(snackError('ERROR: ' + data.error));
          else dispatch(snackError('ERROR: something went wrong'));
        }
      },
      error => {
        dispatch(indicatorOff());
        dispatch(snackError('ERROR: ' + error));
      },
    );
  };
};

export default unarchive;
