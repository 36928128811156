import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapLocation from '../../shared/MapLocation.js';
import { queueUpdate } from '../../../actions/actions.js';
import PropTypes from 'prop-types';

class VenueMap extends Component {
  static propTypes = {
    // from parent
    address: PropTypes.string,

    //from redux
    center: PropTypes.object,
    coords: PropTypes.object,
    address_text: PropTypes.string,
    venue_id: PropTypes.number.isRequired,
    handleModify: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { coords, venue_id } = props;
    this.state = { coords, venue_id };
  }

  componentWillReceiveProps({ coords, venue_id }) {
    if (venue_id === this.state.venue_id) return;
    this.setState({ coords, venue_id });
  }

  _handleChange = coords => {
    this.setState({ coords });

    // make sure both lat & lng values are set. If not set them to null
    if (!coords || !coords.lat || !coords.lng) {
      coords = { lat: null, lng: null };
    }
    this.props.handleModify(coords, this.state.venue_id);
  };

  render() {
    const { address_text, center } = this.props;
    const { coords } = this.state;

    const the_center = coords ? coords : center;
    const zoom = coords ? 18 : 12;

    return (
      <MapLocation
        handleChange={this._handleChange}
        coords={coords}
        address_text={address_text}
        center={the_center}
        zoom={zoom}
      />
    );
  }
}

const mapStateToProps = ({ data }, ownProps) => {
  const { country, city, venue } = data;

  const { address } = ownProps;

  const city_name = city.name_geo || city.name;
  const country_name = country.name;

  let address_text = '';

  if (address) {
    address_text = address.split('(')[0]; // lawendowa 8 (entrance from Straganiarska)
    address_text += ', ';
  }
  if (city_name) {
    address_text += city_name + ', ';
  }
  if (country_name) {
    address_text += country_name + ', ';
  }

  const coords =
    venue.lat && venue.lon ? { lat: venue.lat, lng: venue.lon } : null;

  const center = coords
    ? coords
    : city.lat && city.lon
    ? { lat: city.lat, lng: city.lon }
    : null;

  return {
    center,
    coords,
    address_text: address ? address_text : null,
    venue_id: venue.venue_id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleModify: (coords, venue_id) => {
      dispatch(queueUpdate('venue', venue_id, 'lat', coords.lat));
      dispatch(queueUpdate('venue', venue_id, 'lon', coords.lng));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VenueMap);
