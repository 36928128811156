import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { connect } from 'react-redux';
import PcTextField from '../PcTextField';
import addNew from '../../../actions/addNew';
import getCityStructureData from 'selectors/getCityStructureData';

class AddNewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      event_type_id: 0,
    };
  }

  componentDidMount() {
    window.setTimeout(
      function () {
        const ref = this.refs['name'];
        if (ref) ref.focusInput();
      }.bind(this),
      200,
    );
  }

  handleNameChange = name => this.setState({ name });

  handleChangeType = (e, k, p) => {
    this.setState({ event_type_id: p });
  };

  handleAddNewClick = () => {
    const { name, event_type_id } = this.state;
    const { country_id, city_id } = this.props;
    this.props.handleCloseModal();
    this.props.dispatch(
      addNew('event', { name, event_type_id, country_id, city_id }),
    );
  };

  render() {
    return (
      <Dialog
        title="Add new event"
        actions={[
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={this.props.handleCloseModal}
          />,
          <FlatButton
            label="Add"
            primary={true}
            keyboardFocused={true}
            onClick={this.handleAddNewClick}
            disabled={!this.state.name || !this.state.event_type_id}
          />,
        ]}
        modal={false}
        open={true}
        onRequestClose={this.props.handleCloseModal}>
        <PcTextField
          handleChange={this.handleNameChange}
          floatingLabelText="name"
          value={this.state.name}
          ref="name"
        />
        <br />
        <SelectField
          value={this.state.event_type_id}
          floatingLabelText="event type"
          autoWidth={true}
          onChange={this.handleChangeType}>
          <MenuItem value={0} primaryText="select event type" />
          {this.props.event_type_list.map(el => (
            <MenuItem
              value={el.event_type_id}
              primaryText={el.name}
              key={el.event_type_id}
            />
          ))}
        </SelectField>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ data_city, data }) => {
  const city_id = data.city.city_id;

  const city_structure = getCityStructureData({ data_city, city_id });

  return {
    event_type_list: city_structure.event_type_list || [],
    country_id: data.country.country_id,
    city_id: data.city.city_id,
  };
};

export default connect(mapStateToProps)(AddNewEvent);
