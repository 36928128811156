// @flow

// use if youe expect eg. some data to be feched from the server

import React from 'react';
import { connect } from 'react-redux';

// userdata optionally if we want somehow test superadmin
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

function withData(callbacks: Array<() => void>, WrappedComponent: any) {
  class DataComponent extends React.Component<*, *> {
    componentDidMount() {
      callbacks.map(fn => this.props.dispatch(fn()));
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  const DataComponentConnected = connect(mapStateToProps)(DataComponent);
  return DataComponentConnected;
}

export default withData;
