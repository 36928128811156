// @flow

import React, { Component } from 'react';
import FlatButton from 'material-ui/FlatButton';
import { connect } from 'react-redux';
import { DispatchProp } from 'react-redux';

import { linkGenerator } from '../../helpers.js';
import { remove, archive } from '../../actions/removeArchive';

import { hasAccessLevel } from '../../helpers/hasAccess';

type TProps = {
  item_type: string,
  item_key: string,
  item_id: number,
  parent_url: string,
  custom_key: string,
  dispatch: DispatchProp,
  label: string,
  remove_access: boolean,
  has_archived: boolean,
};

class RemoveArchive extends Component<TProps> {
  archive = () => {
    const { item_type, item_key, item_id, parent_url, dispatch } = this.props;
    if (window.confirm('Item archive. Are you sure?'))
      dispatch(archive({ item_type, item_key, item_id, parent_url }));
  };

  remove = () => {
    const { item_type, item_key, item_id, parent_url, dispatch } = this.props;
    if (window.confirm('Item remove. Are you sure?'))
      dispatch(remove({ item_type, item_key, item_id, parent_url }));
  };

  render() {
    const { remove_access, label = '', has_archived } = this.props;

    if (!remove_access) return null;

    return (
      <div style={{ textAlign: 'right' }}>
        <div className="pd" />
        <div className="pd" />
        {has_archived && (
          <FlatButton label={'Archive ' + label} onClick={this.archive} />
        )}
        <FlatButton
          label={'Remove ' + label}
          secondary={true}
          onClick={this.remove}
        />
      </div>
    );
  }
}

const getParentPage = (item_type, d) => {
  switch (item_type) {
    case 'venue':
      return linkGenerator('category', d.category);
    case 'category':
      return linkGenerator('chapter', d.chapter);
    case 'chapter':
      return linkGenerator('city', d.city);
    case 'city':
      return linkGenerator('country', d.country);
    case 'event':
      return linkGenerator('city', d.city, 'events_chapter');
    case 'feature':
      return d.city
        ? linkGenerator('city', d.city)
        : linkGenerator('country', d.country);
    default:
      return '';
  }
};

const mapStateToProps = ({ data, user_data }, ownProps) => {
  const item_type = ownProps.item_type || Object.keys(data)[0];
  const item_key = ownProps.custom_key || item_type + '_id';
  const item_id = data[item_type][item_key];
  const { page_type } = data;
  const { rights } = user_data;
  const archived = data[item_type].archived;

  return {
    item_type,
    item_key,
    item_id,
    has_archived:
      (['venue', 'event', 'feature'].find(el => el === item_type) &&
        !archived) ||
      false,
    parent_url: getParentPage(item_type, data),
    remove_access: rights ? hasAccessLevel(3, page_type, rights) : false,
  };
};

export default connect(mapStateToProps)(RemoveArchive);
