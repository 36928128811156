import React from 'react';

import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';

import CitySelect from '../shared/Drawer/CitySelect';
import ChapterSelect from '../shared/Drawer/ChapterSelect';
import DistrictSelect from '../shared/Drawer/DistrictSelect';
import RemoveArchive from '../shared/RemoveArchive';

import PcCard from '../shared/PcCard';
import CardImages from '../shared/Images/CardImages';
import Cover from '../shared/Images/Cover';
import CityThumb from '../shared/Images/CityThumb';
import FeatureTypeSelect from '../shared/Drawer/FeatureTypeSelect';

import CityColorPicker from './CityColorPicker';
import CityLanguage from './CityLanguage';
import CityMap from './CityMap';
import CityMenu from './CityMenu';
import CityPdf from './CityPdf';
import CityInfo from './CityInfo';
import CardCityBackground from './CardCityBackground';
import { Link } from 'react-router-dom';

import BreadCrumbs from '../shared/BreadCrumbs';
import EditedBy from '../shared/EditedBy';

//import	CardRedirect 	from '../shared/CardRedirect'
//import	{InfoNotice} 	from '../shared/Info/Info'

import { Row, CellHalf, RowFlex } from '../shared/Grid';
import CardMetaTags from '../shared/CardMetaTags';
import PcAppBar from '../shared/TopBar/PcAppBar';
import PcDrawer from '../shared/Drawer/PcDrawer';
import PcTextField from '../shared/PcTextField';
import PcTextFieldWithTooltip from '../shared/PcTextFieldWithTooltip';
import PcCheckbox from '../shared/PcCheckbox';
import PcHtmlEditor from '../shared/PcHtmlEditor';
import CardRedirect from '../shared/CardRedirect';

import { modifyWrapper } from '../../core/ModifyWrapper';

import FeatureList from '../shared/Drawer/FeatureList';

import Toc from '../shared/Toc';
import toc from './city_toc';

import { api_url } from '../../config/config';

import {
  isSuperAdmin,
  isEditorPlus,
  isEditor,
  hasAccessLevel,
} from '../../helpers/hasAccess';

const TextFieldCity = modifyWrapper(PcTextField, 'city');
const TextFieldText = modifyWrapper(PcTextField, 'text');
const CheckboxCity = modifyWrapper(PcCheckbox, 'city');
const EditorText = modifyWrapper(PcHtmlEditor, 'text');
const EditorCity = modifyWrapper(PcHtmlEditor, 'city');
const TextFieldCityWithTooltip = modifyWrapper(PcTextFieldWithTooltip, 'city');

const CityView = ({ city_id, city_symbol }) => {
  const is_superadmin = isSuperAdmin();

  const is_editor_plus = isEditorPlus();

  const is_editor = isEditor();

  const toc_filtered = toc.filter(
    item =>
      //	(item[1] !== 'card_mailchimp'	|| is_superadmin) &&
      (item[1] !== 'card_affilate' || is_superadmin) &&
      (item[1] !== 'card_export' || hasAccessLevel(2, 'export')),
  );

  return (
    <div>
      <PcAppBar />

      <PcDrawer>
        <CitySelect city_id={city_id} />
        <ChapterSelect />
        <DistrictSelect city_id={city_id} />
        <FeatureTypeSelect />
        <FeatureList />
      </PcDrawer>

      <Toc data={toc_filtered} />

      <div className="pd pc-content">
        <div className="top-line">
          <BreadCrumbs />
          <EditedBy />
        </div>

        <CityInfo />

        <PcCard cardTitle="Settings" id="card_top">
          <Row>
            <CellHalf>
              <TextFieldCity
                name="name"
                required={true}
                floatingLabelText="name"
              />
            </CellHalf>
            {(is_superadmin || is_editor_plus || is_editor) && (
              <CellHalf>
                <Link
                  style={{ float: 'right' }}
                  className="the_link"
                  to={'/city_home/' + city_id}>
                  New homepage
                </Link>
              </CellHalf>
            )}
          </Row>
          <RowFlex>
            <CheckboxCity name="active" label="active" />
            <CheckboxCity name="app_ready" label="app&nbsp;ready" />
            <CheckboxCity
              name="app_country_ready"
              label="app&nbsp;country&nbsp;ready"
            />

            {(is_superadmin || is_editor_plus) && (
              <div>
                <CheckboxCity
                  name="uber"
                  label={
                    <span>
                      uber&nbsp;integration<sup title="Admin only">A</sup>
                    </span>
                  }
                />
                <CheckboxCity
                  name="no_adsense"
                  label={
                    <span>
                      disable&nbsp;adsense<sup title="Admin only">A</sup>
                    </span>
                  }
                />
              </div>
            )}

            <TextFieldCity
              name="ordering"
              floatingLabelText="ordering"
              type="number"
              fullWidth={false}
              style={{ maxWidth: 70 }}
            />
            <TextFieldCityWithTooltip
              name="city_synonyms"
              floatingLabelText="city synonymus"
              fullWidth={false}
              tooltip="Comma seperated. Used in search."
            />
            <TextFieldCityWithTooltip
              name="name_geo"
              floatingLabelText="Name for geo lookup"
              fullWidth={false}
              tooltip="The name of city to use in find venue cords by address. Use only if the city name in PC do not correspend with geographical name"
            />

            <TextFieldCity
              name="twitter"
              floatingLabelText="twitter username"
              fullWidth={false}
            />
            <TextFieldCity
              name="facebook"
              floatingLabelText="facebook url"
              fullWidth={false}
              type="url"
            />
            <TextFieldCity
              name="instagram"
              floatingLabelText="instagram url"
              fullWidth={false}
              type="url"
            />
            <TextFieldCity
              name="blog"
              floatingLabelText="blog"
              fullWidth={false}
              type="url"
            />
            <TextFieldCity
              name="pdf_shop_link"
              floatingLabelText="pdf shop link"
              fullWidth={false}
              type="url"
            />
          </RowFlex>
          <CityColorPicker />
          <p>
            Map: <CityMap />
          </p>
        </PcCard>

        <PcCard cardTitle="Description" id="card_description">
          <Row>
            <CellHalf>
              <TextFieldText
                name="name"
                floatingLabelText="text title"
                hintText="If empty, city name will be used"
              />
            </CellHalf>
          </Row>
          <br />
          <EditorText name="body" dom_key={'city.body.' + city_id} />
        </PcCard>

        <PcCard cardTitle="Pdf" id="card_pdf">
          <CityPdf />
          <TextFieldCity
            name="flippingbook_code"
            floatingLabelText="flippingbook.com code"
            type="text"
            multiLine={true}
          />
        </PcCard>

        <PcCard cardTitle="Cover" id="card_cover">
          <Cover />
        </PcCard>

        <PcCard
          cardTitle="City Thumb"
          id="card_city_thumb"
          description={
            <span>
              Displays on homepage in <i>Where would you like to go</i> area &
              on country page
            </span>
          }>
          <CityThumb />
        </PcCard>

        <CardImages data_type="city" data_id={city_id} />

        <CardCityBackground city_id={city_id} />

        <PcCard cardTitle="Menu" id="card_menu">
          <Row>
            <CityMenu />
          </Row>
        </PcCard>

        <PcCard cardTitle="Language" id="card_lang">
          <Row>
            <CityLanguage />
          </Row>
        </PcCard>

        <CardMetaTags TextFieldTyped={TextFieldCity} />

        {is_superadmin && (
          <PcCard
            cardTitle={
              <span>
                Mailchimp integration<sup title="Admin only">A</sup>
              </span>
            }
            id="card_mailchimp">
            <Row>
              <CellHalf>
                <TextFieldCity
                  name="mailchimp_invite"
                  floatingLabelText="Invite list_id"
                />
              </CellHalf>
              <CellHalf>
                <TextFieldCity
                  name="mailchimp_newsletter"
                  floatingLabelText="Neewsletter list_id"
                />
              </CellHalf>
            </Row>
          </PcCard>
        )}

        <PcCard cardTitle="Contact" id="card_contact">
          <EditorCity name="contact" dom_key={'city.contact.' + city_id} />
        </PcCard>

        <PcCard cardTitle="Export" id="card_export">
          <FlatButton
            label="Venues"
            onClick={() =>
              (document.location.href =
                api_url +
                'excel_export?city_id=' +
                city_id +
                '&type=venues&city_symbol=' +
                city_symbol)
            }
          />
          <FlatButton
            label="Events"
            onClick={() =>
              (document.location.href =
                api_url +
                'excel_export?city_id=' +
                city_id +
                '&type=events&city_symbol=' +
                city_symbol)
            }
          />
        </PcCard>

        {is_superadmin && (
          <PcCard cardTitle="Affilate" id="card_affilate">
            <TextFieldCity
              name="bookingcom"
              type="url"
              floatingLabelText="booking.com"
            />
          </PcCard>
        )}

        <CardRedirect data_type="city" floatingLabelText="booking.com" />

        <RemoveArchive />
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  return {
    city_id: data.city.city_id,
    city_symbol: data.city.symbol,
  };
};

export default connect(mapStateToProps)(CityView);
