import React from 'react';
import { connect } from 'react-redux';
import CitySelect from '../shared/Drawer/CitySelect';
import ChapterSelect from '../shared/Drawer/ChapterSelect';

import PcAppBar from '../shared/TopBar/PcAppBar';
import PcDrawer from '../shared/Drawer/PcDrawer';

import EventTypeSelect from '../shared/Drawer/EventTypeSelect';

import EventList from '../shared/Drawer/EventTypeFilter/EventList';

import BreadCrumbs from '../shared/BreadCrumbs';
import { modifyWrapper } from '../../core/ModifyWrapper';
import PcTextField from '../shared/PcTextField';
import PcCard from '../shared/PcCard';
import RemoveArchive from '../shared/RemoveArchive';

import { Row, CellHalf } from '../shared/Grid';

const MTextField = modifyWrapper(PcTextField, 'event_type');

const EventView = ({ event_type_id, city_id }) => (
  <div>
    <PcAppBar />

    <PcDrawer>
      <CitySelect city_id={city_id} />
      <ChapterSelect />
      <EventTypeSelect />
      <EventList />
    </PcDrawer>

    <div className="pd pc-content">
      <div className="top-line">
        <BreadCrumbs />
      </div>

      <PcCard>
        <Row>
          <CellHalf>
            <MTextField name="name" floatingLabelText="name" />
          </CellHalf>
          <CellHalf>
            <MTextField
              name="ordering"
              floatingLabelText="ordering"
              type="number"
            />
          </CellHalf>
        </Row>
      </PcCard>

      <RemoveArchive />
    </div>
  </div>
);

const mapStateToProps = ({ data }) => {
  const { event_type_id, city_id } = data.event_type;

  return {
    event_type_id,
    city_id,
  };
};

export default connect(mapStateToProps)(EventView);
