import React from 'react';

const Row = props => <div className="row">{props.children}</div>;

const RowFlex = props => <div className="row-flex">{props.children}</div>;

const RowFlexTop = props => (
  <div className="row-flex top" style={props.style}>
    {props.children}
  </div>
);

const RowFlexCenter = props => (
  <div className="row-flex center">{props.children}</div>
);

const CellHalf = props => (
  <div className={'col-md-6 ' + props.className} id={props.id}>
    {props.children}
  </div>
);

const CellFull = props => (
  <div className="col-xs-12" id={props.id}>
    {props.children}
  </div>
);

const CellFree = props => (
  <div
    className="pd"
    style={{ display: 'inline-block', verticalAlign: 'top' }}
    id={props.id}>
    {props.children}
  </div>
);

export {
  Row,
  CellHalf,
  CellFull,
  CellFree,
  RowFlex,
  RowFlexTop,
  RowFlexCenter,
};
