import React from 'react';

import { Link } from 'react-router-dom';

import './Drawer.css';

import {
  getFeaturesArchived,
  getFeaturesNotArchived,
} from 'selectors/getFeatures';

import ClearColors from './ClearColors';

import { state_colors as colors } from '../../../config/config';

//import config from '../../config/config';

import { connect } from 'react-redux';

const ViewItem = ({
  feature_id,
  symbol,
  name,
  current_id,
  active,
  print,
  ordering,
  state,
}) => {
  //const inactive_style = active ? {} : {color:"grey"}

  return (
    <li>
      <Link to={'/feature/' + symbol + '/' + feature_id}>
        {current_id === feature_id && <span>-</span>}
        <span style={{ color: colors[state] }}>
          {ordering !== null && <sup style={{ color: 'grey' }}>{ordering}</sup>}
          {name}
        </span>
      </Link>

      <span className="flags">
        {active && <span>w</span>}
        {print && <span>p</span>}
      </span>
    </li>
  );
};

const ViewList = ({ feature_list, current_id, show_clear_colors }) => (
  <div>
    <ul id="nav_list">
      {feature_list.map((item, i) => (
        <ViewItem {...item} current_id={current_id} key={i} />
      ))}
    </ul>
    {show_clear_colors && (
      <div>
        <ClearColors item_list={feature_list} type="feature" />
      </div>
    )}
  </div>
);

const ViewEmpty = () => <span>empty</span>;

const featureListView = ({ not_archived, archived, current_id }) => (
  <div>
    <div>
      <div className="pd-h">FEATURES:</div>
      {not_archived.length > 0 && (
        <ViewList
          feature_list={not_archived}
          current_id={current_id}
          show_clear_colors={true}
        />
      )}
      {!not_archived.length && <ViewEmpty />}
    </div>

    {archived.length > 0 && (
      <div>
        <div className="line" />

        <div className="pd-h">ARCHIVED:</div>
        {archived.length > 0 && (
          <ViewList
            feature_list={archived}
            current_id={current_id}
            show_clear_colors={false}
          />
        )}
      </div>
    )}
  </div>
);

const mapStateToProps = ({ data }) => {
  return {
    //feature_list:	data.feature_list || []	,
    not_archived: getFeaturesNotArchived(data) || [],
    archived: getFeaturesArchived(data) || [],
    current_id: data.feature ? data.feature.feature_id : null,
  };
};

export default connect(mapStateToProps)(featureListView);
