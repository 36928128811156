// @flow

import * as React from 'react';

const style = {
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

type Props = {
  children: React.Node,
};

const Center = (props: Props) => (
  <div style={style}>
    <div>{props.children}</div>
  </div>
);

export default Center;
