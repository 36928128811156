// @flow

import React, { useState } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import PcCard from '../shared/PcCard';
import languageListIso from '../../helpers/language_list_iso.json';
import withModifySubscriber from '../../core/withModifySubscriber';

const langualeList = languageListIso.filter(l => l[1] !== 'EN');

type TProps = {
  value: string,
  handleChange: (val: string | null) => void,
};

const Language = ({ value, handleChange }: TProps) => {
  console.log('value', value);

  const [languageState, setLanguageState] = useState(value);

  const onChange = new_value => {
    handleChange(new_value);
    setLanguageState(new_value);
  };

  return (
    <PcCard cardTitle="Language" id="card_language">
      <SelectField
        floatingLabelText="Language"
        value={languageState}
        onChange={(e, i, payload) => onChange(payload)}
        autoWidth={true}>
        <MenuItem value="" primaryText="Default (English)" />

        {languageListIso.map((item, index) => (
          <MenuItem value={item[1]} primaryText={item[0]} key={index} />
        ))}
      </SelectField>
      <br />
      <small>Leave empty if english</small>
    </PcCard>
  );
};

export default Language;
