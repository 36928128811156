const cityData = (state = [], action) => {
  switch (action.type) {
    case 'PRESERVE_CITY_DATA':
      const { city_id, data } = action;

      return [...state, { city_id, data }];

    case 'CLEAR_CITY_DATA_ALL':
      return [];

    default:
      return state;
  }
};

export default cityData;
