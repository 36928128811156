import React, { Component } from 'react';
import PcCard from '../shared/PcCard.js';
import PcSearch from '../shared/PcSearch';
import EditableList from '../shared/EditableList';
import { connect } from 'react-redux';
import { queueUpdate } from '../../actions/actions.js';
import { Row, CellHalf } from '../shared/Grid.js';
import PcTextField from '../shared/PcTextField';
import { modifyWrapper } from '../../core/ModifyWrapper';
import PcCheckbox from '../shared/PcCheckbox';

const MTextField = modifyWrapper(PcTextField, 'feature');

class FeatureConnectedState extends Component {
  constructor(props) {
    super();
    this.state = {
      items: props.connected_venues || [],
      venue_ids_is_random: props.venue_ids_is_random || false,
      value: '',
    };
    this.addItem = this.addItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRandomchange = this.handleRandomchange.bind(this);
  }

  handleRandomchange() {
    this.setState(
      prevState => {
        return {
          venue_ids_is_random: !prevState.venue_ids_is_random,
        };
      },
      () =>
        this.props.modifyRandomize(
          this.props.feature_id,
          this.state.venue_ids_is_random ? 1 : null,
        ),
    );
  }

  // added new item
  addItem(data) {
    const items = [...this.state.items, data];
    this.setState({ items });
    this.props.modifyIds(this.props.feature_id, items);
  }

  handleChange(items) {
    this.setState({ items });
    this.props.modifyIds(this.props.feature_id, items);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.feature_id === this.props.feature_id) return;

    this.setState({
      items: nextProps.connected_venues || [],
    });
  }

  render() {
    const { country_id, country_name, feature_id } = this.props;

    const { venue_ids_is_random } = this.state;

    return (
      <PcCard cardTitle="Connected Venues" id="card_venues">
        <Row>
          <CellHalf>
            <MTextField
              name="associated_venues_title"
              floatingLabelText="Venuelist title"
            />
          </CellHalf>
          <CellHalf>
            <br />
            <br />
            <PcCheckbox
              value={venue_ids_is_random}
              label="Randomize&nbsp;Venues"
              handleChange={this.handleRandomchange}
            />
          </CellHalf>
        </Row>
        <Row>
          <CellHalf>
            {/*<PcSearch narrow_field_key='country_id' narrow_field_id={country_id} table='feature' onResultClick={this.addItem} tip={"features in "+country_name} search_id={'feature'+feature_id}/>*/}
            <PcSearch
              narrow_field_key="country_id"
              narrow_field_id={country_id}
              table="venue"
              onResultClick={this.addItem}
              tip={'venues in ' + country_name}
              search_id={'feature.venues-' + feature_id}
            />
          </CellHalf>
          <CellHalf>
            {this.state.items.length > 0 && (
              <EditableList
                items={this.state.items}
                is_sortable={!venue_ids_is_random}
                handleChange={this.handleChange}
              />
            )}
          </CellHalf>
        </Row>
      </PcCard>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    modifyIds: (feature_id, items) => {
      const venue_ids = items.map(item => {
        return item.venue_id;
      });
      dispatch(
        queueUpdate('feature', feature_id, 'venue_ids', [...venue_ids]),
      ); /*...feature_ids will make them uniqe */
    },
    modifyRandomize: (feature_id, venue_ids_is_random) => {
      dispatch(
        queueUpdate(
          'feature',
          feature_id,
          'venue_ids_is_random',
          venue_ids_is_random,
        ),
      );
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const feature_id = ownProps.venue_id || state.data.page_id;

  const { feature, country } = state.data;

  return {
    connected_venues: feature.connected_venues || [],
    venue_ids_is_random: feature.venue_ids_is_random,
    country_name: country.name,
    country_id: country.country_id,
    feature_id,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeatureConnectedState);
