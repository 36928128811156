import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';

const Spinner = ({ indicator }) => {
  const style_progress = {
    position: 'fixed',
    top: 'calc(50vh - 40px)',
    left: 'calc(50vw - 40px)',
    zIndex: '99999',
    display: indicator ? 'block' : 'none',
  };

  return <CircularProgress style={style_progress} size={80} thickness={5} />;
};

const mapStateToProps = ({ indicator }) => {
  return { indicator };
};

export default connect(mapStateToProps)(Spinner);
