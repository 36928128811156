import React from 'react';
import PcCard from '../shared/PcCard.js';
import PcTextField from '../shared/PcTextField';
import { modifyWrapper } from '../../core/ModifyWrapper';
import PropTypes from 'prop-types';

const prop_types = {
  card_title: PropTypes.string,
  type: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  tip: PropTypes.string,
};

const CardTextMulti = ({ card_title, type, field, id, tip }) => {
  const MTextField = modifyWrapper(PcTextField, type);

  return (
    <PcCard cardTitle={card_title} id={id}>
      <div className="row">
        <div className="col-xs-12">
          <MTextField name={field} multiLine={true} rowsMax={8} />
          {tip && <small>{tip}</small>}
        </div>
      </div>
    </PcCard>
  );
};

CardTextMulti.propTypes = prop_types;

export default CardTextMulti;
