import React, { Component } from 'react';
import { connect } from 'react-redux';
import PcCard from '../shared/PcCard';
import PcSearch from '../shared/PcSearch';
import { Row, CellHalf } from '../shared/Grid';
import { queueUpdate } from '../../actions/actions';
import EditableList from '../shared/EditableList';

const SiblingsView = ({
  sibling_list,
  country_id,
  country_name,
  venue_id,
  handleChange,
  addItem,
}) => (
  <PcCard cardTitle="Siblings" id="card_siblings">
    <Row>
      <CellHalf>
        <PcSearch
          narrow_field_key="country_id"
          narrow_field_id={country_id}
          table="venue"
          search_id={'venue' + venue_id}
          onResultClick={addItem}
          tip={'venues in ' + country_name}
          exclude={[venue_id]}
        />
      </CellHalf>
      <CellHalf>
        <EditableList
          items={sibling_list}
          is_sortable={false}
          handleChange={handleChange}
        />
      </CellHalf>
    </Row>
  </PcCard>
);

class SiblingsWithState extends Component {
  constructor(props) {
    super();

    this.state = {
      sibling_list: props.sibling_list || [],
    };
    this.addItem = this.addItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // added new item
  addItem(data) {
    this.setState(
      {
        sibling_list: this.state.sibling_list.concat([data]),
      },
      () => this.props.modifyItem(this.props.venue_id, this.state.sibling_list),
    );
  }

  handleChange(items) {
    this.setState(
      {
        sibling_list: items,
      },
      () => this.props.modifyItem(this.props.venue_id, items),
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.venue_id === this.props.venue_id) return;

    this.setState({
      sibling_list: nextProps.sibling_list || [],
    });
  }

  render() {
    const { handleChange, addItem } = this;
    const { sibling_list } = this.state;
    const { country_id, country_name, venue_id } = this.props;
    const props = {
      sibling_list,
      country_id,
      country_name,
      venue_id,
      handleChange,
      addItem,
    };

    return <SiblingsView {...props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { venue, country } = state.data;

  return {
    sibling_list: venue.sibling_list || [],
    venue_id: venue.venue_id,
    country_name: country.name,
    country_id: country.country_id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    modifyItem: (venue_id, sibling_list) => {
      const sibling_ids = sibling_list.map(item => {
        return item.venue_id;
      });
      dispatch(
        queueUpdate('venue', venue_id, 'siblings', [...sibling_ids]),
      ); /*...spread will make them uniqe */
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiblingsWithState);
