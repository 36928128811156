import React, { Component } from 'react';
import PcCard from '../../shared/PcCard.js';
import { Row } from '../../shared/Grid';
//import SelectField			from 'material-ui/SelectField';
//import MenuItem				from 'material-ui/MenuItem';

import { queueUpdate } from '../../../actions/actions.js';
import { connect } from 'react-redux';

import { modifyWrapper } from '../../../core/ModifyWrapper';
import PcTextField from '../../shared/PcTextField';

//import {isSuperAdmin}		from 'helpers/hasAccess'

import { RoomsView } from './HotelViewParts.js';

const MTextField = modifyWrapper(PcTextField, 'venue_room');

class RoomsWithState extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleShowAll = this.handleShowAll.bind(this);
  }

  init(props) {
    const { handleChange, ...the_props } = props;
    this.setState({
      ...the_props,
      show_all: false,
    });
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data_key === this.props.data_key) return;
    this.init(nextProps);
  }

  handleShowAll() {
    this.setState({ show_all: true });
  }

  handleChange(value, field) {
    this.props.handleChange('venue_room', this.props.venue_id, field, value);
  }

  render() {
    //if (!this.state.venue_room) return null // some venues has no veneu_room connected

    return (
      <RoomsView
        {...this.state}
        handleShowAll={this.handleShowAll}
        handleChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    data_key: data.data_key,
    ...data.venue_room,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleChange: (data_type, data_id, field, value) => {
      dispatch(queueUpdate(data_type, data_id, field, value, 'venue_id'));
    },
  };
};

const HotelRooms = connect(mapStateToProps, mapDispatchToProps)(RoomsWithState);

const VenueCardHotel = ({ display_rooms }) => {
  //const is_superadmin = isSuperAdmin();

  return (
    <PcCard cardTitle="Hotel" id="card_hotel">
      <Row>
        <div className="col-xs-12 pd-w">
          <MTextField
            name="reservation_link"
            floatingLabelText="Reservation link"
            type="url"
            custom_key="venue_id"
          />
        </div>
      </Row>
      {display_rooms && <HotelRooms />}
    </PcCard>
  );
};

const mapStateToPropsVenueCardHotel = ({ data }) => {
  return {
    display_rooms: data.venue_room ? true : false,
  };
};

export default connect(mapStateToPropsVenueCardHotel)(VenueCardHotel);
