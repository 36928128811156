const queueUpdate = (state = {}, action) => {
  switch (action.type) {
    case 'QUEUE_UPDATE':
      const field_key = action.custom_key
        ? action.custom_key
        : action.table + '_id';

      const store_key = action.table + ':' + field_key + ':' + action.id;

      const item_current = state[store_key] || {};

      let new_value = {};

      new_value[action.field] = action.value;

      const item = { ...item_current, ...new_value };

      let data_el = {};

      data_el[store_key] = item;

      console.log('queue update', { ...state, ...data_el });

      return { ...state, ...data_el };

    case 'MODIFFY_CLEAN':
      return {};

    default:
      return state;
  }
};

export default queueUpdate;
