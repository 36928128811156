// @flow
import React, { useCallback, useState } from 'react';
import PcCard from '../shared/PcCard';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import { connect, DispatchProp } from 'react-redux';
import { addNew } from '../../actions/addNew';

type TDoFollowLinksAddNewProps = {
  onNewLinkAdded: () => void,
  dispatch: DispatchProp,
};

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

const AddNew = ({ onNewLinkAdded, dispatch }: TDoFollowLinksAddNewProps) => {
  const [link, setLink] = useState('');
  const onAddClicked = useCallback(async () => {
    const link_trimed = link.trim();
    if (!link_trimed) return;
    if (!isValidHttpUrl(link_trimed)) {
      alert('String ' + link_trimed + ' is not a valid url');
    } else {
      await dispatch(addNew('do_follow_link', { link }));
      onNewLinkAdded();
    }
    setLink('');
  }, [link]);

  return (
    <PcCard cardTitle="Add new">
      <TextField
        value={link}
        placeholder="type link here"
        type="url"
        onChange={(_ev, val) => setLink(val)}></TextField>
      <FlatButton label={'Add'} onClick={onAddClicked} />
    </PcCard>
  );
};

export const DoFollowLinksAddNew = connect()(AddNew);
