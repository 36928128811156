// This is a standalone page
import { connect } from 'react-redux';
import Comments from '../shared/Comments';

const mapStateToProps = state => {
  const { data } = state;
  return {
    name: data.feature.name + ' comments',
    comments: data.comments || [],
  };
};

export default connect(mapStateToProps)(Comments);
