import React, { Component } from 'react';
import PcCard from '../shared/PcCard.js';
import PcSearch from '../shared/PcSearch';
import EditableList from '../shared/EditableList';
import { connect } from 'react-redux';
import { queueUpdate } from '../../actions/actions.js';
import { Row, CellHalf } from '../shared/Grid.js';

class FeatureConnectedState extends Component {
  constructor(props) {
    super();
    this.state = {
      items: props.connected_categories || [],
      value: '',
    };
    this.addItem = this.addItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // added new item
  addItem(data) {
    const items = [...this.state.items, data];
    this.setState({ items });
    this.props.modifyIds(this.props.feature_id, items);
  }

  handleChange(items) {
    this.setState({ items });
    this.props.modifyIds(this.props.feature_id, items);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.feature_id === this.props.feature_id) return;

    this.setState({
      items: nextProps.connected_categories || [],
    });
  }

  render() {
    const { country_id, country_name, city_id, city_name, feature_id } =
      this.props;

    return (
      <PcCard cardTitle="Connected Venue Categories" id="card_categories">
        <Row>
          <CellHalf>
            {/*<PcSearch narrow_field_key='country_id' narrow_field_id={country_id} table='feature' onResultClick={this.addItem} tip={"features in "+country_name} search_id={'feature'+feature_id}/>*/}
            {city_id && (
              <PcSearch
                narrow_field_key="city_id"
                narrow_field_id={city_id}
                table="category"
                onResultClick={this.addItem}
                tip={'venue categories in ' + city_name}
                search_id={'feature.categories-' + feature_id}
              />
            )}
            {!city_id && (
              <PcSearch
                narrow_field_key="country_id"
                narrow_field_id={country_id}
                table="category"
                onResultClick={this.addItem}
                tip={'venue categories in ' + country_name}
                search_id={'feature.categories-' + feature_id}
              />
            )}
          </CellHalf>
          <CellHalf>
            {this.state.items.length > 0 && (
              <EditableList
                items={this.state.items}
                is_sortable={true}
                handleChange={this.handleChange}
              />
            )}
          </CellHalf>
        </Row>
      </PcCard>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    modifyIds: (feature_id, items) => {
      const category_ids = items.map(item => {
        return item.category_id;
      });
      dispatch(
        queueUpdate('feature', feature_id, 'category_ids', [...category_ids]),
      ); /*...feature_ids will make them uniqe */
    },
  };
};

const mapStateToProps = ({ data }, ownProps) => {
  const feature_id = ownProps.category_id || data.page_id;

  return {
    connected_categories: data.feature.connected_categories || [],
    country_name: data.country.name,
    city_name: data.city ? data.city.name : null,
    country_id: data.country.country_id,
    city_id: data.city ? data.city.city_id : null,
    feature_id,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeatureConnectedState);
