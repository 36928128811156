// @flow

import React from 'react';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import { Link } from 'react-router-dom';

import PcAppBar from '../../shared/TopBar/PcAppBar';
import BreadCrumbs from '../../shared/BreadCrumbs';

import withUserDataFetched from '../../../core/withUserDataFetched';
import withDataFromUrl from '../../../core/withDataFromUrl';
import { withAccessSuperadmin } from '../../../core/withAccess';

import { archive } from '../../../actions/removeArchive';

import PcTextField from '../../shared/PcTextField.js';
import PcCheckbox from '../../shared/PcCheckbox.js';
import PcSelect from '../../shared/PcSelect.js';

import AffiliateAssociatedGroups from './AffiliateAssociatedGroups';
import AffiliateEditVenues from './AffiliateEditVenues';
import AffiliatePageTree from './AffiliatePageTree';

import type { TAffiliateWidget, TAffiliateWidgetGroup } from '../types';

//import { modifyWrapper } from "../../core/ModifyWrapper";

import withModifySubscriber from '../../../core/withModifySubscriber';

const subscriber_config = { table: 'affiliate_widget' };

const MTextField = withModifySubscriber(PcTextField, subscriber_config);
const MCheckbox = withModifySubscriber(PcCheckbox, subscriber_config);
const MSelect = withModifySubscriber(PcSelect, subscriber_config);
const MGroups = withModifySubscriber(
  AffiliateAssociatedGroups,
  subscriber_config,
);

type TProps = {
  affiliate_widget: TAffiliateWidget,
  target_group_list: Array<TAffiliateWidgetGroup>,
  country: any,
  archive: any,
};

const AffiliateEdit = ({
  country,
  affiliate_widget,
  target_group_list,
  archive,
}: TProps) => {
  if (!affiliate_widget) return null;

  const breadcrumbs_rest = country
    ? [
        {
          url: '/affiliate/by_country/' + country.country_id + '/all',
          name: country.name,
        },
        {
          url:
            '/affiliate/by_country/' +
            country.country_id +
            '/' +
            affiliate_widget.affiliate_widget_id,
          name: affiliate_widget.name,
        },
      ]
    : [
        {
          url: '/affiliate/global/all',
          name: 'global',
        },
        {
          url: '/affiliate/global/all/' + affiliate_widget.affiliate_widget_id,
          name: affiliate_widget.name,
        },
      ];

  const breadcrumbs = [
    {
      url: '/',
      name: 'home',
    },
    {
      url: '/affiliate',
      name: 'Affliliate home',
    },
    ...breadcrumbs_rest,
  ];

  return (
    <>
      <PcAppBar
        title={(country ? country.name : 'Global') + ' - Affiliate Widgets '}
        no_drawer={true}
      />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
          <h1>{affiliate_widget.name}</h1>
        </div>

        {affiliate_widget.archived && (
          <>
            <strong className="pd"> This widget is archived </strong>
          </>
        )}

        <div style={{ maxWidth: '500px', margin: 'auto' }}>
          <MTextField name="name" floatingLabelText="name" />
          <MTextField name="code" floatingLabelText="code" multiLine={true} />
          <br />
          <br />
          <MCheckbox
            name="active"
            label="active"
            disabled={!!affiliate_widget.archived}
          />
          <br />
          <br />
          <MSelect
            name="zone"
            floatingLabelText="zone"
            options={[
              {
                value: 1,
                name: 'left',
              },
              {
                value: 2,
                name: 'bottom',
              },
              {
                value: 3,
                name: 'top',
              },
              {
                value: 4,
                name: 'above listing',
              },
              {
                value: 5,
                name: 'beginning of text',
              },
            ]}
          />

          <br />
          <br />
          <MTextField
            name="daily_impressions_limit"
            floatingLabelText="daily impressions limit"
            type="number"
            fullWidth={false}
          />
          <small>
            <br />
            Leave empty if no limit
          </small>

          <br />
          <br />
          <MGroups name="target_groups_ids" groupList={target_group_list} />
        </div>

        {country && (
          <div className="pd" style={{ maxWidth: '500px' }}>
            <h3>Venues</h3>
            <p>
              If you don't want other widgets display on venue page then you may
              need to add the venue to{' '}
              <Link
                className="the_link"
                to={'/affiliate/' + country.country_id + '/venue_exclusions'}>
                global exclusions
              </Link>
              .
            </p>
            <AffiliateEditVenues />
          </div>
        )}
        {country && (
          <>
            <div className="pd">
              <h3>Pages</h3>
            </div>
            <AffiliatePageTree />
          </>
        )}
      </div>

      {!affiliate_widget.archived && (
        <div className="pull-right">
          <FlatButton
            label="Archive"
            secondary={true}
            onClick={() =>
              archive(affiliate_widget.affiliate_widget_id, country.country_id)
            }
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ constants, data }, ownProps) => {
  const country_list = constants.country_list || null;
  const country_id_route = parseInt(ownProps.match.params.country_id);
  const country = country_list
    ? country_list.find(c => c.country_id === country_id_route)
    : null;
  const { affiliate_widget, target_group_list } = data;

  return {
    country,
    country_list,
    affiliate_widget,
    target_group_list: (target_group_list || []).filter(
      (g: TAffiliateWidgetGroup) => !g.archived,
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    archive: (affiliate_widget_id, country_id) => {
      if (!window.confirm('Are you sure?')) return;
      const go_to = 'affiliate/by_country/' + country_id;
      dispatch(
        archive(
          'affiliate_widget',
          'affiliate_widget_id',
          affiliate_widget_id,
          go_to,
        ),
      );
    },
  };
};

export default withUserDataFetched(
  withDataFromUrl(
    ({ country_id, affiliate_widget_id }) => ({
      page_type: 'affiliate',
      page_id: affiliate_widget_id,
      api_call: country_id
        ? 'page/affiliate/by_country/' + country_id + '/' + affiliate_widget_id
        : 'page/affiliate/global/' + affiliate_widget_id,
    }),
    withAccessSuperadmin(
      connect(mapStateToProps, mapDispatchToProps)(AffiliateEdit),
    ),
  ),
);

/*
return {
  page_type: "affiliate",
  page_id: match.country_id,
  api_call: "affiliate/" + match.country_id
}
*/
