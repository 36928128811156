// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import {
  queueUpdate,
  formErrorAdd,
  formErrorRemove,
} from '../actions/actions.js';

type t_wrapped_props = * & {
  // Any props with extended name & handleChange
  name: string,
  handleChange: (*) => void,
};

type t_subscriber_config = {
  table: string,
  field_id_name?: string,
};

/*
type t_internal_component_with_subscribe = {
  table : string,
  field_name : string,
  value: string,
  field_id_name :string,
  data_id: number,
  handleModifyChange : (*) => void,
  handleModifyAddError : (*) => void,
  handleModifyRemoveError : (*) => void
}
*/

const withModifySubscriber = (
  WrappedComponent: React.ComponentType<t_wrapped_props>,
  config: t_subscriber_config,
) => {
  const mapStateToProps = (state: { data: * }, ownProps: t_wrapped_props) => {
    const { data } = state;

    const table = config.table;
    const field_name = ownProps.name;

    const field_id_name =
      (config.field_id_name && data[config.field_id_name]
        ? config.field_id_name
        : null) || (data[table][table + '_id'] ? table + '_id' : null);
    const data_id = data[table][field_id_name];

    if (!field_id_name) throw new Error('Unknown field_id_name value');
    return {
      table,
      field_name,
      value: state.data[table][field_name],
      field_id_name,
      data_id,
      ...ownProps,
    };
  };

  const mapDispatchToProps = (dispatch: *) => {
    return {
      handleModifyChange: (
        table,
        field_name,
        value,
        field_id_name,
        data_id,
      ) => {
        dispatch(queueUpdate(table, data_id, field_name, value, field_id_name));
      },

      handleModifyAddError: (message, error_input_id) => {
        dispatch(formErrorAdd(error_input_id, message));
      },

      handleModifyRemoveError: error_input_id => {
        dispatch(formErrorRemove(error_input_id));
      },
    };
  };

  class ComponentWithSubscriber extends React.Component<*, { value: string }> {
    state = {
      value: this.props.value,
    };

    error_input_id = JSON.stringify({
      table: this.props.table,
      field_name: this.props.field_name,
      field_id_name: this.props.field_id_name,
    });

    handleChange = value => {
      this.setState({ value });
      const { table, field_name, field_id_name, data_id, handleModifyChange } =
        this.props;
      handleModifyChange(table, field_name, value, field_id_name, data_id);
    };

    handleAddError = message => {
      //table, field_name, field_id_name
      const { table, field_name, handleModifyAddError } = this.props;
      const message_str =
        message + ' Field ' + table + ':' + field_name + '\n' + message;
      handleModifyAddError(message_str, this.error_input_id);
    };

    handleRemoveError = () => {
      this.props.handleModifyRemoveError(this.error_input_id);
    };

    render() {
      const {
        table,
        field_name,
        field_id_name,
        data_id,
        handleModifyChange,
        handleModifyAddError,
        handleModifyRemoveError,
        value,
        ...filtered_props
      } = this.props;

      return (
        <WrappedComponent
          handleChange={this.handleChange}
          handleAddError={this.handleAddError}
          handleRemoveError={this.handleRemoveError}
          value={this.state.value}
          {...filtered_props}
        />
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ComponentWithSubscriber);
};

export default withModifySubscriber;
