import { connect } from 'react-redux';
import Info from '../shared/Info';

const mapStateToProps = ({ data }) => {
  let warnings = [];

  const item = data.category;

  if (item.redirect_to)
    warnings.push(
      'This category has redirect set and another page will be displayed instead: ' +
        item.redirect_to,
    );

  return {
    warnings,
  };
};
export default connect(mapStateToProps)(Info);
