import React from 'react';

import { modifyWrapper } from '../../core/ModifyWrapper';

import PcTextField from '../shared/PcTextField';
import PcAppBar from '../shared/TopBar/PcAppBar';
import PcDrawer from '../shared/Drawer/PcDrawer';
import BreadCrumbs from '../shared/BreadCrumbs';
import FeatureTypeSelect from '../shared/Drawer/FeatureTypeSelect';
import FeatureList from '../shared/Drawer/FeatureList';

import PcCard from '../shared/PcCard';
import { Row, CellHalf } from '../shared/Grid';
import RemoveArchive from '../shared/RemoveArchive';

const MTextField = modifyWrapper(PcTextField, 'feature_type');

const FeatureType = () => (
  <div>
    <PcAppBar />

    <PcDrawer>
      <FeatureTypeSelect />
      <FeatureList />
    </PcDrawer>

    <div className="pd pc-content">
      <div className="top-line">
        <BreadCrumbs />
      </div>

      <PcCard>
        <Row>
          <CellHalf>
            <MTextField name="name" floatingLabelText="name" />
          </CellHalf>
          <CellHalf>
            <MTextField
              name="ordering"
              floatingLabelText="ordering"
              type="number"
            />
          </CellHalf>
        </Row>
      </PcCard>

      <RemoveArchive />
    </div>
  </div>
);

export default FeatureType;
