import { leloFetch } from '../helpers';
import { loadConstants } from './actions';
import history from '../history';

const getConstantsPromise = () => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    ////
    const { constants } = getState();
    if (constants.city_list && constants.city_list.length > 0) {
      resolve('constants already in state');
    }
    const data_from_localstorage = localStorage.getItem('constants');

    if (data_from_localstorage) {
      dispatch(loadConstants(JSON.parse(data_from_localstorage)));
      resolve('loading constants from local storage');
    }

    try {
      const data = await leloFetch('page/constants');
      dispatch(loadConstants(data));

      try {
        localStorage.setItem('constants', JSON.stringify(data));
      } catch (error) {
        console.warn('localStorage probably full', error);
      }
      resolve('conststans data fetched');
    } catch (error) {
      console.log(error);
      history.push('/login');
      reject('an erorr');
    }
  });

export { getConstantsPromise };

const getConstants = () => {
  return async (dispatch, getState) => {
    const { constants } = getState();
    if (constants.city_list && constants.city_list.length > 0) {
      //console.log('constants already in store')
      return;
    }
    const data_from_localstorage = localStorage.getItem('constants');
    if (data_from_localstorage) {
      dispatch(loadConstants(JSON.parse(data_from_localstorage)));
      //console.log('constants already in localStorage')
      return;
    }

    try {
      const data = await leloFetch('page/constants');
      dispatch(loadConstants(data));

      try {
        localStorage.setItem('constants', JSON.stringify(data));
        //console.log('constants saved into localStorag')
      } catch (error) {
        console.warn('localStorage probably full', error);
      }
      console.log('constans saved');
    } catch (error) {
      console.log(error);
      history.push('/login');
    }
  };
};

export default getConstants;
