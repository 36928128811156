/**
 * @flow
 */

import * as React from 'react';
import { connect } from 'react-redux';

import PcTextField from '../shared/PcTextField';
import PcSelect from '../shared/PcSelect';
import { isSuperAdmin } from '../../helpers/hasAccess';
import FlatButton from 'material-ui/FlatButton';
import addNew from '../../actions/addNew';

const styles = {
  wrapper: {
    margin: 0,
    padding: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

type State = {
  name: ?string,
  password: ?string,
  login: ?string,
  group: ?number,
  description: ?string,
};

type Props = {
  group_list: Array<{ name: string, group_id: number }>,
  dispatch: any,
};

class PcNewUser extends React.Component<Props, State> {
  is_superadmin: null;

  constructor() {
    document.title = 'New user';

    super();

    this.is_superadmin = isSuperAdmin();

    this.state = {
      name: null,
      password: null,
      login: null,
      group: 1,
      description: null,
    };
  }

  handleChange = (key: string, value: string | number) => {
    this.setState({ [`${key}`]: value });
  };

  handleChangeName = (value: string) => this.handleChange('name', value);
  handleChangeLogin = (value: string) => this.handleChange('login', value);
  handleChangePassword = (value: string) =>
    this.handleChange('password', value);
  handleChangeGroup = (value: string) => this.handleChange('group', value);
  handleChangeDescription = (value: string) =>
    this.handleChange('description', value);

  handleAdd = () => {
    this.props.dispatch(
      addNew('cms_users', {
        ...this.state,
        new_url_after_insert: 'pc_user',
        password_new: this.state.password,
      }),
    );
  };

  render() {
    if (!this.is_superadmin) {
      return <span>no Access</span>;
    }

    const { name, login, password, group, description } = this.state;

    return (
      <div style={styles.wrapper}>
        <div>
          <h1>Add new user</h1>
          <PcTextField
            handleChange={this.handleChangeName}
            floatingLabelText="display name *"
            value={name}
            fullWidth={false}
          />
          <br />
          <PcTextField
            handleChange={this.handleChangeLogin}
            floatingLabelText="login *"
            value={login}
            fullWidth={false}
          />
          <br />
          <PcTextField
            handleChange={this.handleChangePassword}
            floatingLabelText="password *"
            value={password}
            fullWidth={false}
          />
          <br />

          <PcSelect
            name="group_id"
            floatingLabelText="group *"
            options={this.props.group_list}
            handleChange={this.handleChangeGroup}
            value={group}
          />

          <br />
          <PcTextField
            handleChange={this.handleChangeDescription}
            floatingLabelText="extra description"
            value={description}
            fullWidth={false}
          />
          <br />
          <center>
            <FlatButton
              label="Add User"
              primary={true}
              disabled={!name || !login || !password ? true : false}
              onClick={this.handleAdd}
            />
          </center>
          <br />
          <small>You will associate user with cities in next step</small>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { cms_group_list } = state.data;

  return {
    group_list: cms_group_list.map(group => ({
      name: group.name,
      value: group.group_id,
    })),
  };
};

export default connect(mapStateToProps)(PcNewUser);
