// @flow
import React, { useState } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Chip from 'material-ui/Chip';

import PcCard from '../../../page/shared/PcCard.js';
import languageListIso from '../../../helpers/language_list_iso.json';

type LanguagesProps = {
  selected: Array<string>,
  handleChange: (Array<string>) => void,
};

const Languages = ({ selected, handleChange }: LanguagesProps) => {
  const handleSelectChange = (event, index, values) => {
    handleChange(values);
  };

  const onChipRemoveClick = iso_code_to_be_removed => {
    const filtered = selected.filter(
      iso_code => iso_code !== iso_code_to_be_removed,
    );
    handleChange(filtered);
  };

  const chip_data = languageListIso
    .filter(iso => selected.includes(iso[1]))
    .sort((a, b) => a[0].localeCompare(b[0]));

  return (
    <PcCard
      cardTitle="Target Languages"
      description="Display widget to users whoes browser introduce as using selected languages:">
      <SelectField
        multiple={true}
        hintText="Select Languages"
        value={selected}
        onChange={handleSelectChange}>
        {languageListIso.map(l => (
          <MenuItem
            key={l[1]}
            insetChildren={true}
            checked={selected && selected.indexOf(l[1]) > -1}
            value={l[1]}
            primaryText={l[0]}
          />
        ))}
      </SelectField>

      <div style={{ display: 'flex' }}>
        {chip_data.map(l => {
          return (
            <Chip
              key={l[1]}
              style={{ margin: '2px' }}
              onRequestDelete={() => onChipRemoveClick(l[1])}>
              {l[0]}
            </Chip>
          );
        })}
      </div>
    </PcCard>
  );
};

const LanguagesWithState = ({ selected, handleChange }: LanguagesProps) => {
  const [selectedState, setSelected] = useState(selected);
  const _handleSelectChange = selectedState => {
    handleChange(selectedState);
    setSelected(selectedState);
  };
  return (
    <Languages selected={selectedState} handleChange={_handleSelectChange} />
  );
};

export { LanguagesWithState };

export default Languages;
