import React from 'react';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import { connect } from 'react-redux';
import AddNewVenue from './AddNewVenue';
import AddNewEvent from './AddNewEvent';
import AddNewChapter from './AddNewChapter';
import AddNewCity from './AddNewCity';
import AddNewCountry from './AddNewCountry';
import AddNewCategory from './AddNewCategory';
import AddNewDistrict from './AddNewDistrict';
import AddNewFeature from './AddNewFeature';
import AddNewFeatureType from './AddNewFeatureType';

import AddNewEventType from './AddNewEventType';

import { hasAccessLevel } from '../../../helpers/hasAccess';

const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

class AddNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open_menu: false,
      modal_type: null,
    };
  }

  handleOpenMenu = event => {
    event.preventDefault();
    this.setState({
      open_menu: true,
      anchorEl: event.currentTarget,
    });
  };

  openModal = modal_type => {
    this.setState({
      modal_type,
      open_menu: false,
    });
  };

  handleCloseModal = () => {
    this.setState({ modal_type: null });
  };

  handleCloseMenu = () => {
    this.setState({ open_menu: false });
  };

  render() {
    const { links } = this.props;
    const { modal_type, open_menu, anchorEl } = this.state;
    const { handleOpenMenu, handleCloseMenu, openModal, handleCloseModal } =
      this;

    if (links.length === 0) return null;

    let the_links = [];

    links.map((item_type, index) =>
      the_links.push(
        <MenuItem
          key={item_type}
          onClick={() => openModal(item_type)}
          primaryText={capitalizeFirstLetter(item_type.replace('_', ' '))}
        />,
      ),
    );

    const style = {
      position: 'fixed',
      bottom: '30px',
      right: '30px',
      zIndex: 100,
    };

    return (
      <div style={style}>
        {modal_type === 'event' && (
          <AddNewEvent
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}
        {modal_type === 'venue' && (
          <AddNewVenue
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}
        {modal_type === 'chapter' && (
          <AddNewChapter
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}
        {modal_type === 'category' && (
          <AddNewCategory
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}
        {modal_type === 'city' && (
          <AddNewCity
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}
        {modal_type === 'country' && (
          <AddNewCountry
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}
        {modal_type === 'district' && (
          <AddNewDistrict
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}
        {modal_type === 'feature' && (
          <AddNewFeature
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}
        {modal_type === 'event_type' && (
          <AddNewEventType
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}
        {modal_type === 'feature_type' && (
          <AddNewFeatureType
            handleCloseModal={handleCloseModal}
            dispatch={this.props.dispatch}
          />
        )}

        {/*<AddNewVenue open={modal_type==='venue' ? true : false} handleCloseModal={handleCloseModal} />*/}

        <FloatingActionButton onClick={handleOpenMenu}>
          <ContentAdd />
        </FloatingActionButton>

        <Popover
          open={open_menu}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={handleCloseMenu}>
          <Menu>{the_links}</Menu>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { page_type } = data;
  let links_all = [];

  if (['venue', 'category'].includes(page_type)) {
    links_all = ['venue', 'event', 'feature', 'category', 'chapter'];
  } else if (page_type === 'chapter') {
    links_all = ['venue', 'event', 'feature', 'category', 'chapter'];
  } else if (page_type === 'city') {
    links_all = [
      'venue',
      'event',
      'feature',
      'feature_type',
      'chapter',
      'district',
      'city',
      'country',
    ];
  } else if (page_type === 'country') {
    links_all = ['event', 'feature', 'city', 'country'];
  } else if (['event', 'events_chapter', 'event_type'].includes(page_type)) {
    links_all = ['event', 'venue', 'feature', 'event_type', 'city', 'country'];
  } else if (page_type === 'feature') {
    links_all = ['feature', 'feature_type', 'country'];
  } else if (page_type === 'feature_type') {
    links_all = ['feature', 'feature_type'];
  } else if (page_type === 'district') {
    links_all = ['venue', 'event', 'feature', 'chapter', 'district'];
  }

  const links = links_all.filter(type => hasAccessLevel(3, type));

  return { links };
};

export default connect(mapStateToProps)(AddNew);
