const queueDelete = (state = {}, action) => {
  switch (action.type) {
    case 'QUEUE_DELETE':
      //console.log(action);

      const field_key = action.custom_key
        ? action.custom_key
        : action.table + '_id';

      const store_key = action.table + ':' + field_key + ':' + action.id;

      const item_current = state[store_key] || {};

      const item = { ...item_current };

      let data_el = {};

      data_el[store_key] = item;

      console.log('delete', { ...state, ...data_el });

      return { ...state, ...data_el };

    case 'MODIFFY_CLEAN':
      return {};

    default:
      return state;
  }
};

export default queueDelete;
