import modifyItem from './ModifyItem';

export const modifyWrapper = (Wrapped, type, props = {}) => {
  if (!Wrapped || !type)
    return console.error('function modifyWrapper expect two parameters');
  if (typeof Wrapped !== 'function')
    return console.error(
      'first params of function modifyWrapper should be Reactt Component',
    );

  return modifyItem(Wrapped, { data_type: type, ...props });
};

export const modifyCategory = (Wrapped, props = {}) => {
  return modifyItem(Wrapped, { data_type: 'category', ...props });
};

export const modifyText = (Wrapped, props = {}) => {
  return modifyItem(Wrapped, { data_type: 'text', ...props });
};

export const modifyFeature = (Wrapped, props = {}) => {
  return modifyItem(Wrapped, { data_type: 'feature', ...props });
};

export const modifyGallery = (Wrapped, props = {}) => {
  return modifyItem(Wrapped, { data_type: 'gallery', ...props });
};

export const modifyChapter = (Wrapped, props = {}) => {
  return modifyItem(Wrapped, { data_type: 'chapter', ...props });
};
