import { api_url } from '../config/config';
import { indicatorOn, indicatorOff } from './actions';
import { getConstantsPromise } from './getConstants';
import { getUserDataPromise } from './getUserData';
import { leloFetch } from '../helpers';
import history from '../history.js';
import Cookies from 'js-cookie';

export const preserveUserData = user_data => {
  return {
    type: 'LOGGED_IN',
    user_data,
  };
};

export const getUserData = () => {
  return dispatch => {
    dispatch(indicatorOn());

    return leloFetch('getUserData');
  };
};

export const logoutClick = () => {
  return dispatch => {
    dispatch(indicatorOn());
    return leloFetch('logout', {
      method: 'POST',
    })
      .then(data => {
        dispatch(indicatorOff());
        if (data.success) {
          dispatch(loggedOut());
          //history.push('/login')
          document.location.href = '/login'; // force hard reload
        } else {
          alert('Error code:6hx7');
        }
        Cookies.remove('lelo14');
        Cookies.remove('pc');
      })
      .catch(function (error) {
        dispatch(indicatorOff());
        alert('Error code:s94d');
        console.log(error);
      });
  };
};

export const loggedOut = () => {
  return {
    type: 'LOGGED_OUT',
  };
};

const setLoginErrorMessage = msg_error => {
  return {
    type: 'SET_LOGIN_ERROR_MESSAGE',
    msg_error,
  };
};

export const loginEntry = (username, password) => {
  return (dispatch, getState) => {
    const state = getState();
    const go_to = state.page_control.not_logged_link_preserve || '';
    dispatch(login(username, password, go_to));
  };
};

const login = (username, password, go_to) => {
  return async dispatch => {
    if (!username || !password)
      return dispatch(setLoginErrorMessage('No username or password'));

    dispatch(indicatorOn());
    dispatch(setLoginErrorMessage(null));

    let data = {};
    try {
      data = await leloFetch('login', {
        method: 'POST',
        json_data: { username, password },
      });
    } catch (error) {
      console.log(error);
      dispatch(indicatorOff());

      alert(error.error || JSON.stringify(error));
      return;
    }

    if (data.success) {
      const { success, ...user_data } = data;

      dispatch(preserveUserData(user_data));
      dispatch(setLoginErrorMessage(null));

      //console.log('user_data', user_data);

      // temporary - used in DAMS
      Cookies.set('lelo14', btoa(user_data.session_id + '!@#$%^&'), {
        expires: 365,
        domain: '.inyourpocket.com',
      });

      // used do access test.inyourpocket.com domain
      // notice it will not add the cookie in localhost
      Cookies.set('pc', '1', { expires: 365, domain: '.inyourpocket.com' });

      // as there were a problems that smetimes user data were citylist displayed no access we will make sure they are fetched before going to hoemapge
      Promise.all([
        dispatch(getConstantsPromise()),
        dispatch(getUserDataPromise()),
      ])
        .then(function (values) {
          console.log('values', values);
          history.push('/' + (go_to !== 'login' ? go_to : '/'));
          //history.push('/' + go_to)
        })
        .catch(reason => {
          alert(reason);
          dispatch(indicatorOff());
          dispatch(setLoginErrorMessage(reason));
        });
    } else if (data.error) {
      dispatch(indicatorOff());
      return dispatch(setLoginErrorMessage('Incorrect username or password'));
    } else {
      dispatch(indicatorOff());
      alert('Error code:1hx7');
    }

    /*
			.catch(function(error) {
				dispatch(indicatorOff())
				return dispatch(setLoginErrorMessage('Error: ' + error.message))
			});

		*/
  };
};

export const loginCheck = () => {
  return (dispatch, getState) => {
    dispatch(indicatorOn());

    return fetch(api_url + 'loginCheck', {
      method: 'POST',
    })
      .then(r => r.json())
      .then(data => {
        dispatch(indicatorOff());
        if (data.logged) {
          return alert('logged in');
          //return dispatch(preserveUserData())
        } else {
          //localStorage.removeItem("logged");
          return alert('not logged in');
          //return dispatch(loggedOut())
        }
      })
      .catch(function (error) {
        dispatch(indicatorOff());
        alert('Something went wrong');
        console.log(error);
      });
  };
};
