import React, { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI

    let message = error.stack
      ? error.stack.split('\n').slice(0, 2).join('\n')
      : JSON.stringify(error);
    message += '\nin:' + document.location.href;

    this.setState({
      hasError: true,
      message,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const link =
        'mailto:sebastian.grzymala@inyourpocket.com?subject=PC%20Error&body=' +
        encodeURIComponent('error details:\n' + this.state.message);
      return (
        <div className="pd">
          Something went wrong. Component didn't load.
          <div className="pd">
            <pre>{this.state.message}</pre>
            <a
              className="the_link"
              target="_blank"
              href={link}
              rel="noopener noreferrer">
              click to report error
            </a>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export function withErrorBoundary(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <ErrorBoundary>
          <WrappedComponent {...this.props} />
        </ErrorBoundary>
      );
    }
  };
}
